import { BucketedTimes } from '../bucketed_times/bucketed_times_core'
import { AvailableTimes, defaultAvailableTimes } from '../available_times/available_times_core'
import { GraphQLResponseItems, Availability, AvailableTimesContainer } from './remote'
import { parseAvailableTimes, parseBucketedTimes } from '../../util/available_time_utils'

export interface ParseTimesRemote {
  readonly bucketedTimes: BucketedTimes
  readonly availableTimes: AvailableTimes
  readonly hasNextPage: boolean
  readonly nextPageStart: string
}

export const parseAvailableTimesRemoteGql = (
  remote: GraphQLResponseItems,
  oldTimes: AvailableTimes,
  duration: number,
  timezone: string
): ParseTimesRemote => {
  const availability: Availability | undefined = remote.data.availability
  if (!availability) {
    return {
      bucketedTimes: [],
      availableTimes: { ...defaultAvailableTimes },
      hasNextPage: false,
      nextPageStart: '',
    }
  }

  const times: ReadonlyArray<string> = oldTimes.sort.concat(availability.availableTimes.data)

  return {
    bucketedTimes: parseBucketedTimes(times, timezone),
    availableTimes: {
      ...parseAvailableTimes(times, duration, timezone),
      selected: oldTimes.selected,
    },
    hasNextPage: remote.data.availability?.availableTimes.hasNextPage || false,
    nextPageStart: remote.data.availability?.availableTimes.nextPageStart || '',
  }
}
export const parseRescheduleTimesRemoteGql = (
  remote: any,
  oldTimes: AvailableTimes,
  duration: number,
  timezone: string
): ParseTimesRemote => {
  const availability: AvailableTimesContainer | undefined =
    remote.data?.availability?.meeting?.reschedulableTimes
  if (!availability) {
    return {
      bucketedTimes: [],
      availableTimes: { ...defaultAvailableTimes },
      hasNextPage: false,
      nextPageStart: '',
    }
  }

  const times: ReadonlyArray<string> = oldTimes.sort.concat(availability.data)

  return {
    bucketedTimes: parseBucketedTimes(times, timezone),
    availableTimes: {
      ...parseAvailableTimes(times, duration, timezone),
      selected: oldTimes.selected,
    },
    hasNextPage: availability.hasNextPage || false,
    nextPageStart: availability.nextPageStart || '',
  }
}

export const parseAvailableTimesRemote = (
  remote: ReadonlyArray<string>,
  duration: number,
  timezone: string
): ParseTimesRemote => {
  return {
    bucketedTimes: parseBucketedTimes(remote, timezone),
    availableTimes: parseAvailableTimes(remote, duration, timezone),
    hasNextPage: false,
    nextPageStart: '',
  }
}
