import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import { throttle } from 'lodash'
import { calculateVisibilityForElement } from '../../../util/style_utils'
import { CONSTS } from 'assets/app_consts'

const { moreTimes: moreTimesString } = appStrings
// Distance from top of page, used to scroll
const TOP_MARGIN_OFFSET = 94

export interface WeekSelectorProps {
  /**
   * An array of week strings
   */
  readonly weeks: ReadonlyArray<string>
  /**
   * Currently selected week string
   */
  readonly currentWeek: string
  /**
   * Function for setting the current week
   */
  readonly setCurrentWeek: (week: string) => void
  /**
   * Adds a "more times" link to trigger infinite scrolling
   */
  readonly moreTimes?: boolean
}

export const WeekSelector: FC<WeekSelectorProps> = ({
  weeks,
  currentWeek,
  setCurrentWeek,
  moreTimes,
}) => {
  interface ScrollDescription {
    visibility: number
    week: string
  }
  const [pauseUpdate, setPauseUpdate] = useState(false)
  const [timeDelay, setTimeDelay] = useState(0)

  // This disables catching events while the list is scrolling to a week
  const debounce = () => {
    clearTimeout(timeDelay)
    setTimeDelay(
      window.setTimeout(() => {
        setPauseUpdate(false)
      }, 100)
    )
  }

  // Updates after a scroll to week is completed
  useEffect(() => {
    if (!pauseUpdate) {
      updateScroll()
    }
    // eslint-disable-next-line
  }, [pauseUpdate])

  // Updates the week navigator to the week with the most visibility on the screen
  const updateScroll = throttle(() => {
    const panelList: Array<ScrollDescription> = []
    for (const week of weeks) {
      const listItem = document.getElementById(week)
      if (listItem) {
        panelList.push({
          week,
          visibility: calculateVisibilityForElement(listItem, TOP_MARGIN_OFFSET),
        })
      }
    }

    const newPanel = panelList.reduce(
      (acc, current) => (current.visibility > acc.visibility ? current : acc),
      {
        visibility: 0,
        week: '',
      }
    )
    if (currentWeek !== newPanel.week) {
      setCurrentWeek(newPanel.week)
      document.getElementById(newPanel.week + 'scroll')?.scrollIntoView({ block: 'center' })
    }
  }, 300)

  // Sets the initial week when loading
  useEffect(() => {
    updateScroll()
    // eslint-disable-next-line
  }, [])

  // Sets the callback functions for the container scroll
  useEffect(() => {
    document.getElementById('content-container')?.addEventListener('scroll', updateScroll)
    return () => {
      document.getElementById('content-container')?.removeEventListener('scroll', updateScroll)
    }
  }, [weeks, updateScroll])

  // Scrolls to the correct week in both the navigator and the week list
  const executeScroll = (id: string) => {
    setPauseUpdate(true)
    debounce()
    const elementTop = document.getElementById(id)?.offsetTop
    if (elementTop) {
      document.getElementById('content-container')?.scrollTo({
        top: elementTop - TOP_MARGIN_OFFSET,
        behavior: 'smooth',
      })
    }
  }

  return (
    <WeekSelectorContainer>
      <div className="grid-list">
        {weeks.map(week => {
          const className = `${week === currentWeek ? 'active' : ''}${
            CONSTS.WEEK_HEADERS.includes(week) ? '' : ' excl-lz'
          }`
          return (
            <ListItem
              key={week + 'scroll'}
              className={className}
              id={week + 'scroll'}
              tabIndex={0}
              onClick={() => executeScroll(week)}
            >
              <p>{week.toLowerCase()}</p>
            </ListItem>
          )
        })}
        {moreTimes && (
          <ListItem
            key={'more-times-week'}
            tabIndex={0}
            onClick={() => executeScroll('more-times')}
          >
            <p>{moreTimesString}</p>
          </ListItem>
        )}
      </div>
    </WeekSelectorContainer>
  )
}

const WeekSelectorContainer = styled.div`
  display: grid;
  overflow: auto;
  grid-auto-rows: min-content;
  grid-row-gap: 5px;
  width: 100%;

  .grid-list {
    padding: 16px 0;
  }

  .active > p {
    color: ${appColors.primary} !important;
    background-color: ${appColors.selectedWeekBackground};
  }
`

const ListItem = styled.button`
  padding: 0;
  border: 3px solid transparent;
  background: inherit;
  width: 100%;

  &:focus {
    outline: 0;
    border-color: ${appColors.primary};
  }
  &:active,
  &:hover {
    text-decoration: none;
  }

  p,
  p:focus {
    padding: 8px 12px;
    margin: 0 16px;
    border-radius: 3px;
    font-size: 14px;
    text-decoration: none !import;
    color: ${appColors.unselectedWeek};
    cursor: pointer;
    text-transform: capitalize;
    text-align: left;
  }
`
