import React from 'react'
import styled from 'styled-components'

import { MeetingInfoItems } from './meeting_info/meeting_info_items'
import { MeetingDescription } from './meeting_info/meeting_description'

import { LocationType } from 'common/redux/meetings/meetings_core'
import { appColors } from '../../assets/app_colors'
import { Maybe } from 'graphql'

export interface ModalInfoProps {
  /**
   * The description of the meeting type
   */
  readonly description?: Maybe<string>
  /**
   * The duration of the meeting type (ex: "30 minutes")
   */
  readonly duration?: number
  /**
   * The description of the meeting location
   */
  readonly locationType?: Maybe<LocationType>
  /**
   * The location to meet, overrides locationType
   */
  readonly location?: Maybe<string>
  /**
   * The start time of a meeting (ex: "9:00 am")
   */
  readonly startTime?: string
  /**
   * The end time of a meeting (ex: "9:30 am")
   */
  readonly endTime?: string
  /**
   * The date of the meeting
   */
  readonly date?: string
  /**
   * Price formatted as a string
   */
  readonly priceFormatted?: string
  /**
   * Currency used for price
   */
  readonly priceCurrency?: Maybe<string>
}

export const ModalInfo: React.FC<ModalInfoProps> = ({
  date,
  description,
  duration,
  endTime,
  location,
  locationType,
  priceCurrency,
  priceFormatted,
  startTime,
}) => {
  return (
    <ModalInfoContainer>
      <MeetingInfoItems
        {...{
          date,
          duration,
          endTime,
          location,
          locationType,
          startTime,
          priceCurrency,
          priceFormatted,
        }}
      />
      <MeetingDescription description={description} />
    </ModalInfoContainer>
  )
}

const ModalInfoContainer = styled.div`
  overflow-y: auto;
  padding: 16px;
  padding-top: 6px;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-color: ${appColors.timezoneSelectionBorder};
`
