export interface UpdatePayload {
  readonly id: string
  readonly message: string
  readonly start?: string
}

export interface SubmitUpdateBooking {
  readonly updateBookingReason: string
}

export const defaultUpdateBookingReason: SubmitUpdateBooking = {
  updateBookingReason: '',
}
