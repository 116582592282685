import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { Organization } from 'common/redux/organization/organization_core'

export interface ConfirmContentIface {
  readonly availableTimeStart: string
  readonly dateLong: string
  readonly endTime: string
  readonly showAppointletBranding: boolean
}

export const getConfirmContent = (
  bookedAppointment: BookedAppointment,
  organization: Organization,
  availableTimes: AvailableTimes
): ConfirmContentIface | null => {
  if (!bookedAppointment.meeting) {
    return null
  }
  const { timezone } = bookedAppointment
  if (!timezone) {
    return null
  }
  if (!organization) {
    return null
  }

  const { showAppointletBranding } = organization
  const { selected } = availableTimes

  if (!availableTimes.items[selected]) {
    return null
  }

  const { dateLong, display: availableTimeStart, end: endTime } = availableTimes.items[selected]

  return {
    availableTimeStart,
    dateLong,
    endTime: endTime ? endTime : '',
    showAppointletBranding,
  }
}
