import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState } from 'common/redux/sitka'
import { FieldValues, defaultFieldValues } from './field_values_core'
import { select, put } from 'redux-saga/effects'

import { loadState, saveState } from 'common/util/local_storage'
import { handleActivity } from '../activity/activity_module'

export class FieldValuesModule extends SitkaModule<FieldValues, AppModules> {
  public moduleName: string = 'fieldValues'
  public defaultState: FieldValues = defaultFieldValues
  public static selectFieldValues(state: AppState): FieldValues {
    return state.fieldValues
  }

  public *handleSaveLocalStorage(fieldValues: FieldValues, meetingTypeId: string) {
    const existingFieldValues = yield select(FieldValuesModule.selectFieldValues)
    const userFilledInputFields = { ...existingFieldValues, ...fieldValues }
    yield put(this.setState(userFilledInputFields, true))
    saveState(userFilledInputFields, meetingTypeId)
  }

  public *handleLoadLocalStorage(meetingTypeId: string) {
    yield this.loadLocalStorage(meetingTypeId)
  }

  public *loadLocalStorage(meetingTypeId: string) {
    // when query params pre-populate form values, they are present in existingFieldValues
    const existingFieldValues: FieldValues = yield select(FieldValuesModule.selectFieldValues)
    const persistedState: FieldValues = yield loadState(meetingTypeId)

    if (!!persistedState) {
      // Attempt to intelligently merge existing and persisted state
      // Build array of all keys across both persisted and existing
      const formFieldKeys = [...Object.keys(persistedState), ...Object.keys(existingFieldValues)]

      // merge persisted state with existing state, preferring existingFieldValues over persisted
      const newState = formFieldKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: existingFieldValues[key] || persistedState[key],
        }
      }, {})

      yield put(this.setState(newState, true))
    }
  }

  public *handleRenderedFormFields() {
    yield handleActivity('loadedUserInfo')
  }

  public *set(state: FieldValues) {
    yield put(this.setState(state, true))
  }
}
