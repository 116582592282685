import { BaseMap } from 'common/redux/base_map'

// converts query string in form of "?key1=val1&key2=val2"
// to a key/value object dictionary
export const queryStringToObject = (queryString: string): BaseMap<string> => {
  if (!queryString) {
    return {}
  }
  return queryString
    .substring(1)
    .split('&')
    .reduce((acc, pair) => {
      const [key, value] = pair.split('=')
      if (!key) {
        return acc
      }
      const decodedValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
      return {
        ...acc,
        [key]: acc[key] || decodedValue,
      }
    }, {} as BaseMap<string>)
}

export const snakeCaseToCamelCase = (str: string) => {
  return str.replace(/_(\w)/g, m => m[1].toUpperCase())
}

export const getQueryStringFormValues = (queryString: string) => {
  const params = queryStringToObject(queryString)

  return Object.keys(params).reduce((acc, key) => {
    const cleanKey = key.replace(/^field__/, '')
    const camelCaseKey = snakeCaseToCamelCase(cleanKey)
    return { ...acc, [camelCaseKey]: params[key] }
  }, {})
}

export function decodeQueryParams<TParams extends { [key: string]: any }>(
  params?: string
): TParams {
  return (params || window.location.search.substr(1)).split('&').reduce((acc, paramPair) => {
    // Split the key/val
    const [key, val] = paramPair.split('=')

    // Decode and store
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    acc[decodeURIComponent(key)] = decodeURIComponent(val)

    return acc
  }, {} as TParams)
}
