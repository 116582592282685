/**
 * This is a container that by default organizes its contents vertically,
 * and expands horizontally to fill its container.
 */

import { appColors } from 'assets/app_colors'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { Maybe } from 'graphql'
import React from 'react'
import styled from 'styled-components'
import { spacing } from 'util/style_utils'
import { ListItem, ListItemProps } from './list_item'

interface ListProps {
  readonly items: ReadonlyArray<ListItemProps>
  readonly welcomeMessage: Maybe<string>
  readonly className?: string
  readonly setInfoModal: (isOpen: boolean, id: string, modalType: InfoModalType) => void
}

export const List = ({ items, className, setInfoModal, welcomeMessage }: ListProps) => {
  return (
    <ListContainer className={className}>
      {welcomeMessage && <WelcomeMessage className="excl-lz">{welcomeMessage}</WelcomeMessage>}
      {items.map(item => (
        <ListItem
          id={item.id}
          image={item.image}
          key={item.id}
          label={item.label}
          price={item.price}
          showPrice={item.showPrice}
          setInfoModal={setInfoModal}
          slug={item.slug}
          subLabel={item.subLabel}
        />
      ))}
    </ListContainer>
  )
}

const { background, border } = appColors.selectMeeting.description

const WelcomeMessage = styled.div`
  width: 100%;
  background: ${background};
  border: 1px solid ${border};
  padding: ${spacing(2)};
  margin-bottom: ${spacing(2)};
  line-height: 20px;
  border-radius: 5px;
  word-wrap: break-word !important;
  word-break: break-word !important;
  white-space: pre-wrap;
`

const ListContainer = styled.div`
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: max-content;
  width: 100%;
  justify-items: center;
`
