import { Meeting, Meetings } from 'common/redux/meetings/meetings_core'
import { AppState } from 'common/redux/sitka'
import { SelectHostMemberPage } from 'pages/select_host_member/select_host_member_page'
import { SelectTimePage } from 'pages/select_time/select_time_page'
import React from 'react'
import { connect } from 'react-redux'

interface SelectTimeOrHostMemberPageProps {
  readonly meetings: Meetings
}

const Component: React.FC<SelectTimeOrHostMemberPageProps> = ({
  meetings,
}: SelectTimeOrHostMemberPageProps) => {
  const meeting: Meeting = meetings.items[meetings.selected]

  return meeting.hostAssignmentStrategy === 'ATTENDEE_CHOOSES' ? (
    <SelectHostMemberPage />
  ) : (
    <SelectTimePage />
  )
}

export const SelectTimeOrHostMemberPage = connect((state: AppState) => {
  return {
    meetings: state.meetings,
  }
})(Component)
