import React from 'react'
import styled from 'styled-components'
import { Globe } from 'react-feather'
import { appColors } from '../../assets/app_colors'
import { appStrings } from '../../assets/app_strings'
import { Timer } from './timer'

export interface TimezonePickerProps {
  /**
   * Timezone TZ database name (example: US/Pacific)
   */
  readonly timezoneName?: string
  /**
   * Current time in timezone (example: 12:20pm)
   */
  readonly currentTime?: string
  /**
   * The function to call when pressing "Change" to modify the timezone
   */
  readonly changeTimezone?: () => void
  /**
   * Is timezone locked and can't be changed
   */
  readonly isLocked?: boolean
}

export const TimezonePicker: React.FC<TimezonePickerProps> = ({
  timezoneName,
  currentTime,
  changeTimezone,
  isLocked,
}) => {
  const { currently, change } = appStrings.timezonePicker
  const timezoneNameDisplay = (timezoneName || '').replace(/_/g, ' ')

  return (
    <TimezonePickerContainer>
      <div className="contents">
        <div className="label">
          <Globe className="globe" color={appColors.timezoneGlobe} size={34} strokeWidth={1.5} />
          <div className="text">
            <span className="currently">
              <Timer
                timerString={currently}
                currentTime={currentTime || ''}
                timezoneRegion={timezoneName || ''}
              />
            </span>
            <span className="times-in">
              <span className="timezone-name excl-lz">{timezoneNameDisplay}</span>
            </span>
          </div>
        </div>
        {!isLocked && (
          <button className="btn-change" tabIndex={1} onClick={changeTimezone}>
            {change}
          </button>
        )}
      </div>
    </TimezonePickerContainer>
  )
}

const TimezonePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 8px;

  background: ${appColors.timezonePickerBg};

  .contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    overflow: hidden;
  }

  .globe {
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    margin: 8px 0px;
  }

  .text {
    /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    /* Inside Auto Layout */
    order: 1;
    align-self: center;

    margin: 8px 0px 8px 6px;
    min-width: 0;
  }

  .times-in {
    font-size: 14px;
    line-height: 18px;

    color: ${appColors.headerText};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .timezone-name {
    font-weight: 500;
  }

  .currently {
    font-size: 12px;
    line-height: 16px;

    color: ${appColors.subHeaderText};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-change {
    border: none;
    background: none;
    font-size: 14px;
    color: ${appColors.primary};

    display: flex;
    align-items: center;
    cursor: pointer;
  }
`
