import React, { useEffect, useState } from 'react'
import { Header } from './header'

import { getShowBack, getTitle } from './header_utils'
import { Organization } from 'common/redux/organization/organization_core'
import { Session } from 'common/redux/session/session_core'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { connect } from 'react-redux'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { SessionModule } from 'common/redux/session/session_module'
import { useHistory } from 'react-router'

interface HeaderContainerProps {
  readonly sessionModule: SessionModule
  readonly organization: Organization
  readonly session: Session
  readonly bookedAppointment: BookedAppointment
}

const Component: React.FC<HeaderContainerProps> = ({
  organization,
  session,
  bookedAppointment,
}) => {
  const { brandColor, name, image, slug, showAppointletBranding } = organization

  const history = useHistory()
  const screenTitle = getTitle(session, bookedAppointment)
  const showBack: boolean = getShowBack(session)
  const [backDebounce, setBackDebounce] = useState<string | undefined>()

  const handleNavClick = () => {
    if (backDebounce !== session.path) {
      history.goBack()
      setBackDebounce(session.path)
    }
  }

  useEffect(() => {
    if (backDebounce !== session.path) {
      setBackDebounce(undefined)
    }
  }, [session.path, setBackDebounce, backDebounce])

  if (name === '' || session.sessionType === 'conference') {
    return null
  }

  const headerProps = {
    teamName: name,
    teamLogo: image,
    teamSlug: slug,
    brandColor,
    screenTitle,
    onNavClick: handleNavClick,
    showBadge: showAppointletBranding,
    showBack,
    isModal: session.mode === 'modal',
  }

  return <Header {...headerProps} />
}

export const HeaderContainer = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    sessionModule: modules.session,
    organization: state.organization,
    session: state.session,
    bookedAppointment: state.bookedAppointment,
  }
})(Component)
