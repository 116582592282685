import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { CompleteCard, CompleteCardState } from 'components/molecules/complete_card/complete_card'
import { LocationType, Meetings } from 'common/redux/meetings/meetings_core'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { BookedAppointmentModule } from 'common/redux/booked_appointment/booked_appointment_module'
import { SessionType } from 'common/redux/session/session_core'
import { Organization } from 'common/redux/organization/organization_core'
import { toAvailableTime } from 'common/util/available_time_utils'

interface CompletePageProps {
  readonly bookedAppointmentModule: BookedAppointmentModule

  readonly bookedAppointment: BookedAppointment
  readonly organization: Organization
  readonly availableTimes: AvailableTimes
  readonly sessionType: SessionType
  readonly meetings: Meetings
}

const Component: React.FC<CompletePageProps> = ({
  bookedAppointmentModule: { handleRenderedBookedAppointment },
  bookedAppointment: { timezone, meeting, status },
  organization,
  meetings,
  sessionType,
}) => {
  useEffect(() => {
    handleRenderedBookedAppointment()
    // eslint-disable-next-line
  }, [])

  if (!meeting || !timezone || !meetings) {
    return null
  }

  const { name: meetingName } = meetings.items[meetings.selected]
  const { start, end } = meeting
  const { name: teamName, slug: teamSlug, showAppointletBranding } = organization

  const { display: startTime, dateLong } = toAvailableTime(start, 0, timezone.momentCode)
  const endTime = toAvailableTime(end, 0, timezone.momentCode).display

  const getCompleteCardState = (): CompleteCardState => {
    if (status === 'CONFIRMED') {
      if (sessionType === 'create') return 'confirmed'
      if (sessionType === 'reschedule') return 'rescheduleConfirmed'
      if (sessionType === 'cancel') return 'cancelled'
    }
    if (status === 'REQUESTED') {
      if (sessionType === 'create') return 'meetingRequested'
      if (sessionType === 'reschedule') return 'rescheduleRequested'
      if (sessionType === 'cancel') return 'cancellationRequested'
    }
    if (status === 'CANCELLED') {
      return 'cancelled'
    }

    return 'confirmed'
  }

  const cardState = getCompleteCardState()

  return (
    <CompletePageContainer>
      <CompleteCard
        teamName={teamName}
        teamSlug={teamSlug}
        state={cardState}
        meetingType={meetingName}
        startTime={startTime}
        endTime={endTime}
        timezone={timezone.display.replace(/\sTime$/, '')}
        date={dateLong}
        locationType={meeting!.locationType as LocationType}
        location={meeting!.location}
        showAppointletBranding={showAppointletBranding}
      />
    </CompletePageContainer>
  )
}

export const CompletePage = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    bookedAppointmentModule: modules.bookedAppointment,
    bookedAppointment: state.bookedAppointment,
    organization: state.organization,
    availableTimes: state.availableTimes,
    sessionType: state.session.sessionType,
    meetings: state.meetings,
  }
})(Component)

const CompletePageContainer = styled.div`
  margin: 0 auto;
  max-width: 532px;
  width: 100%;

  .complete-card {
    margin: 26px;
  }

  .wrap {
    word-break: break-word;
  }
  @media all and (max-width: 480px) {
    .complete-card {
      box-shadow: none;
      border: 0;
      margin: 0;
      border-radius: 0;
      height: calc(100vh - 68px);
    }
  }
`
