import { BaseMap, getRepo } from 'common/redux/base_map'
import { Maybe, SchedulerMeetingTypeNode, SchedulerTeamNode } from 'graphql'
import { queryStringToObject, snakeCaseToCamelCase } from '../../util/query_string_utils'
import { Field, Fields } from '../fields/fields_core'
import { Meeting, Meetings } from '../meetings/meetings_core'
import { FeatureFlags, Organization } from '../organization/organization_core'
import { deprecatedZones } from '../timezone/deprecated_zones'
import { FieldEdge, GraphQLResponseItems, MeetingTypeEdge, Team, TimezoneRemote } from './remote'
import { getDefaultTimezone } from './remote_timezones_transform'

export interface ParseBookingRemote {
  readonly organization: Organization
  readonly meetings: Meetings
  readonly fields: Fields
}

export const parseMeetingTypesRemote = (
  remote: GraphQLResponseItems,
  slug: string,
  timezones: ReadonlyArray<TimezoneRemote>
): Meetings | null => {
  const source = remote.data.team
  const params = queryStringToObject(window.location.search)
  const timezoneQuery = params['timezone'] || ''
  const correctedTimezone = deprecatedZones[timezoneQuery]
    ? deprecatedZones[timezoneQuery]
    : timezoneQuery

  if (source) {
    const items: BaseMap<Meeting> = source.meetingTypes.edges.reduce(
      (acc, meetingTypeEdge: MeetingTypeEdge) => {
        const { node } = meetingTypeEdge
        const id = meetingTypeEdge.node.id

        const timezone =
          correctedTimezone !== ''
            ? getDefaultTimezone(correctedTimezone, timezones)
            : getDefaultTimezone(node.usesLocalTimezone ? node.timezone : undefined, timezones)
        return {
          ...acc,
          [id]: {
            name: node.name,
            description: node.description,
            locationType: node.locationType,
            slug: node.slug,
            duration: node.duration / 60,
            timezone,
            image: node.image || '',
            priceCurrency: node.priceCurrency,
            price: node.price,
            id,
            priceFormatted: node.priceFormatted,
            teamSlug: slug,
            usesLocalTimezone: node.usesLocalTimezone,
            hostAssignmentStrategy: node.hostAssignmentStrategy,
            hostMembers: [],
            selectedHostMember: null,
          },
        }
      },
      {}
    )
    const sort = source.meetingTypes.edges.map(item => item.node.id)
    return {
      items,
      sort,
      selected: '',
    }
    // return getRepoSortBy(items, "order")
  }

  return null
}

// TODO when Team type is refactored to use CodeGen types this can be removed
export const parseTeamToOrganizationLegacy = (team: Team): Organization => {
  const { id, brandColor, featureFlags, showAppointletBranding, gtmContainerId } = team.profile
  const { language, image, name, slug, description } = team
  return {
    id,
    brandColor,
    description,
    featureFlags: featureFlags as ReadonlyArray<FeatureFlags>,
    image,
    language,
    name,
    showAppointletBranding,
    gtmContainerId,
    slug,
  }
}

export const parseTeamAndMeetingTypeToOrganization = (
  meetingType: SchedulerMeetingTypeNode,
  team: SchedulerTeamNode
): Organization => {
  const {
    id,
    brandColor,
    featureFlags,
    showAppointletBranding,
    gtmContainerId,
  } = meetingType.profile
  const { language, description, image, name, slug } = team
  return {
    id,
    brandColor,
    description,
    featureFlags: (featureFlags as ReadonlyArray<FeatureFlags>) || [],
    image,
    language,
    name,
    showAppointletBranding,
    gtmContainerId,
    slug,
  }
}

export const parseFieldsRemote = (
  remoteFields: GraphQLResponseItems,
  locationType: Maybe<string>
): Fields | null => {
  // all bookings will have required firstName, lastName and email fields
  const emailField = {
    id: 'Email',
    fieldId: 'email',
    kind: 'EMAIL',
    required: true,
    label: 'Email',
    helpText: '',
    choices: [],
    validators: {
      required: true,
      regex: /\S+@\S+\.\S+/,
    },
    visible: true,
  }

  const firstNameField = {
    id: 'FirstName',
    fieldId: 'firstName',
    kind: 'TEXT',
    label: 'First Name',
    helpText: '',
    choices: [],
    validators: {
      required: true,
    },
    visible: true,
  }

  const lastNameField = {
    id: 'LastName',
    fieldId: 'lastName',
    kind: 'TEXT',
    label: 'Last Name',
    helpText: '',
    choices: [],
    validators: {
      required: true,
    },
    visible: true,
  }
  // special fields are determined by meetingType.locationType enum
  const specialPlaceField = {
    id: 'Location',
    fieldId: 'location',
    kind: 'PLACE_USER',
    label: 'Meeting Place',
    helpText: 'Enter your meeting location here',
    choices: [],
    validators: {
      required: true,
    },
    visible: true,
    localizeExclude: false,
  }

  const specialPhoneField = {
    id: 'Phone',
    fieldId: 'location',
    kind: 'PHONE_USER',
    label: 'Contact Phone Number',
    helpText: 'Enter a phone number you can be reached at',
    choices: [],
    validators: {
      required: true,
    },
    visible: true,
    localizeExclude: false,
  }

  let initial: BaseMap<Field> = {
    firstName: firstNameField,
    lastName: lastNameField,
    email: emailField,
  }
  // changing location type here for testing
  if (locationType === 'PLACE_USER') {
    initial = {
      ...initial,
      location: specialPlaceField,
    }
  }

  if (locationType === 'PHONE_USER') {
    initial = {
      ...initial,
      location: specialPhoneField,
    }
  }

  const source = remoteFields.data.fieldsContainer
  if (source) {
    const items: BaseMap<Field> = source.fields.edges.reduce((acc, edge: FieldEdge) => {
      const { id, name, fieldType, helpText, required, choices, order, slug, visible } = edge.node

      const validators = {
        required,
      }

      const camelCaseSlug = snakeCaseToCamelCase(slug)

      return {
        ...acc,
        // using a number for remote fields keys and strings for 'initial' keys causes sort issues
        // key might be changed to fieldNode.slug when made available in graphQL query
        [camelCaseSlug]: {
          id,
          // react-final-form requires Nan value strings, so here we create a new ID for the form
          // to reference it's fields correctly while maintaining unique remote ID
          fieldId: camelCaseSlug,
          label: name,
          kind: fieldType,
          helpText,
          order,
          choices,
          validators,
          visible,
        },
      }
    }, initial)
    return getRepo(items, false)
  }
  return null
}
