export interface BaseMap<T> {
  [key: string]: T
}

export interface BaseRepository<T> {
  readonly items: BaseMap<T>
  readonly selected: string
  readonly sort: ReadonlyArray<string>
}

export const getEmptyRepo = () => {
  return {
    items: {},
    selected: '',
    sort: [],
  }
}

export const getRepo = <T>(items: BaseMap<T>, sortItems: boolean = true): BaseRepository<T> => {
  const keys = Object.keys(items)
  const sort = sortItems ? keys.sort() : keys
  return {
    items,
    sort,
    selected: '',
  }
}
