export type SessionType = '' | 'create' | 'reschedule' | 'cancel' | 'conference' | 'error'
export type SessionModes = null | 'modal' | 'inline'

export type LocationType =
  | ''
  | 'meetings'
  | 'hostMember'
  | 'time'
  | 'confirm'
  | 'complete'
  | 'reschedule'
  | 'cancel'
  | 'conference'
  | 'rescheduleBookingFailed'
  | 'rescheduleBookingSuccess'
  | 'cancelBookingFailed'
  | 'cancelBookingSuccess'
  | 'error404'
  | 'serverError'
  | 'noMeetings'

export interface Session {
  readonly location: LocationType
  readonly isLegacy: boolean
  readonly mode?: SessionModes
  readonly hasParent: boolean
  readonly goingForward: boolean | null
  readonly sessionType: SessionType
  readonly spinner: boolean
  readonly redirect: string
  readonly redirectReplace: boolean
  readonly startingDepth?: number
  readonly path?: string
  readonly polling?: boolean
}

const defaultSessionType: SessionType = ''
const defaultLocationType: LocationType = ''

export const defaultSession = {
  location: defaultLocationType,
  isLegacy: false,
  mode: undefined,
  hasParent: false,
  goingForward: null,
  sessionType: defaultSessionType,
  spinner: false,
  redirect: '',
  redirectReplace: false,
  startingPath: undefined,
  path: undefined,
  polling: undefined,
}
