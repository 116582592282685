import React from 'react'
import styled from 'styled-components'

import { appColors } from '../../../assets/app_colors'
import { shortDescLimit } from '../../../util/string'
import { MeetingInfoItems } from './meeting_info_items'
import { MeetingDescription } from './meeting_description'
import { Info } from 'react-feather'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { spacing } from '../../../util/style_utils'
import { Maybe } from 'graphql'
import { desktopStylesWrapper } from 'assets/shared_css_styles'
import { HostMember, LocationType } from 'common/redux/meetings/meetings_core'
export interface MeetingInfoProps {
  /**
   * The meeting id
   */
  readonly id?: string
  /**
   * The url for the meeting type image
   */
  readonly image?: Maybe<string>
  /**
   * The name of the meeting type
   */
  readonly name?: string
  /**
   * The description of the meeting type
   */
  readonly description?: Maybe<string>
  /**
   * The duration of the meeting type (ex: "30 minutes")
   */
  readonly duration?: number
  /**
   * The description of the meeting location
   */
  readonly locationType?: Maybe<LocationType>
  /**
   * The location to meet, overrides locationType
   */
  readonly location?: Maybe<string>
  /**
   * The start time of a meeting (ex: "9:00 am")
   */
  readonly startTime?: string
  /**
   * The end time of a meeting (ex: "9:30 am")
   */
  readonly endTime?: string
  /**
   * The date of the meeting (ex: "Wed February 3rd, 2021")
   */
  readonly date?: string
  /**
   * The date of the meeting (ex: "Wed. 02/03/2021")
   */
  readonly dateMobile?: string
  /**
   * Used for triggering the meeting info Modal
   */
  readonly setInfoModal?: (isOpen: boolean, id: string, modalType?: InfoModalType) => void
  /**
   * Price formatted as a string
   */
  readonly priceFormatted?: string
  /**
   * Currency used for price
   */
  readonly priceCurrency?: Maybe<string>
  readonly page?: string
  readonly isModal?: boolean
  readonly hostMember?: HostMember
}

export const MeetingInfo: React.FC<MeetingInfoProps> = ({
  id,
  image,
  name,
  description,
  duration,
  date,
  dateMobile,
  startTime,
  endTime,
  location,
  locationType,
  setInfoModal,
  priceFormatted,
  priceCurrency,
  page,
  isModal,
  hostMember,
}) => {
  const showMeetingInfo = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    if (setInfoModal && id) {
      setInfoModal(true, id, 'Info')
    }
  }

  return (
    <MeetingInfoContainer className="meeting-info-container" isModal={isModal}>
      {image && (
        <div className="meeting-info-image">
          <img src={image} alt={'Meeting Icon'} />
        </div>
      )}
      <div className="meeting-info-title-container">
        <MeetingTitle className="excl-lz" isModal={isModal}>
          {name}
        </MeetingTitle>
        <MeetingInfoItems
          {...{
            duration,
            startTime,
            endTime,
            location,
            locationType,
            date,
            dateMobile,
            page,
            priceFormatted,
            priceCurrency,
            isModal,
            hostMember,
          }}
        />
      </div>
      <InfoIconContainer className="meeting-info-button" onClick={showMeetingInfo}>
        <Info />
      </InfoIconContainer>
      <MeetingDescription
        description={description}
        limit={shortDescLimit}
        onMoreClick={showMeetingInfo}
      />
    </MeetingInfoContainer>
  )
}

interface ContainerProps {
  readonly isModal?: boolean
}
const InfoIconContainer = styled.div`
  margin-left: auto;
  width: 64px;
  color: ${appColors.gray400};
  vertical-align: middle;
  display: none;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #1e9bff;
    cursor: pointer;
  }
`

const MeetingTitle = styled.div<ContainerProps>`
  font-size: 14px;
  line-height: 16.71px;
  color: ${appColors.headerText};
  margin-bottom: ${spacing(1)};
  word-break: break-word;

  ${({ isModal }) => desktopStylesWrapper(isModal).wrapper`
    font-weight: 500;
    font-size: 16px;
  `}
`

const MeetingInfoContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .meeting-info-title-container {
    padding: 8px 16px;
  }
  .meeting-info-image {
    display: none;
  }
  .meeting-info-item {
    margin-top: 8px;
  }
  .meeting-info-button {
    display: flex;
    align-items: center;
  }
  .meeting-description {
    display: none;
  }
  .desktop-only {
    display: none;
  }

  ${({ isModal }) => desktopStylesWrapper(isModal).wrapper`
    max-width: 298px;
    width: 298px;
    padding: ${spacing(3)};
    flex-direction: column;

    .meeting-info-title-container {
      padding: 0;
    }
    .meeting-info-image {
      display: block;
      img {
        margin-bottom: ${spacing(1)};
        max-width: 50px;
      }
    }
    .meeting-info-item {
      margin-top: ${spacing(1)};
    }
    .meeting-info-button {
      display: none;
    }
    .meeting-description {
      display: block;
    }
    .mobile-only {
      display: none;
    }
    .desktop-only {
      display: grid;
    }
  `}
`
