import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState } from 'common/redux/sitka'
import { put } from 'redux-saga/effects'
import { Payment, defaultPayment } from './payment_core'

export class PaymentModule extends SitkaModule<Payment, AppModules> {
  public moduleName: string = 'payment'
  public defaultState: Payment = defaultPayment
  public static selectPayment(state: AppState): Payment {
    return state.payment
  }

  public *createPayment(meetingTypeId: string) {
    yield this.modules.ingress.createPayment(meetingTypeId || '')
  }

  public *set(state: Payment) {
    yield put(this.setState(state, true))
  }
}
