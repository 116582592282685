import { graphQLEndpoint } from '../build_params'
import {
  BookingSubmission,
  GraphQLResponseItems,
  CancelAttendeeInput,
  RescheduleAttendeeInput,
} from '../redux/remote/remote'
import { ErrorResp } from 'common/redux/error/error_remote'
import {
  getTeamAndMeetingTypesQuery,
  getTeamAndMeetingTypeQuery,
  getTimeZonesQuery,
  requestMeetingMutation,
  getAttendeeByIdQuery,
  getFormFieldsQuery,
  submitCancelAttendeeQuery,
  submitRescheduleAttendeeQuery,
  getAvailabilityQuery,
  getRescheduleAvailabilityQuery,
  getAttendeeByIdRescheduleQuery,
  createPaymentMutation,
  getHostMembersQuery,
} from './gql_queries'
import { SchedulerCreatePaymentInput } from 'graphql'

const request = require('superagent-promise')(require('superagent'), Promise)

export interface Resp {
  readonly body: GraphQLResponseItems
  readonly status: number
  readonly statusText: string
  readonly response: {
    readonly body: GraphQLResponseItems
    readonly status: number
    readonly statusText: string
    readonly error: ErrorResp | boolean
  }
}

export interface HandledResp {
  readonly items: GraphQLResponseItems
  readonly status: number
  readonly statusText: string
}

const handleResp = (resp: Resp): HandledResp => {
  // return normalized response object
  const returnResp = resp?.response ? resp.response : resp
  return { items: returnResp.body, status: returnResp.status, statusText: returnResp.statusText }
}

export class Client {
  rootUrl = graphQLEndpoint

  constructor() {
    this.fetchBookedAppointment = this.fetchBookedAppointment.bind(this)
    this.fetchBookedAppointmentReschedule = this.fetchBookedAppointmentReschedule.bind(this)
    this.getAvailableTimes = this.getAvailableTimes.bind(this)
    this.getFormFields = this.getFormFields.bind(this)
    this.getRescheduleTimes = this.getRescheduleTimes.bind(this)
    this.getTeamAndMeetingType = this.getTeamAndMeetingType.bind(this)
    this.getTeamAndMeetingTypes = this.getTeamAndMeetingTypes.bind(this)
    this.getTimeZones = this.getTimeZones.bind(this)
    this.submitCancelBooking = this.submitCancelBooking.bind(this)
    this.submitRequestMeeting = this.submitRequestMeeting.bind(this)
    this.submitCreatePayment = this.submitCreatePayment.bind(this)
    this.submitRescheduleBooking = this.submitRescheduleBooking.bind(this)
    this.getAvailableHostMembers = this.getAvailableHostMembers.bind(this)
  }

  getTeamAndMeetingTypes(teamSlug: string): Promise<HandledResp> {
    const body = {
      query: getTeamAndMeetingTypesQuery,
      variables: { teamSlug },
    }
    return request
      .post(this.rootUrl)
      .send(JSON.stringify(body))
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  getTeamAndMeetingType(teamSlug: string, meetingTypeSlug: string): Promise<HandledResp> {
    const body = {
      query: getTeamAndMeetingTypeQuery,
      variables: {
        teamSlug,
        meetingTypeSlug,
      },
    }
    return request
      .post(this.rootUrl)
      .send(JSON.stringify(body))
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  getAvailableHostMembers(slug: string, teamSlug: string): Promise<HandledResp> {
    const body = {
      query: getHostMembersQuery,
      variables: {
        slug,
        teamSlug,
      },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  getAvailableTimes(
    slug: string,
    teamSlug: string,
    start?: string,
    end?: string,
    hostMemberId?: string
  ): Promise<HandledResp> {
    const body = {
      query: getAvailabilityQuery,
      variables: {
        slug,
        teamSlug,
        start,
        end,
        hostMemberId,
      },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  getRescheduleTimes(externalId: string, start?: string): Promise<HandledResp> {
    const body = {
      query: getRescheduleAvailabilityQuery,
      variables: {
        externalId,
        start,
      },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  getFormFields(slug: string, teamSlug: string): Promise<HandledResp> {
    const body = {
      query: getFormFieldsQuery,
      variables: {
        slug,
        teamSlug,
      },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  getTimeZones(): Promise<HandledResp> {
    const body = {
      query: getTimeZonesQuery,
    }
    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  submitRequestMeeting(input: BookingSubmission): Promise<HandledResp> {
    const body = {
      query: requestMeetingMutation,
      variables: { input },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  submitCreatePayment(input: SchedulerCreatePaymentInput): Promise<HandledResp> {
    const body = {
      query: createPaymentMutation,
      variables: { input },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  submitCancelBooking(input: CancelAttendeeInput): Promise<HandledResp> {
    const body = {
      query: submitCancelAttendeeQuery,
      variables: { input },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  submitRescheduleBooking(input: RescheduleAttendeeInput): Promise<HandledResp> {
    const body = {
      query: submitRescheduleAttendeeQuery,
      variables: { input },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  fetchBookedAppointmentReschedule(externalId: string): Promise<HandledResp> {
    const body = {
      query: getAttendeeByIdRescheduleQuery,
      variables: { externalId },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }

  fetchBookedAppointment(externalId: string): Promise<HandledResp> {
    const body = {
      query: getAttendeeByIdQuery,
      variables: { externalId },
    }

    return request
      .post(this.rootUrl)
      .send(body)
      .type('application/json')
      .accept('application/json')
      .then((res: Resp) => handleResp(res))
      .catch((res: Resp) => handleResp(res))
  }
}
