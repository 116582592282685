import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Spinner } from '@blueprintjs/core'
import { useHistory } from 'react-router'
import { ArrowLeft } from 'react-feather'

import { appColors } from '../../assets/app_colors'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { appStrings } from '../../assets/app_strings'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { AvailableTimesModule } from 'common/redux/available_times/available_times_module'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { BucketedTimes } from 'common/redux/bucketed_times/bucketed_times_core'
import { Divider } from '../../components/atoms/divider'
import { DynamicLoading } from 'common/redux/dynamic_loading/dynamic_loading_core'
import { getTimeProps } from '../../util/components/page_container'
import { InfoModalModule } from 'common/redux/info_modal/info_modal_module'
import { IngressModule } from 'common/redux/ingress/ingress_module'
import { MeetingInfo } from '../../components/molecules/meeting_info/meeting_info'
import { HostMember, Meeting, Meetings } from 'common/redux/meetings/meetings_core'
import { Session } from 'common/redux/session/session_core'
import { spacing } from 'util/style_utils'
import { TimezoneModalModule } from 'common/redux/timezone_modal/timezone_modal_module'
import { TimezoneModule } from 'common/redux/timezone/timezone_module'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { TimezonePicker } from '../../components/molecules/timezone_picker'
import { WeekList, WeekListContainer } from '../../components/molecules/time_picker/week_list'
import { WeekNavigator } from './week_navigator'
import { FeatureFlags } from 'common/redux/organization/organization_core'
import { ErrorMessage } from 'components/molecules/error_message/error_message'
import { Button } from 'components/atoms/button/button'
import { desktopStylesWrapper, ContainerPropsModalMode } from 'assets/shared_css_styles'

const { loadingComplete } = appStrings
// These are here because the scenes need to be class components for now

interface SelectTimePageProps {
  readonly availableTimes: AvailableTimes
  readonly bookedAppointment: BookedAppointment
  readonly bucketedTimes: BucketedTimes
  readonly dynamicLoading: DynamicLoading
  readonly featureFlags: ReadonlyArray<FeatureFlags>
  readonly meetings: Meetings
  readonly session: Session
  readonly timezone: Timezone

  readonly availableTimesModule: AvailableTimesModule
  readonly infoModalModule: InfoModalModule
  readonly ingressModule: IngressModule
  readonly timezoneModalModule: TimezoneModalModule
  readonly timezoneModule: TimezoneModule
}

const Component: React.FC<SelectTimePageProps> = ({
  availableTimesModule: { handleRenderedTimes, handleSelectTime },
  infoModalModule: { handleSetInfoModal },
  ingressModule: { handleFetchAvailableTimes },
  timezoneModalModule: { handleSetTimezoneModal },
  timezoneModule: { handleChangeTimezone },
  availableTimes,
  bookedAppointment,
  bucketedTimes,
  dynamicLoading: { hasNextPage, nextPageStart },
  featureFlags,
  meetings,
  session,
  session: { sessionType, startingDepth },
  timezone,
}) => {
  const [currentWeek, setCurrentWeek] = useState<string>('')
  const [loadingRef, setLoadingRef] = useState<any>()
  const [lastNextPageStart, setLastNextPageStart] = useState<string | undefined>()

  let prevY = 0

  const handleObserver = (entities: any) => {
    const y = entities[0].boundingClientRect.y
    if (prevY > y && hasNextPage) {
      handleFetchAvailableTimes(false)
    }
    prevY = y
  }

  const checkVisible = debounce(() => {
    if (loadingRef) {
      const top = loadingRef.getBoundingClientRect().top
      if (top < window.innerHeight && hasNextPage && lastNextPageStart !== nextPageStart) {
        handleFetchAvailableTimes(false)
        setLastNextPageStart(nextPageStart)
      }
    }
  }, 100)

  useEffect(() => {
    let observer: IntersectionObserver
    if (loadingRef) {
      observer = new IntersectionObserver(handleObserver, {
        root: null, // Page as root
        rootMargin: '70px',
        threshold: 0,
      })
      observer.observe(loadingRef)
    }
    if (hasNextPage) {
      window.addEventListener('resize', checkVisible)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
      window.removeEventListener('resize', checkVisible)
    }
    // eslint-disable-next-line
  }, [loadingRef, hasNextPage])

  useEffect(() => {
    handleRenderedTimes()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    checkVisible()
    if (!hasNextPage) {
      window.removeEventListener('resize', checkVisible)
    }
  }, [
    checkVisible,
    loadingRef,
    nextPageStart,
    handleFetchAvailableTimes,
    hasNextPage,
    lastNextPageStart,
  ])
  const history = useHistory()
  const BackButton = (
    <Button
      onClick={() => history.goBack()}
      bg={appColors.lightBackground}
      border={appColors.gray400}
      color={appColors.gray700}
      padding={[12, 15]}
      fontWeight="normal"
    >
      <ArrowLeft color={appColors.gray700} />
      Back
    </Button>
  )
  const meeting: Meeting = meetings.items[meetings.selected]
  // Create and no times free
  if (sessionType === 'create' && (!meeting || availableTimes.sort.length === 0) && !hasNextPage) {
    return (
      <AlertWrapper>
        <ErrorMessage type="allTimesBooked" />
        {startingDepth === 1 && BackButton}
      </AlertWrapper>
    )
  }
  // Reschedule and not able to reschedule
  if (sessionType === 'reschedule' && bookedAppointment?.meeting?.isReschedulable === false) {
    return (
      <AlertWrapper>
        <ErrorMessage type="notReschedulable" />
        {startingDepth === 1 && BackButton}
      </AlertWrapper>
    )
  }
  // Reschedule and no times free
  if (
    sessionType === 'reschedule' &&
    (!meeting || availableTimes.sort.length === 0) &&
    !hasNextPage
  ) {
    return (
      <AlertWrapper>
        <ErrorMessage type="allTimesBooked" />
        {startingDepth === 1 && BackButton}
      </AlertWrapper>
    )
  }

  const timeProps = getTimeProps(
    bookedAppointment,
    meetings,
    session.sessionType,
    handleChangeTimezone,
    featureFlags,
    timezone
  )

  const changeTimezone = () => handleSetTimezoneModal(true)

  // get meta tag data from selected meeting
  const selectedMeetingId = meetings.selected !== '' ? meetings.selected : 'booked-appointment'

  const loadingMessage = hasNextPage ? <Spinner /> : !hasNextPage ? loadingComplete : ''
  const { location: page, mode } = session
  const isModal = mode === 'modal'
  const selectedMeetingType: Meeting = meetings.items[meetings.selected]

  // if rescheduling &  host assignment strategy is ATTENDEE_CHOOSES, pull hostMember from bookedAppointment state. Else pull from the currently selected meeting
  const hostMemberMeta =
    sessionType === 'reschedule' &&
    selectedMeetingType.hostAssignmentStrategy === 'ATTENDEE_CHOOSES'
      ? (bookedAppointment.meeting?.hostMember as HostMember)
      : meeting.selectedHostMember

  return (
    <DayTimePicker isModal={isModal}>
      <WeekNavigator
        setInfoModal={handleSetInfoModal}
        setTimezoneModal={handleSetTimezoneModal}
        id={selectedMeetingId}
        session={session}
        currentWeek={currentWeek}
        weeks={bucketedTimes.map(week => week.displayLong)}
        timeProps={timeProps}
        bookedAppointment={bookedAppointment}
        setCurrentWeek={setCurrentWeek}
        moreTimes={hasNextPage}
        location={meeting.location}
        locationType={meeting.locationType}
        hostMember={hostMemberMeta || undefined}
      />
      <WeekListContainer isModal={isModal}>
        <div className="mobile-meeting-info mobile-only">
          <MeetingInfo
            setInfoModal={handleSetInfoModal}
            id={selectedMeetingId}
            name={timeProps.name}
            description={timeProps.description}
            duration={timeProps.duration}
            location={meeting.location}
            locationType={meeting.locationType}
            image={timeProps.image}
            page={page}
            priceCurrency={timeProps.priceCurrency}
            priceFormatted={timeProps.priceFormatted}
            isModal={isModal}
            hostMember={hostMemberMeta || undefined}
          />
          <Divider />
          <TimezonePicker
            changeTimezone={changeTimezone}
            currentTime={timeProps.currentTime}
            isLocked={timeProps.timezone.isLocked}
            timezoneName={timeProps.timezone.momentCode}
          />
        </div>
        <WeekList
          bucketedTimes={bucketedTimes}
          availableTimes={availableTimes}
          timeZone={timeProps.timezone}
          reschedule={sessionType === 'reschedule'}
          select={handleSelectTime}
          isModal={isModal}
        />
        <ScrollLoader
          ref={loadingRef => setLoadingRef(loadingRef)}
          className={'loading-ref'}
          isModal={isModal}
        >
          <div id="more-times" />
          {bucketedTimes.length > 0 && <h3>{loadingMessage}</h3>}
        </ScrollLoader>
      </WeekListContainer>
    </DayTimePicker>
  )
}

export const SelectTimePage = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    availableTimes: state.availableTimes,
    bookedAppointment: state.bookedAppointment,
    bucketedTimes: state.bucketedTimes,
    dynamicLoading: state.dynamicLoading,
    featureFlags: state.organization.featureFlags,
    meetings: state.meetings,
    session: state.session,
    timezone: state.timezone,

    availableTimesModule: modules.availableTimes,
    infoModalModule: modules.infoModal,
    ingressModule: modules.ingress,
    timezoneModalModule: modules.timezoneModal,
    timezoneModule: modules.timezone,
  }
})(Component)

const DayTimePicker = styled.div<ContainerPropsModalMode>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .mobile-meeting-info {
    background-color: ${appColors.lightBackground};
  }

  /* Desktop view */

  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
    display: block;
    margin-top: 23px;
    width: 100vw;

    .mobile-only {
      display: none;
    }
  `}

  @media all and (min-width: 782px) {
    max-width: 972px;
  }
`

const AlertWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  button svg {
    height: ${spacing(2)};
    width: ${spacing(2)};
    margin-right: 5px;
  }
  /* Desktop view */
  @media all and (min-width: 699px) {
    margin-top: 48px;
  }
`

const ScrollLoader = styled.div<ContainerPropsModalMode>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: ${spacing(2)};

  h3 {
    margin: 0;
  }

  /* Desktop view */
  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
    margin: 16px 0px 25vh;
  `}
`
