import React from 'react'
import { Options } from './field_options'
import { FieldProps } from './custom_field'

interface MultipleChoiceFieldProps extends FieldProps {}

export const MultipleChoiceField = ({
  fieldId,
  choices,
  value,
}: MultipleChoiceFieldProps): JSX.Element => {
  return <Options value={value} id={fieldId} choices={choices} type="checkbox" />
}
