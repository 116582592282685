const profileDataFragment = `
fragment ProfileData on SchedulerProfileNode {
  id
  brandColor
  showAppointletBranding
  gtmContainerId
  featureFlags
}`

const teamDataFragment = `
fragment TeamData on SchedulerTeamNode {
  name
  image
  slug
  language
}`

const meetingTypeDataFragment = `
fragment MeetingTypeData on SchedulerMeetingTypeNode {
  name
  description
  locationType
  slug
  duration
  timezone
  image
  priceCurrency
  price
  id
  priceFormatted
  usesLocalTimezone
  hostAssignmentStrategy
}`

export const getTeamAndMeetingTypeQuery = `query getTeamAndMeetingType($teamSlug: String!, $meetingTypeSlug: String!) {
  team: getTeamBySlug(slug: $teamSlug) {
    name
    image
    slug
    language
    description
    profile {
      ...ProfileData
    }
  }
  meetingType: getMeetingType(teamSlug: $teamSlug, slug: $meetingTypeSlug) {
    ...MeetingTypeData
  }
}
${profileDataFragment}
${meetingTypeDataFragment}
`

export const getTeamAndMeetingTypesQuery = `query getTeamAndMeetingTypes($teamSlug: String!) {
  team: getTeamBySlug(slug: $teamSlug) {
    name
    image
    slug
    language
    description
    profile {
      ...ProfileData
    }
    meetingTypes {
      edges {
        node {
          ...MeetingTypeData
        }
      }
    }
  }
}
${profileDataFragment}
${meetingTypeDataFragment}
`

export const getHostMembersQuery = `query getTeamAndMeetingType($teamSlug: String!, $slug: String!) {
  meetingType: getMeetingType(teamSlug: $teamSlug, slug: $slug) {
    hostMembers {
      edges {
        node {
          id
          name
          image
        }
      }
    }
  }
}`

export const getTimeZonesQuery = `{
  timeZones: __type(name: "Timezone") {
    enumValues {
      name
      description
    }
  }
}`

export const getAttendeeByIdQuery = `query getAttendeeById($externalId: String!) {
  getAttendeeById(externalId: $externalId) {
    id
    externalId
    firstName
    lastName
    email
    status
    timezone
    meeting {
      conferenceUrl
      confirmed
      end
      isCancellable
      isReschedulable
      location
      locationType
      start
      meetingType {
        description
        duration
        id
        image
        location
        locationType
        name
        price
        priceCurrency
        priceFormatted
        slug
        hostAssignmentStrategy
        profile {
          ...ProfileData
        }
        team {
          ...TeamData
        }
      }
    }
  }
}
${profileDataFragment}
${teamDataFragment}`

export const getAttendeeByIdRescheduleQuery = `query getAttendeeByIdReschedule($externalId: String!) {
  getAttendeeById(externalId: $externalId) {
    id
    externalId
    firstName
    lastName
    email
    status
    timezone
    meeting {
      conferenceUrl
      confirmed
      end
      isCancellable
      isReschedulable
      location
      locationType
      start
      hostMember {
        id
        name
        image
      }
      reschedulableTimes {
        data
        hasNextPage
        nextPageStart
      }
      meetingType {
        description
        duration
        id
        image
        location
        locationType
        name
        price
        priceCurrency
        priceFormatted
        slug
        hostAssignmentStrategy
        profile {
          ...ProfileData
        }
        team {
          ...TeamData
        }
      }
    }
  }
}

${profileDataFragment}
${teamDataFragment}`

export const requestMeetingMutation = `mutation requestMeeting($input: RequestMeetingInput!) {
  requestMeeting(input: $input) {
    errors {
      field
      messages
    }
    data {
      externalId
      redirectUrl
    }
  }
}`

export const createPaymentMutation = `mutation createPayment($input: SchedulerCreatePaymentInput!) {
  createPayment(input: $input) {
    data {
      id
      amountDue
      providerPaymentPageUrl
    }
  }
}
`

export const getAvailabilityQuery = `query getAvailability($slug: String!, $teamSlug: String!, $start: DateTime, $end: DateTime, $hostMemberId:ID) {
  availability: getMeetingType(slug: $slug, teamSlug: $teamSlug) {
    availableTimes(start: $start, end: $end, hostMemberId:$hostMemberId) {
      data
      hasNextPage
      nextPageStart
    }
  }
}`

export const getRescheduleAvailabilityQuery = `query getRescheduleAvailability($externalId: String!, $start: DateTime) {
  availability: getAttendeeById(externalId: $externalId) {
    meeting {
      reschedulableTimes(start: $start) {
        data
        hasNextPage
        nextPageStart
      }
    }
  }
}`

export const submitCancelAttendeeQuery = `mutation cancelAttendee($input: SchedulerCancelAttendeeInput!) {
  cancelAttendee(input: $input) {
    errors {
      field
      messages
    }
  }
}`

export const submitRescheduleAttendeeQuery = `mutation rescheduleAttendee($input: SchedulerRescheduleAttendeeInput!) {
  rescheduleAttendee(input: $input) {
    errors {
      field
      messages
    }
  }
}`

export const getFormFieldsQuery = `query getFormFields($slug: String!, $teamSlug: String!) {
  fieldsContainer: getMeetingType(slug: $slug, teamSlug: $teamSlug) {
    fields {
      edges {
        node {
          id
          name
          fieldType
          helpText
          required
          choices
          order
          slug
          visible
        }
      }
    }
  }
}`
