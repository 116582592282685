import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { spacing } from '../../../../src/util/style_utils'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import { ReactComponent as warningIcon } from '../../../assets/Icons/warning_icon.svg'
import { Typography } from '../../../components/atoms/typography/typography'

const {
  errorPages: {
    noMeetingsFound,
    allTimesBooked,
    pageNotFound,
    serverError,
    notCancellable,
    notReschedulable,
  },
} = appStrings

export type ErrorType =
  | 'noMeetings'
  | 'serverError'
  | 'notFound'
  | 'allTimesBooked'
  | 'notReschedulable'
  | 'notCancellable'
export interface ErrorMessageProps {
  readonly type: ErrorType
  readonly message?: string
}

interface ErrorMeta {
  readonly Icon: FunctionComponent
  readonly message: string
  readonly iconColorOverride?: string
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ type, message: customMessage }) => {
  const createErrorMeta = (type: string): ErrorMeta => {
    switch (type) {
      case 'notReschedulable':
        return {
          Icon: warningIcon,
          message: notReschedulable,
          iconColorOverride: appColors.gray500,
        }
      case 'notCancellable':
        return {
          Icon: warningIcon,
          message: notCancellable,
          iconColorOverride: appColors.gray500,
        }
      case 'allTimesBooked':
        return {
          Icon: warningIcon,
          message: allTimesBooked,
          iconColorOverride: appColors.gray500,
        }
      case 'noMeetings':
        return {
          Icon: warningIcon,
          message: noMeetingsFound,
          iconColorOverride: appColors.gray500,
        }
      case 'serverError':
        return {
          Icon: warningIcon,
          message: serverError,
        }
      case 'notFound':
      default:
        return {
          Icon: warningIcon,
          message: pageNotFound,
        }
    }
  }

  const { Icon, message } = createErrorMeta(type)

  return (
    <ErrorMessageContainer {...createErrorMeta(type)}>
      <Icon />
      <Typography variant="h1" color={appColors.gray800}>
        {customMessage || message}
      </Typography>
    </ErrorMessageContainer>
  )
}

const ErrorMessageContainer = styled.div<ErrorMeta>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${spacing(2)};

  h1 {
    font-size: 24px;
  }

  p {
    margin-top: ${spacing(1)};
    font-size: 16px;
    letter-spacing: 0.01em;
  }
  a {
    color: ${appColors.primary};
  }
  svg {
    width: 64px;
    height: 64px;
    margin-bottom: ${spacing(2)};

    ${({ iconColorOverride }: ErrorMeta) =>
      iconColorOverride && `g path:first-child {fill: ${iconColorOverride}}`}
  }
`
