const colors = {
  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#212529',

  borderGray: '#DCDEE0',
  white: '#FFF',
  black: '#000',
  grey: '#CCC',
  highlight: '#fff9ea',
  highlightBorder: '#f2c94c',
  lightGrey: '#f7f8fa',
  mediumLightGrey: '#ebebec',
  mediumLightGrey2: '#e6e8ea',
  slate: '#8a9199',
  iron: '#d6d9dc',
  darkGrey: '#33383e',
  veryDarkGrey: '#272a2c',
  transGray: 'rgba(234, 236, 240, 0.73)',
  red: 'red',
  errorRed: '#c40404',
  transparent: 'transparent',
  shadow1: 'rgba(0, 0, 0, 0.05)',
  shadow2: 'rgba(0, 0, 0, 0.1)',
  shadow3: 'rgba(138, 145, 153, 0.3)',
  shadow4: 'rgba(138, 145, 153, 0.18)',
  shadow5: 'rgba(200, 201, 202, 0.38)',
  blue: '#2087e3',
  blueGradient1: 'rgba(255, 255, 255, 0.4)',
  blueGradient2: 'rgba(255, 255, 255, 0)',
  veryLightBlue: 'rgba(30, 155, 255, 0.07)',
  primaryBlue: '#1e9bff',
  blueSlate: '#60666c',
  raisin: '#212121',
  purpleHeart: '#973DD0',
  osloGray: '#898d91',
  successGreen: '#2ECC71',
}

export const appColors = {
  gray100: colors.gray100,
  gray200: colors.gray200,
  gray300: colors.gray300,
  gray400: colors.gray400,
  gray500: colors.gray500,
  gray600: colors.gray600,
  gray700: colors.gray700,
  gray800: colors.gray800,
  gray900: colors.gray900,

  text: colors.darkGrey,
  defaultThemeColor: colors.white,
  lightText: colors.white,
  labelText: colors.slate,
  button1: colors.slate,
  mediumText: colors.slate,
  mediumDarkText: colors.darkGrey,
  darkText: colors.veryDarkGrey,
  blueGreyText: colors.blueSlate,
  lightBackground: colors.white,
  mediumLightBackground: colors.mediumLightGrey,
  background: colors.lightGrey,
  navButton: colors.grey,
  transparent: colors.transparent,
  headerBorder: colors.mediumLightGrey,
  shadow1: colors.shadow1,
  shadow2: colors.shadow2,
  shadow3: colors.shadow3,
  shadow4: colors.shadow4,
  shadow5: colors.shadow5,
  blueBackground: colors.blue,
  blueGradient1: colors.blueGradient1,
  blueGradient2: colors.blueGradient2,
  errorRed: colors.errorRed,
  completeTopTitle: colors.darkGrey,
  completeTopSubtitle: colors.slate,
  completeTopBtn: colors.white,
  completeTopBtnBorder: colors.blue,
  completeTopBtnText: colors.darkGrey,
  infoItemBg: colors.white,
  infoItemBorder: colors.shadow5,
  infoItemText: colors.raisin,
  infoItemIcon: colors.slate,
  infoContainerBg: colors.lightGrey,
  helperText: colors.slate,
  timezoneSelectionBorder: colors.mediumLightGrey,
  weekNavigatorDivider: colors.mediumLightGrey,
  currentTimeText: colors.slate,
  unselectedWeek: colors.gray500,
  selectedWeekBackground: colors.veryLightBlue,
  weekHeaderBackgroundMobile: colors.gray200,
  weekHeaderBackgroundWeb: colors.white,
  weekGroupBorder: colors.iron,
  rescheduleHeaderBackground: colors.white,
  rescheduleHeaderBoxShadow: colors.shadow1,
  rescheduleHeaderBorder: colors.mediumLightGrey2,
  rescheduleHeaderText: colors.darkGrey,
  updateBookingFeebackBackground: colors.white,
  updateBookingFeebackFont: colors.slate,
  updateBookingFeedbackFooterBackground: colors.white,
  updateBookingContainerHeading: colors.darkGrey,
  formCompleteButton: colors.purpleHeart,
  customDateFont: colors.darkGrey,
  customDateMobileFont: colors.darkGrey,
  focusBorder: colors.primaryBlue,
  placeHolder: colors.osloGray,
  timezonePickerBg: colors.highlight,
  timezoneGlobe: colors.successGreen,
  headerText: colors.gray900,
  subHeaderText: colors.gray600,
  bodyText: colors.gray700,
  lightGrayIcon: colors.gray400,
  cardBorder: colors.gray300,
  primary: colors.primaryBlue,
  modalCloseButton: colors.white,
  successGreen: colors.successGreen,
  completeCard: {
    iconBackgrounds: {
      type: '#FEF4E3',
      time: '#E9FBE9',
      location: '#FFEDF4',
    },
    icon: {
      location: '#E5186F',
    },
  },
  selectMeeting: {
    description: {
      background: colors.highlight,
      border: colors.highlightBorder,
    },
  },
}
