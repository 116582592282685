import { Maybe } from 'graphql'
import React from 'react'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import { truncate, shouldTruncate } from '../../../util/string'

interface MeetingDescriptionProps {
  /**
   * The description text (\n chars are converted to line breaks)
   */
  readonly description?: Maybe<string>
  /**
   * The number of characters to allow before truncating (or 0 for no limit)
   */
  readonly limit?: number

  readonly onMoreClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

export const MeetingDescription: React.FC<MeetingDescriptionProps> = ({
  description,
  limit,
  onMoreClick,
}) => {
  if (limit === undefined) {
    return (
      <Container className="meeting-description excl-lz">
        <span>{description && description}</span>
      </Container>
    )
  }

  const isTruncated = shouldTruncate(description, limit)
  const displayText = truncate(description, limit)

  if (!displayText) {
    return null
  }

  return (
    <Container className="meeting-description">
      <span className="excl-lz">{displayText}</span>
      {isTruncated && (
        <>
          {' '}
          <span className="more-link" onClick={onMoreClick}>
            {appStrings.more}
          </span>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  font-size: 14px;
  line-height: 20px;

  margin-top: 10px;

  color: ${appColors.bodyText};

  .more-link {
    color: ${appColors.primary};
    cursor: pointer;
  }

  span {
    white-space: pre-line;
    overflow-wrap: break-word;
  }
`
