export type InfoModalType = 'InfoWithButton' | 'Info'

export interface InfoModal {
  readonly isOpen: boolean
  readonly meetingId: string
  readonly modalType: InfoModalType
}

export const defaultInfoModal: InfoModal = {
  isOpen: false,
  meetingId: '',
  modalType: 'InfoWithButton',
}
