import { Intent } from '@blueprintjs/core'
import { appStrings } from 'assets/app_strings'
import { AppModules, AppState } from 'common/redux/sitka'
import { SitkaModule } from 'olio-sitka'
import { put, select } from 'redux-saga/effects'
import { routes } from 'router'
import { handleActivity, initGoogleTagManager } from '../activity/activity_module'
import { AvailableTimes } from '../available_times/available_times_core'
import { AvailableTimesModule } from '../available_times/available_times_module'
import { BookedAppointment } from '../booked_appointment/booked_appointment_core'
import { BookedAppointmentModule } from '../booked_appointment/booked_appointment_module'
import { Meeting, Meetings } from '../meetings/meetings_core'
import { MeetingsModule } from '../meetings/meetings_module'
import { Session } from '../session/session_core'
import { SessionModule } from '../session/session_module'
import { getUtmContext } from '../utm/utm_util'
import { Bootstrap, defaultBootstrap } from './bootstrap_core'

const { NO_LONGER_AVAILABLE } = appStrings.errorMessage

interface RouteParams {
  team?: string
  meeting?: string
  time?: string
  time_or_host_member?: string
  meeting_id?: string
}

const routeNames = routes.reduce<Array<string>>((acc, value) => {
  acc.push(value.path)
  return acc
}, [])

export class BootstrapModule extends SitkaModule<Bootstrap, AppModules> {
  public moduleName: string = 'bootstrap'
  public defaultState: Bootstrap = defaultBootstrap
  public static selectBootstrap(state: AppState): Bootstrap {
    return state.bootstrap
  }

  public *handleBootstrap(query: string) {
    const { parent, self }: Window = window
    const hasParent: boolean = self !== parent

    const searchParams = new URLSearchParams(query)
    const isLegacy: boolean = searchParams.getAll('legacy').length > 0
    const mode: string = searchParams.get('mode') || ''

    const utm = getUtmContext(query).utm
    yield this.modules.utm.processUtm(utm)

    const session = yield select(SessionModule.selectSession)
    yield this.modules.session.set({ ...session, hasParent, isLegacy, mode })
    yield handleActivity('loaded')
  }

  public *handleNavigation(match: any) {
    const bootstrap = yield select(BootstrapModule.selectBootstrap)
    const session: Session = yield select(SessionModule.selectSession)

    if (
      bootstrap?.currentPage?.url !== match?.url &&
      routeNames.includes(match?.path) &&
      !session.spinner
    ) {
      yield this.modules.session.setSpinner(true)
      yield this.modules.session.setPath(match?.path)

      yield put(this.setState({ currentPage: match }, true))
      switch (match?.path) {
        case '/:team':
          yield this.bootstrapSelectMeeting(match.params)
          break
        case '/:team/:meeting':
          yield this.bootstrapSelectTimeOrHostMember(match.params)
          break
        case '/:team/:meeting/:time_or_host_member':
          yield this.bootstrapSelectTimeOrCompleteForm(match.params)
          break
        case '/:team/:meeting/:time_or_host_member/:time':
          yield this.bootstrapCompleteForm(match.params)
          break
        case '/meeting/:meeting_id':
          yield this.bootstrapCompletePage(match.params)
          break
        case '/m/:meeting_id/reschedule':
          yield this.bootstrapUpdateBooking(match.params)
          break
        case '/m/:meeting_id/cancel':
          yield this.bootstrapCancelBooking(match.params)
          break
        case '/m/:meeting_id/conference':
          yield this.bootstrapConference(match.params)
          break
      }

      yield initGoogleTagManager()
    }
  }

  *bootstrapSelectMeeting(params: RouteParams) {
    if (params.team) {
      yield this.modules.session.setSessionMeta('create', 'meetings')
      const error = yield this.modules.ingress.fetchMeetingTypes(params.team)
      if (error) {
        return
      }
      yield this.modules.timezone.resetTimezone()
      yield this.modules.session.setSpinner(false)
    }
  }

  *bootstrapSelectTimeOrHostMember(params: RouteParams) {
    if (params.team && params.meeting) {
      const { team, meeting } = params

      let error = yield this.loadMeetings(team, meeting)
      if (error) {
        return
      }

      const { hostAssignmentStrategy } = yield this.modules.meetings.getSelectedMeeting()

      //bootstrap correct page per hostAssignmentStrategy
      if (hostAssignmentStrategy === 'ATTENDEE_CHOOSES') {
        yield this.modules.session.setSessionMeta('create', 'hostMember')
        error = yield this.modules.ingress.fetchHostMembersForSelectedMeeting()
        if (error) {
          return
        }
      } else {
        yield this.modules.session.setSessionMeta('create', 'time')
        error = yield this.modules.ingress.fetchAvailableTimes(true)
        if (error) {
          return
        }
      }
      yield this.modules.session.setSpinner(false)
    }
  }

  // payment flow
  *bootstrapSelectTimeOrCompleteForm(params: RouteParams) {
    if (params.team && params.meeting && params.time_or_host_member) {
      const { team, meeting, time_or_host_member } = params

      let error = yield this.loadMeetings(team, meeting)
      if (error) {
        return
      }

      const selectedMeeting: Meeting = yield this.modules.meetings.getSelectedMeeting(meeting)

      //bootstrap correct page per hostAssignmentStrategy
      if (selectedMeeting.hostAssignmentStrategy === 'ATTENDEE_CHOOSES') {
        // hydrate host members in case user has refreshed or is navigating here via deeplink
        error = yield this.hydrateHostMembers(time_or_host_member)
        if (error) {
          return
        }

        yield this.modules.session.setSessionMeta('create', 'time')
        error = yield this.modules.ingress.fetchAvailableTimes(true)
        if (error) {
          return
        }
      } else {
        yield this.modules.session.setSessionMeta('create', 'confirm')
        error = yield this.modules.ingress.fetchAvailableTime(time_or_host_member)
        if (error) {
          yield this.modules.session.setRedirect(`/${team}/${meeting}/`, true)
          return
        }

        // Set selected time
        const availableTimes: AvailableTimes = yield select(
          AvailableTimesModule.selectAvailableTimes
        )

        // Check is the time is available
        if (!availableTimes.sort.includes(time_or_host_member)) {
          yield this.modules.error.handleShowError(NO_LONGER_AVAILABLE, Intent.DANGER, true)
          yield this.modules.session.setRedirect(`/${team}/${meeting}/`, true)
          return
        }

        yield this.modules.availableTimes.set({ ...availableTimes, selected: time_or_host_member })

        // Set selected meeting to fetched meeting
        error = yield this.modules.ingress.fetchFormFields()
        if (error) {
          return
        }

        // check meeting for payment price
        if (selectedMeeting.price) {
          // if price exist create payment for meeting
          error = yield this.modules.payment.createPayment(selectedMeeting.id)
          if (error) {
            return
          }
        }

        yield this.modules.fieldValues.loadLocalStorage(selectedMeeting.id || '')
      }
      yield this.modules.session.setSpinner(false)
    }
  }

  *bootstrapCompleteForm(params: RouteParams) {
    if (params.team && params.meeting && params.time && params.time_or_host_member) {
      const { team, meeting, time, time_or_host_member } = params
      yield this.modules.session.setSessionMeta('create', 'confirm')

      // Fetch meeting by slug
      let error = yield this.loadMeetings(team, meeting)
      if (error) {
        return
      }

      // hydrate host members in case user has refreshed or is navigating here via deeplink
      error = yield this.hydrateHostMembers(time_or_host_member)
      if (error) {
        return
      }

      // Check is the time is loaded
      error = yield this.modules.ingress.fetchAvailableTime(time)
      if (error) {
        yield this.modules.session.setRedirect(`/${team}/${meeting}/`, true)
        return
      }

      // Set selected time
      const availableTimes: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)

      // Check is the time is available
      if (!availableTimes.sort.includes(time)) {
        yield this.modules.error.handleShowError(NO_LONGER_AVAILABLE, Intent.DANGER, true)
        yield this.modules.session.setRedirect(`/${team}/${meeting}/`, true)
        return
      }

      yield this.modules.availableTimes.set({ ...availableTimes, selected: time })

      // Set selected meeting to fetched meeting
      error = yield this.modules.ingress.fetchFormFields()
      if (error) {
        return
      }

      const selectedMeeting: Meeting = yield this.modules.meetings.getSelectedMeeting(meeting)

      // check meeting for payment price
      if (selectedMeeting.price) {
        // if price exist create payment for meeting
        error = yield this.modules.payment.createPayment(selectedMeeting.id)
        if (error) {
          return
        }
      }

      yield this.modules.fieldValues.loadLocalStorage(selectedMeeting.id || '')
      yield this.modules.session.setSpinner(false)
    }
  }

  *bootstrapCompletePage(params: RouteParams) {
    if (params.meeting_id) {
      const session: Session = yield select(SessionModule.selectSession)
      yield this.modules.session.setSessionMeta(session.sessionType || 'create', 'complete')
      const error = yield this.modules.ingress.completedBooking(params.meeting_id)
      if (error) {
        return
      }
      yield this.modules.session.setSpinner(false)
    }
  }

  *bootstrapUpdateBooking(params: RouteParams) {
    if (params.meeting_id) {
      yield this.modules.session.setSessionMeta('reschedule', 'time')
      const error = yield this.modules.ingress.updateBooking(params.meeting_id)
      if (error) {
        return
      }
      yield this.modules.session.setSpinner(false)
    }
  }

  *bootstrapCancelBooking(params: RouteParams) {
    if (params.meeting_id) {
      yield this.modules.session.setSessionMeta('cancel', 'cancel')
      const error = yield this.modules.ingress.updateBooking(params.meeting_id)
      if (error) {
        return
      }
      yield this.modules.session.setSpinner(false)
    }
  }

  *bootstrapConference(params: RouteParams) {
    if (params.meeting_id) {
      yield this.modules.session.setSessionMeta('conference', 'conference')
      yield this.modules.ingress.updateBooking(params.meeting_id)
      const booking: BookedAppointment = yield select(
        BookedAppointmentModule.selectBookedAppointment
      )

      if (booking.meeting?.conferenceUrl) {
        window.location.href = booking.meeting?.conferenceUrl
      }
    }
  }

  *loadMeetings(team: string, meeting: string) {
    // Check if meeting is loaded if not load it
    const selectedMeeting: Meeting = yield this.modules.meetings.getSelectedMeeting(meeting)
    if (!selectedMeeting) {
      yield this.modules.ingress.fetchMeetingType(team, meeting)

      // Check if meeting exists
      const meetings: Meetings = yield select(MeetingsModule.selectMeetings)
      const meetingId = meetings.sort.find((id: string) => meetings.items[id].slug === meeting)

      if (!meetingId) {
        return true
      }
      if (meetings.items[meetingId].usesLocalTimezone) {
        yield this.modules.timezone.setTimezone(meetings.items[meetingId].timezone)
      }
      yield this.modules.meetings.set({ ...meetings, selected: meetingId })
    } else {
      const meetings: Meetings = yield select(MeetingsModule.selectMeetings)
      if (selectedMeeting.usesLocalTimezone) {
        yield this.modules.timezone.setTimezone(selectedMeeting.timezone)
      }
      yield this.modules.meetings.set({ ...meetings, selected: selectedMeeting.id })
    }
    return false
  }

  *hydrateHostMembers(hostMemberId: string) {
    const { hostMembers } = yield this.modules.meetings.getSelectedMeeting()
    if (hostMembers.length === 0) {
      let error = yield this.modules.ingress.fetchHostMembersForSelectedMeeting()
      if (error) {
        return
      }
      error = yield this.callAsGenerator(
        this.modules.meetings.handleSetSelectedHostMember,
        hostMemberId
      )
      if (error) {
        return
      }
    }
  }
}
