import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState, store } from 'common/redux/sitka'

import { call, select } from 'redux-saga/effects'
import { Client, HandledResp } from 'common/api/client'
import { parseStateIntoBookingSubmission } from '../remote/submit_booking_transform'
import { openWindow, PaymentErrorType, paymentProcess } from './egress_util'
import { BookedAppointment } from '../booked_appointment/booked_appointment_core'
import { parseErrors } from '../submit_update_booking/submit_update_booking_util'

import { Meeting } from 'common/redux/meetings/meetings_core'
import { handleActivity, trackEvent, trackGtmEvent } from '../activity/activity_module'
import { Intent } from '@blueprintjs/core'
import { appStrings } from 'assets/app_strings'
import { FieldValues } from '../field_values/field_values_core'
import { Session } from '../session/session_core'
import { SessionModule } from '../session/session_module'
import { BookedAppointmentModule } from '../booked_appointment/booked_appointment_module'
import { Payment } from '../payment/payment_core'
import { PaymentModule } from '../payment/payment_module'

const {
  errorMessage: { SHAPE, NO_URL_FROM_SERVER, NO_LONGER_AVAILABLE },
} = appStrings

export class EgressModule extends SitkaModule<{}, AppModules> {
  public moduleName: string = 'egress'
  public defaultState = {}

  public *handleSubmitBooking(fieldValues: FieldValues) {
    // update fields in state
    yield this.modules.fieldValues.set(fieldValues)

    // Submit booking activity
    yield handleActivity('submittedBooking')

    // parse booking response
    const selectedMeeting: Meeting = yield this.modules.meetings.getSelectedMeeting()

    if (selectedMeeting.price > 0) {
      yield this.paymentBooking()
    } else {
      yield this.postStandardBooking()
    }
  }

  startPayment = async (redirectUrl: string) => {
    try {
      await paymentProcess(redirectUrl)
    } catch (error) {
      return error
    }
  };

  // payment booking flow
  *paymentBooking() {
    // we get redirect url and id from payment state
    const paymentState: Payment = yield select(PaymentModule.selectPayment)
    const { redirectUrl, id } = paymentState

    // handle if theres no redirect url or id in payment state
    if (!redirectUrl || !id) {
      yield this.modules.error.handleShowError(NO_URL_FROM_SERVER, Intent.DANGER, true)
      return
    }
    yield this.modules.genericModal.setModalIsOpen(true, 'paymentWaiting')

    // we open payment window
    const paymentProcess = yield this.startPayment(redirectUrl)

    // check for errors or window closure
    if (
      paymentProcess === PaymentErrorType.Cancelled ||
      paymentProcess === PaymentErrorType.NetworkFailure
    ) {
      yield this.modules.genericModal.setModalIsOpen(false, 'paymentWaiting')
      return
    }
    // after payment window is closed we also close the modal
    yield this.modules.genericModal.setModalIsOpen(false, 'paymentWaiting')

    // payment is successful, run standard booking with payment id
    yield this.postStandardBooking()
  }

  // standard booking flow
  *postStandardBooking() {
    yield this.modules.session.setSpinner(true)
    // post booking
    const state: AppState = yield select(store.getState)
    const payload = parseStateIntoBookingSubmission(state)

    const client = new Client()
    const resp: HandledResp = yield call(client.submitRequestMeeting, payload)

    // Connection parse and error handle
    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      yield this.modules.session.setSpinner(false)
      return true
    }

    // Check for bad data shape or missing bookingMeeting
    const errors = resp?.items?.data?.requestMeeting?.errors
    if (errors && errors[0]?.messages[0] === 'That time is unavailable') {
      const meeting: Meeting = yield this.modules.meetings.getSelectedMeeting()
      const { teamSlug, slug } = meeting
      yield this.modules.error.handleShowError(NO_LONGER_AVAILABLE, Intent.DANGER, true)
      yield this.modules.session.setRedirect(`/${teamSlug}/${slug}/`, true)
      return
    }
    if (!resp?.items?.data?.requestMeeting?.data) {
      yield this.modules.error.handleShowError(SHAPE, Intent.DANGER, true)
      yield this.modules.session.setSpinner(false)
      return
    }

    const redirectUrl = resp.items.data.requestMeeting.data.redirectUrl
    const externalId = resp.items.data.requestMeeting.data.externalId

    yield call(trackEvent, 'Scheduled Meeting', state.organization.id)
    yield call(trackGtmEvent, 'Scheduled Meeting')

    const session: Session = yield select(SessionModule.selectSession)

    if (redirectUrl) {
      yield handleActivity('bookingCreated', redirectUrl)

      // if not inline or modal mode, handle redirect on this window
      // otherwise, appointlet.js will redirect parent window
      if (session.mode !== 'inline' && session.mode !== 'modal') {
        // redirect to redirectUrl
        window.location.href = redirectUrl
      }
      return
    }

    // set to redirect to completed page
    yield this.modules.session.setRedirect(`/meeting/${externalId}`, true)
  }

  public *handleShowPaymentWindow() {
    // We are already polling, reshow the window
    const bookedAppointment: BookedAppointment = yield select(
      BookedAppointmentModule.selectBookedAppointment
    )
    const providerPaymentPageUrl = bookedAppointment?.providerPaymentPageUrl
    const { externalId } = bookedAppointment

    if (!providerPaymentPageUrl || !externalId) {
      yield this.modules.error.handleShowError(NO_URL_FROM_SERVER, Intent.DANGER, true)
      return
    }

    openWindow(providerPaymentPageUrl, '_blank', {
      width: 1000,
      height: 750,
    })
  }
}
