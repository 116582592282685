import React from 'react'
import { Box } from '../../atoms/box'
import { Circle } from '../../atoms/circle'

interface InfoItemProps {
  icon: React.ReactNode
  iconBg: string
  marginTop?: number
  children: React.ReactNode
}

const InfoItem = ({ icon, iconBg, children, marginTop = 3 }: InfoItemProps) => (
  <Box display="flex" alignItems="center" mt={marginTop}>
    <Circle size={42} bg={iconBg}>
      {icon}
    </Circle>
    <Box ml={1.5}>{children}</Box>
  </Box>
)

export default InfoItem
