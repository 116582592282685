import { Sitka } from 'olio-sitka'
import { Store } from 'redux'

import { AvailableTimesModule } from './available_times/available_times_module'
import { BookedAppointmentModule } from './booked_appointment/booked_appointment_module'
import { BootstrapModule } from './bootstrap/bootstrap_module'
import { BucketedTimesModule } from './bucketed_times/bucketed_times_module'
import { FieldValuesModule } from './field_values/field_values_module'
import { MeetingsModule } from './meetings/meetings_module'
import { SessionModule } from './session/session_module'
import { OrganizationModule } from './organization/organization_module'
import { DynamicLoadingModule } from './dynamic_loading/dynamic_loading_module'
import { FieldsModule } from './fields/fields_module'
import { TimezoneModule } from './timezone/timezone_module'
import { IngressModule } from './ingress/ingress_module'
import { TimezoneModalModule } from './timezone_modal/timezone_modal_module'
import { InfoModalModule } from './info_modal/info_modal_module'
import { SubmitUpdateBookingModule } from './submit_update_booking/submit_update_booking_module'
import { UtmModule } from './utm/utm_module'
import { GenericModalModule } from './generic_modal/generic_modal_module'

import { AvailableTimes } from './available_times/available_times_core'
import { BookedAppointment } from './booked_appointment/booked_appointment_core'
import { Bootstrap } from './bootstrap/bootstrap_core'
import { BucketedTimes } from './bucketed_times/bucketed_times_core'
import { FieldValues } from './field_values/field_values_core'
import { Meetings } from './meetings/meetings_core'
import { Session } from './session/session_core'
import { Organization } from './organization/organization_core'
import { DynamicLoading } from './dynamic_loading/dynamic_loading_core'
import { Fields } from './fields/fields_core'
import { Timezone } from './timezone/timezone_core'
import { TimezoneModal } from './timezone_modal/timezone_modal_core'
import { EgressModule } from './egress/egress_module'
import { InfoModal } from './info_modal/info_modal_core'
import { ErrorModule } from './error/error_module'
import { Error } from './error/error_core'
import { SubmitUpdateBooking } from './submit_update_booking/submit_update_booking_core'
import { Utm } from './utm/utm_core'
import { GenericModal } from './generic_modal/generic_modal_core'
import { Payment } from './payment/payment_core'
import { PaymentModule } from './payment/payment_module'

const LOGGING: boolean = process.env.REACT_APP_LOGGING === 'true' || false
// const LOGGING: boolean = true

export interface AppModules {
  readonly availableTimes: AvailableTimesModule
  readonly bookedAppointment: BookedAppointmentModule
  readonly bootstrap: BootstrapModule
  readonly bucketedTimes: BucketedTimesModule
  readonly fieldValues: FieldValuesModule
  readonly organization: OrganizationModule
  readonly meetings: MeetingsModule
  readonly payment: PaymentModule
  readonly session: SessionModule
  readonly dynamicLoading: DynamicLoadingModule
  readonly fields: FieldsModule
  readonly timezone: TimezoneModule
  readonly ingress: IngressModule
  readonly timezoneModal: TimezoneModalModule
  readonly egress: EgressModule
  readonly infoModal: InfoModalModule
  readonly submitUpdateBooking: SubmitUpdateBookingModule
  readonly error: ErrorModule
  readonly utm: UtmModule
  readonly genericModal: GenericModalModule
}

export interface AppState {
  readonly availableTimes: AvailableTimes
  readonly bookedAppointment: BookedAppointment
  readonly bootstrap: Bootstrap
  readonly bucketedTimes: BucketedTimes
  readonly fieldValues: FieldValues
  readonly organization: Organization
  readonly meetings: Meetings
  readonly payment: Payment
  readonly session: Session
  readonly dynamicLoading: DynamicLoading
  readonly fields: Fields
  readonly timezone: Timezone
  readonly timezoneModal: TimezoneModal
  readonly infoModal: InfoModal
  readonly submitUpdateBooking: SubmitUpdateBooking
  readonly error: Error
  readonly utm: Utm
  readonly genericModal: GenericModal
}

export const sitka = new Sitka<AppModules>({
  log: LOGGING,
  sitkaInState: false,
})
sitka.register([
  new AvailableTimesModule(),
  new BookedAppointmentModule(),
  new BootstrapModule(),
  new BucketedTimesModule(),
  new FieldValuesModule(),
  new OrganizationModule(),
  new MeetingsModule(),
  new PaymentModule(),
  new SessionModule(),
  new DynamicLoadingModule(),
  new FieldsModule(),
  new TimezoneModule(),
  new IngressModule(),
  new TimezoneModalModule(),
  new EgressModule(),
  new InfoModalModule(),
  new SubmitUpdateBookingModule(),
  new ErrorModule(),
  new UtmModule(),
  new GenericModalModule(),
])

export const store: Store = sitka.createStore() as Store
