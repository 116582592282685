import React, { useEffect, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { FieldProps } from './custom_field'
import styled from 'styled-components'
import { ReactComponent as MarkerIcon } from '../../../assets/Icons/marker-icon.svg'
import { useField } from 'react-final-form'

export const PlaceField: React.FC<FieldProps> = ({ fieldId, value: fieldValue }) => {
  const [value, setValue] = useState<string>(fieldValue ? (fieldValue as string) : '')
  const formField = useField(fieldId)

  // The Google Places API has a concept called "session tokens",
  // which link multiple autocomplete requests together into a single
  // session.  We are billed by the session, so it's important that we
  // get as many requests bundled into a single session as possible.
  // It seems like sessions last about 3 minutes, so we should try and
  // refresh them with that frequency.
  //
  // Some relevant links:
  // https://github.com/hibiken/react-places-autocomplete/issues/263
  // https://stackoverflow.com/questions/50398801/how-long-do-the-new-places-api-session-tokens-last
  // https://developers.google.com/maps/documentation/places/web-service/session-tokens
  const [
    sessionToken,
    setSessionToken,
  ] = React.useState<google.maps.places.AutocompleteSessionToken>(
    () => new google.maps.places.AutocompleteSessionToken()
  )

  // Start an interval that fires every 3 minutes and refreshes the session token.
  useEffect(() => {
    const sessionRefresher = setInterval(
      () => setSessionToken(new google.maps.places.AutocompleteSessionToken()),
      60 * 3 * 1000
    )

    // When this component unloads kill the refresher
    return () => clearInterval(sessionRefresher)
  }, [])

  useEffect(() => {
    formField.input.onChange(value)
  }, [formField, value])

  return (
    <PlacesAutocomplete
      value={value}
      onChange={setValue}
      onSelect={setValue}
      debounce={1000}
      searchOptions={{
        sessionToken,
      }}
      // ONLY send a a request to Google Maps if the user input
      // is 7 characters in length or greater
      shouldFetchSuggestions={!!value && value.length >= 7}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div>
            <PlacesInputContainer>
              <MarkerIcon />
              {/*
              //@ts-ignore */}
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                  name: fieldId,
                })}
              />
            </PlacesInputContainer>
            {suggestions.length > 0 && (
              <DropdownContainer className="excl-lz">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                      key={i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </DropdownContainer>
            )}
          </div>
        )
      }}
    </PlacesAutocomplete>
  )
}

const PlacesInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(138, 145, 153, 0.41);
  border-radius: 5px;
  padding-left: 10px;

  input {
    width: 100%;
    border: none !important;
    padding-left: 10px !important;
  }

  svg {
    height: 20px;
    width: 20px;
    align-self: center;
  }
`

const DropdownContainer = styled.div`
  border: 1px solid rgba(138, 145, 153, 0.41);
  border-radius: 5px;
  margin-top: 5px;

  .suggestion-item {
    padding: 8px;
    cursor: pointer;
  }

  .suggestion-item:last-child {
    border-radius: 0 0 5px 5px;
  }

  .suggestion-item:first-child {
    border-radius: 5px 5px 0 0;
  }

  .suggestion-item--active {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 8px;
  }
`
