import React from 'react'
import { Field } from 'react-final-form'
import { FieldProps } from './custom_field'

interface TextFieldProps extends FieldProps {
  readonly type: string
}
export const TextField = ({
  fieldId,
  type,
  kind,
  autoFocus,
  value,
}: TextFieldProps): JSX.Element => {
  const useTextarea = kind === 'MULTITEXT'
  const component = useTextarea ? 'textarea' : 'input'

  return (
    <Field
      className={useTextarea ? 'bp3-input .modifier' : undefined}
      name={fieldId}
      value={value}
      type={type}
      rows={useTextarea ? 4 : undefined}
      component={component}
      autoFocus={autoFocus}
    />
  )
}
