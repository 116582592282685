import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { appColors } from '../../assets/app_colors'
import { CustomForm } from './custom_form/custom_form'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { Fields } from 'common/redux/fields/fields_core'
import { Meetings } from 'common/redux/meetings/meetings_core'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { FieldValuesModule } from 'common/redux/field_values/field_values_module'
import { InfoModalModule } from 'common/redux/info_modal/info_modal_module'
import { spacing } from '../../util/style_utils'
import { MeetingInfo } from 'components/molecules/meeting_info/meeting_info'
import { SidebarContainer } from 'components/molecules/sidebar/sidebar'
import { Card } from 'components/atoms/card'
import {
  defaultFontFamilies,
  desktopStylesWrapper,
  ContainerPropsModalMode,
} from 'assets/shared_css_styles'

interface ConfirmInfoPageProps {
  readonly availableTimes: AvailableTimes
  readonly fields: Fields
  readonly meetings: Meetings
  readonly mode?: string | null

  readonly fieldValuesModule: FieldValuesModule
  readonly infoModalModule: InfoModalModule
}

const Component: React.FC<ConfirmInfoPageProps> = ({
  availableTimes,
  fields,
  meetings,
  mode,

  fieldValuesModule: { handleRenderedFormFields },
  infoModalModule: { handleSetInfoModal: setInfoModal },
}) => {
  useEffect(() => {
    handleRenderedFormFields()
  }, [handleRenderedFormFields])

  if (!meetings.items[meetings.selected] || fields.sort.length === 0) {
    return <div />
  }

  const selectedMeeting = meetings.items[meetings.selected]
  const selectedTime = availableTimes.items[availableTimes.selected]
  const paymentMode = selectedMeeting.price > 0
  const isModal = mode === 'modal'
  return (
    <ConfirmInfoWrapper isModal={isModal}>
      <div className="container">
        <SidebarContainer isModal={isModal}>
          <Card>
            <MeetingInfo
              {...selectedMeeting}
              {...{
                setInfoModal,
                priceFormatted: paymentMode ? selectedMeeting.priceFormatted : undefined,
                date: selectedTime.date,
                dateMobile: selectedTime.dateMobile,
                startTime: selectedTime.display,
                endTime: selectedTime.end,
                hostMember: selectedMeeting.selectedHostMember || undefined,
                page: 'confirm',
                isModal,
              }}
            />
          </Card>
        </SidebarContainer>
        <div className="form-wrapper">
          <CustomForm />
        </div>
      </div>
    </ConfirmInfoWrapper>
  )
}

export const ConfirmInfoPage = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    fields: state.fields,
    meetings: state.meetings,
    availableTimes: state.availableTimes,
    mode: state.session.mode,

    fieldValuesModule: modules.fieldValues,
    infoModalModule: modules.infoModal,
  }
})(Component)

const ConfirmInfoWrapper = styled.div<ContainerPropsModalMode>`
${defaultFontFamilies}

/* mobile */
  width: 100%;
  background-color ${appColors.lightBackground};

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${appColors.lightBackground};

    .info-icon {
      width: 16px;
      height: 16px;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .fields-wrapper {
        width: 100%;
        padding: ${spacing(2, 0, 0)};
        display: flex;
        align-self: center;
        flex-direction: column;
        background-color: ${appColors.lightBackground};
        border-bottom: 1px solid ${appColors.shadow5};

        .full-width,
        .inline-field {
          width: 100%;
          margin-bottom: ${spacing(2)};
        }

        .hidden-field {
          display: none;
        }

        .field-group {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-self: center;
          white-space: pre-line;
          padding: ${spacing(0, 2)};

          input {
            font-size: 15px;
            font-weight: normal;
            border-radius: 5px;
            height: 40px;
            padding: 10px;
            background-color: #ffffff;
            border: solid 1px rgba(138, 145, 153, 0.41);
            color: ${appColors.mediumDarkText};
            text-transform: none;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;

            :focus {
              border-color: ${appColors.focusBorder};
              outline: none;
            }
            &::-webkit-input-placeholder {
              /* chrome/opera/safari */
              color: ${appColors.placeHolder};
            }
            &::-moz-placeholder {
              /* firefox 19+ */
              color: ${appColors.placeHolder};
            }

            &:-ms-input-placeholder {
              /* ie 10+ */
              color: ${appColors.placeHolder};
            }
            &:-moz-placeholder {
              /* firefox 18- */
              color: ${appColors.placeHolder};
            }
            &[type="time"] {
              width: 171px;
            }
          }

          select {
            width: 100%;
          }

          textarea {
            min-width: 100%;
            min-height: 80px;
            font-size: 15px;
            font-weight: normal;
            ${defaultFontFamilies}
            color: ${appColors.mediumDarkText};
            text-transform: none;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;

            :focus {
              border-color: ${appColors.focusBorder};
            }
          }

          .helper-top {
            font-size: 14px;
            color: ${appColors.helperText};
            line-height: 17px;
            word-break: break-word;
            margin-top: ${spacing(1)};
          }

          .field-label-wrapper {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: ${spacing(1)};

            &-wrapper {
              display: inline;
            }
          }
          .options-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 3px;

            & :last-child {
              margin-bottom: 0px;
            }
          }
          .is-required {
            position: relative;
            top: 0px;
            left: 10px;
            color: ${appColors.gray600};
          }

          .booleanField {
            display: inline-flex;
            p {
              margin-bottom: 0;
            }
          }
        }
      }

      .checkbox-button-container {
        width: 100%;
        padding: ${spacing(2)};
        display: flex;
        flex-direction: column;
        justify-content: center;

        .checkbox-container {
          display: none;
        }
      }
    }
  }

  /* desktop */
  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
    width: auto;
    max-width: 100%;
    background: 0;

    .container {
      margin-top: ${spacing(3)};
      flex-direction: row;
      background: 0;
      padding: ${spacing(0, 3)};

      .info-icon {
        width: 20px;
        height: 20px;
      }
      .form-wrapper {
        margin-left: 324px;
      }
      form {
        input[type="time"] {
          max-width: 139px;
        }

        .fields-wrapper {
          max-width: 600px;
          padding: ${spacing(3, 3, 0, 3)};
          flex-direction: row;
          flex-wrap: wrap;
          border-radius: 5px;
          box-shadow: 0px 1px 2px ${appColors.shadow1};
          border: 1px solid ${appColors.cardBorder};

          .field-group {
            padding: 0;
          }
          .inline-field {
            max-width: calc(50% - 12px);
            flex: auto;
            margin-bottom: ${spacing(3)};

            &:first-child {
              margin-right: ${spacing(3)};
            }
          }
          .full-width {
            margin-bottom: ${spacing(3)};
          }
        }

        .checkbox-button-container {
          padding: ${spacing(3, 0)};
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .checkbox-container {
            white-space: nowrap;
            margin-right: 32px;
            display: block;

            label {
              margin-bottom: 0px;
            }
          }
          .button-container {
            padding: 0;
            max-width: 320px;
            width: 100%;
          }
        }
      }
    }
  `}

  /* exceptions */
  @media all and (min-width: 320px) {
    .checkbox-button-container {
      button {
        height: 44px;
      }
    }
  }
`
