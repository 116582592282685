import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState } from 'common/redux/sitka'
import { Meetings, defaultMeetings, HostMember } from './meetings_core'

import { put, select } from 'redux-saga/effects'
import { handleActivity } from '../activity/activity_module'

export class MeetingsModule extends SitkaModule<Meetings, AppModules> {
  public moduleName: string = 'meetings'
  public defaultState: Meetings = defaultMeetings
  public static selectMeetings(state: AppState): Meetings {
    return state.meetings
  }

  public *getSelectedMeeting(slug: string = '') {
    const meetings: Meetings = yield select(MeetingsModule.selectMeetings)
    const meetingId = meetings.sort.find((id: string) => meetings.items[id].slug === slug)
    return meetingId ? meetings.items[meetingId] : meetings.items[meetings.selected]
  }

  public *handleSetSelectedHostMember(hostMemberId: string) {
    const meetings: Meetings = yield select(MeetingsModule.selectMeetings)
    const selected = meetings.items[meetings.selected]
    const hostMember =
      meetings.items[meetings.selected].hostMembers.find((mem: HostMember) => {
        return mem.id === hostMemberId
      }) || null
    const updatedMeeting = {
      ...selected,
      selectedHostMember: hostMember,
    }
    const newState = {
      ...meetings,
      items: {
        ...meetings.items,
        [updatedMeeting.id]: updatedMeeting,
      },
    }
    yield this.modules.meetings.set(newState)
  }

  public *handleRenderedMeetings() {
    yield handleActivity('loadedMeetings')
  }

  public *set(state: Meetings) {
    yield put(this.setState(state, true))
  }
}
