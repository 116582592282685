export interface TimeBucket {
  displayLong: string // "Next week (Apr 1 - Apr 8)"
  displayShort: string // "Next week (4/1 - 4/8)"
  days: ReadonlyArray<Day>
  unit: BucketUnit
}
export type BucketUnit = 'week'

export interface Day {
  start: string
  end: string
  dayNumber: string
  dayNumberSuffixed: string
  dayName: string
  dayNameShort: string
  month: string
  year: string
  times: ReadonlyArray<string>
  isToday: boolean
}

export interface BucketedTimes extends ReadonlyArray<TimeBucket> {}

export const defaultBucketedTimes: BucketedTimes = []
