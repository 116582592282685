import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { appColors } from '../../assets/app_colors'

import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { Session } from 'common/redux/session/session_core'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { SubmitUpdateBookingModule } from 'common/redux/submit_update_booking/submit_update_booking_module'
import { CancelForm } from '../../components/molecules/complete_card/cancel_form'
import { getConfirmContent, ConfirmContentIface } from '../../util/components/complete'
import { LocationType, Meeting, Meetings } from 'common/redux/meetings/meetings_core'
import { Organization } from 'common/redux/organization/organization_core'
import { ErrorMessage } from 'components/molecules/error_message/error_message'

interface UpdateBookingProps {
  readonly availableTimes: AvailableTimes
  readonly bookedAppointment: BookedAppointment
  readonly organization: Organization
  readonly meetings: Meetings
  readonly session: Session
  readonly timezone: Timezone

  readonly submitUpdateBookingModule: SubmitUpdateBookingModule
}

const Component: React.FC<UpdateBookingProps> = ({
  submitUpdateBookingModule: { handleUpdateBooking, handleUpdateBookingReason },

  availableTimes,
  bookedAppointment,
  bookedAppointment: { meeting },
  organization,
  meetings,
  session,
  timezone,
}) => {
  const meta: ConfirmContentIface | null = getConfirmContent(
    bookedAppointment,
    organization,
    availableTimes
  )

  if (!meta || !timezone) {
    return null
  }
  if (!bookedAppointment.meeting?.isCancellable) {
    return (
      <ErrorContainer>
        <ErrorMessage type="notCancellable" />
      </ErrorContainer>
    )
  }
  const meetingType: Meeting = meetings.items[meetings.selected]
  const { availableTimeStart, endTime, dateLong } = meta

  // Check for hostMember & host assignment strategy, if it's ATTENDEE_CHOOSES we use the host member info
  const hostMemberMeta =
    !!meeting?.hostMember && meetingType.hostAssignmentStrategy === 'ATTENDEE_CHOOSES'
      ? meeting?.hostMember
      : undefined

  return (
    <UpdateBookingContainer color={organization.brandColor}>
      <div className="content">
        <CancelForm
          meetingType={meetingType.name}
          startTime={availableTimeStart}
          endTime={endTime}
          timezone={timezone.display.replace(/\sTime$/, '')}
          date={dateLong}
          locationType={meeting!.locationType as LocationType}
          location={meeting!.location}
          sessionType={session.sessionType}
          submitUpdateBooking={handleUpdateBooking}
          setUpdateBookingReason={handleUpdateBookingReason}
          hostMember={hostMemberMeta}
        />
      </div>
    </UpdateBookingContainer>
  )
}

export const UpdateBookingPage = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    availableTimes: state.availableTimes,
    bookedAppointment: state.bookedAppointment,
    organization: state.organization,
    session: state.session,
    timezone: state.timezone,
    meetings: state.meetings,

    submitUpdateBookingModule: modules.submitUpdateBooking,
  }
})(Component)

/* ******************

       Styles

  ******************* */
const ErrorContainer = styled.div`
  width: 100%;
  padding-top: 16px;

  @media all and (min-width: 699px) {
    padding-top: 48px;
  }
`
const UpdateBookingContainer = styled.div<{ readonly color: string }>`
  width: 100%;
  height: min-content;

  h1 {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    font-size: 25px;
    color: ${appColors.updateBookingContainerHeading};
    font-family: 'Helvetica Neue';
    font-weight: 400;
    margin-bottom: 22px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cancel-form-card {
      margin: 0;
      padding: 16px;
      height: calc(100vh - 68px);
      border: transparent;
      box-shadow: none;
      border-radius: 0;

      h1 {
        font-size: 24px;
      }
      .wrap {
        word-break: break-word;
      }
    }
  }

  button {
    width: 100%;
    max-width: 100% !important;
    height: 40px;
    padding: 0 31px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500 !important;
  }

  @media all and (min-width: 699px) {
    .content {
      flex-direction: row;
      justify-content: center;

      .cancel-form-card {
        padding: 32px;
        margin-top: 20px;
        width: 480px;
        max-width: 480px;
        height: min-content;
        box-shadow: 0px 1px 2px ${appColors.shadow1};
        border-radius: 5px;
        border: 1px solid ${appColors.cardBorder};

        // this is to appease IE if height: min-content doesn't work
        display: -ms-grid; // this is to appease internet explorer
        -ms-grid-rows: min-content;
      }
    }

    button {
      width: 100%;
      margin: 0;
    }
  }
`
