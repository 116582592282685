import React from 'react'
import { Field } from 'react-final-form'
import { FieldProps } from './custom_field'
import styled from 'styled-components'

interface BooleanFieldProps extends FieldProps {}

export const BooleanField = ({ fieldId, helpText }: BooleanFieldProps): JSX.Element => {
  return (
    <InputLabel className={`bp3-control bp3-checkbox .modifier`}>
      <Field name={fieldId} component="input" type={'checkbox'} />
      <span className="bp3-control-indicator" />
      <div />
      <div className="booleanField">{helpText}</div>
    </InputLabel>
  )
}

const InputLabel = styled.label`
  margin-bottom: 0px;
  display: flex;
  align-items: center;

  .booleanField {
    margin-bottom: 2px;
    white-space: pre-line;
  }
`
