import { Timezone } from '../timezone/timezone_core'
import { LocationType } from '../meetings/meetings_core'
import { Maybe, SchedulerHostMemberNode } from 'graphql'

export interface BookedAppointment {
  readonly externalId?: string
  readonly status?: string
  readonly timezone?: Timezone | null
  readonly meeting?: Meeting
  readonly providerPaymentPageUrl?: string
}

export interface Meeting {
  readonly conferenceUrl: Maybe<string>
  readonly confirmed: boolean
  readonly end: string
  readonly isCancellable: Maybe<boolean>
  readonly isReschedulable: Maybe<boolean>
  readonly location: Maybe<string>
  readonly locationType: Maybe<LocationType>
  readonly start: string
  readonly hostMember: Maybe<SchedulerHostMemberNode>
}

export const defaultBookedAppointment = {}
