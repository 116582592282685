import React from 'react'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { AvailableTime } from 'common/redux/available_times/available_times_core'
import { appColors } from 'assets/app_colors'
import { desktopStylesWrapper } from 'assets/shared_css_styles'

interface TimeProps {
  readonly time: AvailableTime
  readonly reschedule: boolean
  readonly select: (id: string) => void
  readonly isModal: boolean
  readonly tabIndex: number
  readonly mapId: string
}

export const Time: React.FC<TimeProps> = ({
  time,
  reschedule,
  select,
  isModal,
  tabIndex,
  mapId,
}) => {
  const { display, id } = time || { display: '', id: '' }
  const match = useRouteMatch()
  const location = useLocation()
  const url = match?.url
    ? match?.url?.slice(-1) === '/'
      ? id + '/'
      : `${match?.url}/${id}/`
    : undefined

  if (reschedule) {
    return (
      <TimeButton
        className="time-button"
        onClick={() => select(id)}
        disabled={!url}
        isModal={isModal}
        tabIndex={tabIndex}
        id={mapId}
      >
        {display}
      </TimeButton>
    )
  }
  return (
    <TimeButtonLink
      to={{ pathname: url, search: location.search }}
      className="time-button excl-lz"
      disabled={!url}
      isModal={isModal}
      tabIndex={tabIndex}
      id={mapId}
    >
      {display}
    </TimeButtonLink>
  )
}

interface LinkProps {
  readonly disabled: boolean
  readonly isModal: boolean
}

const NavLinkStyle = (isModal: boolean) => css`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  font-size: 15px;
  border-radius: 4px;
  color: ${appColors.primary};
  background-color: ${appColors.lightBackground};
  box-shadow: 0 0 0 1px ${appColors.shadow4}, 0 1px 3px 0 ${appColors.shadow2};

  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: ${appColors.primary};
  }
  &:focus {
    outline: none;
    border: 3px solid ${appColors.primary};
  }
  ${desktopStylesWrapper(isModal).wrapper`
    width: 104px;
  `}
`

const TimeButtonLink = styled(NavLink)`
  ${({ disabled }: LinkProps) => (disabled ? 'pointer-events: none;' : '')};
  ${({ isModal }: LinkProps) => NavLinkStyle(isModal)}
`
const TimeButton = styled.button<LinkProps>`
  ${({ isModal }: LinkProps) => NavLinkStyle(isModal)}
  border: 0;
  background: inherit;
`
