import React from 'react'
import { TimezoneSelect } from '../timezone_select/timezone_select'
import { Classes } from '@blueprintjs/core'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import styled from 'styled-components'
import { ModalHeader } from '../modal_header'
import { Globe } from 'react-feather'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { TimezoneModal as TimezoneModalState } from 'common/redux/timezone_modal/timezone_modal_core'

interface TimezoneModalProps {
  readonly handleSetTimezoneModal: (isOpen: boolean) => void
  readonly setTimezone: (timezone: Timezone) => void
  readonly timezoneModalState: TimezoneModalState
}

const modalClasses = [Classes.OVERLAY_CONTENT, Classes.ELEVATION_4, 'modal'].join(' ')

export const TimezoneModal = (props: TimezoneModalProps) => {
  const { timezoneModalState, setTimezone, handleSetTimezoneModal } = props

  const {
    timezoneModal: { changeTimeZone },
  } = appStrings

  const onCloseClick = () => {
    handleSetTimezoneModal(false)
  }
  const GreenGlobe = (
    <Globe className="globe" color={appColors.timezoneGlobe} size={34} strokeWidth={1.5} />
  )

  return (
    <TimezoneModalStyled className={modalClasses}>
      <ModalHeader image={GreenGlobe} name={changeTimeZone} onCloseClick={onCloseClick} />
      <TimezoneSelect timezoneModal={timezoneModalState} setTimezone={setTimezone} />
    </TimezoneModalStyled>
  )
}

const TimezoneModalStyled = styled.div`
  width: 92%;
  height: calc(92% - 64px);
  min-height: 241px;
  max-height: 491px;
  background: ${appColors.lightBackground};
  overflow: hidden;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: grid;
  grid-template-rows: 68px 1fr;

  &.modal.bp3-overlay-enter {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
  }
  &.modal.bp3-overlay-enter-active {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    transition: opacity 300ms, transform 300ms;
  }
  &.modal.bp3-overlay-exit {
    opacity: 1;
  }
  &.modal.bp3-overlay-exit-active {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
    transition: opacity 300ms, transform 300ms;
  }

  @media all and (min-width: 699px) {
    width: 400px;
    left: calc(50% - 200px);
    top: 64px;
    transform: unset;
    &.modal.bp3-overlay-enter {
      transform: scale(0.9);
    }
    &.modal.bp3-overlay-enter-active {
      transform: scale(1);
    }
    &.modal.bp3-overlay-exit-active {
      transform: scale(0.9);
    }
  }
`
