const enStrings = {
  SELECT_A_MEETING: 'Select a meeting',
  SELECT_A_TIME: 'Select a time that works for you',
  CONFIRM_YOUR_APPOINTMENT: 'Enter your information to confirm your appointment',
  CURRENTLY: 'Currently',
  DURATION: 'Duration:',
  MINUTE: 'minute',
  MINUTES: 'minutes',
  HOUR: 'hour',
  HOURS: 'hours',
  TIMES_ARE_IN: 'Times are in',
  PACIFIC_STANDARD_TIME: 'Pacific Standard Time',
  THIS_WEEK: 'THIS WEEK',
  NEXT_WEEK: 'NEXT WEEK',
  MORE_TIMES: 'More times',
  MORE: 'more',
  AT_SYMBOL: '@',
  RIGHT_ARROW: '→',
  FULL_DETAILS: 'Full Details',
  EDIT: 'Edit',
  FROM: 'from',
  TO: 'to',
  SUBMIT: 'Submit',
  SUBMIT_AND_PAY: 'Submit & Pay',
  EXCEEDS_MAX_LENGTH: 'exceeds maximum length',
  IS_REQUIRED: 'required',
  INVALID_ERROR: 'invalid',
  SAVE_FOR_NEXT_TiME: 'Save for next time',
  POWERED_BY: 'Powered by',
  APPOINTLET: 'Appointlet',

  HEADER_TITLES: {
    CHOOSE_A_MEETING: 'Choose a meeting',
    CHOOSE_A_TIME: 'Choose a time',
    ENTER_YOUR_INFORMATION: 'Enter your information',
    BOOKING_CONFIRMED: 'Booking Confirmed',
    BOOKING_REQUESTED: 'Booking Requested',
    RESCHEDULE_CONFIRMED: 'Reschedule Confirmed',
    RESCHEDULED_REQUESTED: 'Reschedule Requested',
    MEETING_CANCELLED: 'Meeting Cancelled',
    CANCEL_BOOKING: 'Confirm your cancellation',
    CHOOSE_A_NEW_TIME: 'Choose a new time',
    REVIEW_AND_SUBMIT: 'Review & Submit',
    RESCHEDULE: 'Reschedule',
    SELECT_A_HOST_MEMBER: 'Choose a host',
  },

  LOADING_COMPLETE: 'All times showing',
  // location type display text
  LOCATION_TYPE_DESCRIPTION: {
    PLACE: `We'll meet in person`,
    PLACE_USER: `We'll meet in person`,
    PHONE: 'Meet on a phone call',
    PHONE_USER: 'Meet on a phone call',
    WEB_CONFERENCE: `We'll meet on a web conference`,
    GOOGLE_MEET: `We'll meet on a web conference`,
  },
  ERROR_MESSAGES: {
    CONNECT: 'Cannot connect to server. Please try again in a few minutes.',
    SHAPE: 'Unexpected data returned from server.',
    FETCH_BOOKED: 'Something went wrong fetching booked appointment', // eg 'Something went wrong fetching booked appointment - Initial Consultation'
    REST_ERROR: 'Server error: ', // eg 'Server error: 500'
    NO_LONGER_AVAILABLE: 'That time is no longer available',
    NO_URL_FROM_SERVER: 'Error: No payment URL returned from server',
    MEETING_NOT_FOUND: 'Meeting not found',
  },
}

const completeCard = {
  COMPLETE_HEADER_CONFIRMED: 'Thanks for booking!',
  COMPLETE_SUBTITLE_CONFIRMED: `You'll receive an email with a calendar invite.`,
  COMPLETE_HEADER_REQUESTED: 'Thanks for your booking request!',
  COMPLETE_SUBTITLE_REQUESTED: `We’ll confirm the booking with you ASAP.`,
  COMPLETE_HEADER_CANCEL: 'Are you sure you want to cancel?',
  COMPLETE_SUBTITLE_CANCEL: `We'll let TEAM_NAME know that you're not coming.`,
  COMPLETE_HEADER_CANCELLED: 'This meeting has been cancelled.',
  COMPLETE_SUBTITLE_CANCELLED: '',

  COMPLETE_HEADER_RESCHEDULED_CONFIRMED: `You're rescheduled!`,
  COMPLETE_HEADER_RESCHEDULED_REQUESTED: 'Thanks for your reschedule request!',

  COMPLETE_INFO_MEETING_TYPE: 'Meeting Type',
  COMPLETE_INFO_MEETING_TIME: 'Meeting Time',

  COMPLETE_LOCATION_GOOGLE_MEET: 'We’ll meet on Google Meet',
  COMPLETE_LOCATION_WEB_CONFERENCE: 'We’ll meet on a web conference',
  COMPLETE_LOCATION_PHONE: 'We’ll meet on a phone call',
  COMPLETE_APPOINTLET_BUTTON: 'Get your own Appointlet page for free!',
  COMPLETE_COMMENT_PLACEHOLDER: 'Anything you want to tell us? (optional)',
  COMPLETE_CANCEL: 'Cancel',
  COMPLETE_RESCHEDULE: 'Reschedule',
}

const timezoneModal = {
  changeTimeZone: 'Select your time zone',
}

const paymentWaitingModal = {
  waiting: 'Waiting for payment...',
  havingTrouble: 'Having trouble?',
  tryAgain: 'Try again',
  or: 'or',
  cancel: 'cancel',
}

const timezonePicker = {
  currently: 'Currently',
  change: 'Change',
}

const rescheduleHeader = {
  meetingDetails: 'Meeting Details',
  currentMeeting: 'Current Meeting',
}

const updateBookingPageHeader = {
  confirmReschedule: 'Confirm Reschedule',
  cancelAppointment: 'Cancel Appointment',
}

const updateBookingPage = {
  feedbackHeader: 'Reason',
  rescheduleBookingSubheader: 'Please let us know why you decided to reschedule your appointment!',
  cancelBookingSubheader: 'Please let us know why you decided to cancel your appointment!',
  rescheduleBookingButtonText: 'Reschedule Booking',
  cancelBookingButtonText: 'Cancel Booking',
}

const updateBookingPageButton = {
  rescheduleAppointment: 'Reschedule Appointment',
  cancelAppointment: 'Cancel Appointment',
}

const errorPages = {
  pageNotFound: "Sorry, we couldn't find that page.",
  serverError: 'Oops, something went wrong.',
  serverErrorSubtitle: 'Please try again in a few minutes.',
  noMeetingsFound: "There's nothing to schedule right now.",
  subtitleWithLogin1: 'If this is your page, please ',
  subtitleWithLogin2: 'login',
  subtitleWithLogin3: ' to find out more.',
  allTimesBooked: 'All available times are taken',
  notCancellable: "Sorry, this meeting can't be cancelled",
  notReschedulable: "Sorry, this meeting can't be rescheduled",
}

const paymentInformation = {
  heading: 'Payment Information',
  body:
    'After submitting your information, you’ll be sent to our payment provider to collect payment.',
}

export const appStrings = {
  headerTitles: enStrings.HEADER_TITLES,

  currently: enStrings.CURRENTLY,
  duration: enStrings.DURATION,
  minute: enStrings.MINUTE,
  minutes: enStrings.MINUTES,
  hour: enStrings.HOUR,
  hours: enStrings.HOURS,
  timesAreIn: enStrings.TIMES_ARE_IN,
  pacificStandardTime: enStrings.PACIFIC_STANDARD_TIME,
  thisWeek: enStrings.THIS_WEEK,
  nextWeek: enStrings.NEXT_WEEK,
  moreTimes: enStrings.MORE_TIMES,
  more: enStrings.MORE,
  atSymbol: enStrings.AT_SYMBOL,
  rightArrow: enStrings.RIGHT_ARROW,
  fullDetails: enStrings.FULL_DETAILS,
  edit: enStrings.EDIT,
  from: enStrings.FROM,
  to: enStrings.TO,
  completeBookingBtn: enStrings.SUBMIT,
  completeBookingBtnPrice: enStrings.SUBMIT_AND_PAY,
  saveInfoCheckbox: enStrings.SAVE_FOR_NEXT_TiME,
  exceedsLenthError: enStrings.EXCEEDS_MAX_LENGTH,
  requiredError: enStrings.IS_REQUIRED,
  invalidError: enStrings.INVALID_ERROR,
  poweredBy: enStrings.POWERED_BY,
  appointlet: enStrings.APPOINTLET,
  loadingComplete: enStrings.LOADING_COMPLETE,
  timezoneModal,
  timezonePicker,
  rescheduleHeader,
  updateBookingPageHeader,
  updateBookingPage,
  updateBookingPageButton,
  completeCard,
  // location type display text
  locationTypeDescription: enStrings.LOCATION_TYPE_DESCRIPTION,
  // Error Messages
  errorMessage: enStrings.ERROR_MESSAGES,
  errorPages,
  paymentInformation,
  paymentWaitingModal,
}
