import { GraphQLResponseItems, MeetingTypeNode, Team } from './remote'

export const transformSingleMeetingType = (resp: GraphQLResponseItems): GraphQLResponseItems => {
  return {
    data: {
      team: {
        ...(resp.data.team as Team),
        meetingTypes: {
          edges: [
            {
              node: resp.data.meetingType as MeetingTypeNode,
            },
          ],
        },
      },
    },
  }
}
