import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SelectableList } from './selectable_list'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { Meetings } from 'common/redux/meetings/meetings_core'
import { Organization } from 'common/redux/organization/organization_core'
import { MeetingsModule } from 'common/redux/meetings/meetings_module'
import { InfoModalModule } from 'common/redux/info_modal/info_modal_module'
import { ErrorPage } from 'pages/error/error_page'

interface SelectMeetingPageProps {
  readonly meetingsModule: MeetingsModule
  readonly infoModalModule: InfoModalModule

  readonly meetings: Meetings
  readonly organization: Organization
  readonly isModal: boolean
}

const Component: React.FC<SelectMeetingPageProps> = ({
  meetingsModule,
  meetings,
  organization,
  infoModalModule: { handleSetInfoModal },
  isModal,
}) => {
  useEffect(() => {
    meetingsModule.handleRenderedMeetings()
  }, [meetingsModule])

  return (
    <>
      {meetings.sort.length > 0 && (
        <SelectableList
          items={meetings}
          organization={organization}
          setInfoModal={handleSetInfoModal}
          isModal={isModal}
        />
      )}
      {meetings.sort.length === 0 && <ErrorPage type="noMeetings" isFullScreen={false} />}
    </>
  )
}

export const SelectMeetingPage = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    meetingsModule: modules.meetings,
    infoModalModule: modules.infoModal,

    meetings: state.meetings,
    organization: state.organization,
    isModal: state.session.mode === 'modal',
  }
})(Component)
