export interface Timezone {
  readonly display: string
  readonly momentCode: string
  readonly isLocked: boolean
  readonly name: string
}

export const defaultTimezone = {
  display: 'America/Los Angeles',
  momentCode: 'America/Los_Angeles',
  isLocked: false,
  name: 'AMERICA_LOS_ANGELES',
}
