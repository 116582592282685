import { BaseRepository, getEmptyRepo } from 'common/redux/base_map'

export interface AvailableTime {
  id: string
  display: string
  date: string
  dateLong: string
  dateMobile: string
  end?: string
  endValue: string
  iconType?: string
  className?: string
}

export interface AvailableTimes extends BaseRepository<AvailableTime> {}

export const defaultAvailableTimes: AvailableTimes = getEmptyRepo()
