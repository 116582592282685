import { GraphQLResponseItems } from './remote'
import { HostMember } from '../meetings/meetings_core'

export const transformRemoteHostMembers = (
  resp: GraphQLResponseItems
): ReadonlyArray<HostMember> => {
  return (
    resp.data.meetingType?.hostMembers?.edges.map(edge => {
      return {
        ...edge.node,
      }
    }) || []
  )
}
