import { Meeting, Meetings } from 'common/redux/meetings/meetings_core'
import { AppState } from 'common/redux/sitka'
import { ConfirmInfoPage } from 'pages/confirm_info/confirm_info_page'
import { SelectTimePage } from 'pages/select_time/select_time_page'
import React from 'react'
import { connect } from 'react-redux'

interface SelectTimeOrConfirmInfoPageProps {
  readonly meetings: Meetings
}

const Component: React.FC<SelectTimeOrConfirmInfoPageProps> = ({
  meetings,
}: SelectTimeOrConfirmInfoPageProps) => {
  const meeting: Meeting = meetings.items[meetings.selected]

  return meeting.hostAssignmentStrategy === 'ATTENDEE_CHOOSES' ? (
    <SelectTimePage />
  ) : (
    <ConfirmInfoPage />
  )
}

export const SelectTimeOrConfirmInfoPage = connect((state: AppState) => {
  return {
    meetings: state.meetings,
  }
})(Component)
