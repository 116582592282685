import React from 'react'
import styled from 'styled-components'
import { Day } from './day'
import { getIsMobile } from '../../../util/device_utils'
import { appColors } from '../../../assets/app_colors'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { Day as DayState } from 'common/redux/bucketed_times/bucketed_times_core'

interface DayListProps {
  readonly days: ReadonlyArray<DayState>
  readonly availableTimes: AvailableTimes
  readonly timeZone: Timezone
  readonly reschedule: boolean
  readonly select: (id: string) => void
  readonly isModal: boolean
}

export const DayList: React.FC<DayListProps> = ({
  days,
  availableTimes,
  timeZone,
  select,
  reschedule,
  isModal,
}) => {
  const isMobile = getIsMobile()
  return (
    <DayListContainer>
      {days.map((day, index) => (
        <Day
          key={day.start}
          times={day.times}
          availableTimes={availableTimes}
          day={day}
          index={index}
          isMobile={isMobile}
          timeZone={timeZone}
          reschedule={reschedule}
          select={select}
          isModal={isModal}
        />
      ))}
    </DayListContainer>
  )
}

const DayListContainer = styled.div`
  display: grid;
  background-color: ${appColors.lightBackground};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`
