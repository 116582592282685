import { SitkaModule } from 'olio-sitka'
import { DynamicLoading, defaultDynamicLoading } from './dynamic_loading_core'
import { AppModules, AppState } from 'common/redux/sitka'
import { put } from 'redux-saga/effects'

export class DynamicLoadingModule extends SitkaModule<DynamicLoading, AppModules> {
  public moduleName: string = 'dynamicLoading'
  public defaultState: DynamicLoading = defaultDynamicLoading
  public static selectDynamicLoading(state: AppState): DynamicLoading {
    return state.dynamicLoading
  }

  public *handleSetDynamicLoading(hasNextPage: boolean, fetching: boolean, nextPageStart: string) {
    yield put(this.setState({ hasNextPage, nextPageStart, fetching }, true))
  }

  public *set(state: DynamicLoading) {
    yield put(this.setState(state, true))
  }
}
