import React from 'react'
import styled from 'styled-components'
import { Overlay, Spinner } from '@blueprintjs/core'

export const ProgressSpinner: React.FC = () => {
  return (
    <Overlay isOpen={true} hasBackdrop={false} className="progress-spinner-test">
      <StyledSpinner className="bp3-large" />
    </Overlay>
  )
}

const StyledSpinner = styled(Spinner)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100% !important;
  z-index: 1001;

  .bp3-spinner-svg-container {
    width: 100px !important;
  }
`
