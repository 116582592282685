import { BaseMap, getRepo } from 'common/redux/base_map'
import {
  BucketedTimes,
  BucketUnit,
  Day,
  TimeBucket,
} from 'common/redux/bucketed_times/bucketed_times_core'
import { DateTime } from 'luxon'
import { AvailableTime, AvailableTimes } from '../redux/available_times/available_times_core'
import { computeDisplayLong, computeIsToday } from './remote/available_times_transform_util'

// Converts time string into AvailableTime object
export const toAvailableTime = (
  timeString: string,
  duration: number,
  timezone: string
): AvailableTime => {
  const timeWithZone = DateTime.fromISO(timeString).setZone(timezone)
  const endMoment = timeWithZone.plus({ minutes: duration })

  return {
    id: timeString,
    date: timeWithZone.toLocaleString({
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }),
    dateMobile: timeWithZone.toLocaleString({
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    }),
    dateLong: timeWithZone.toLocaleString({
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }),
    display: timeWithZone.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase(),
    endValue: endMoment.toISO()!,
    end: endMoment.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase(),
  }
}

// Converts array of times into AvailableTimes
export const parseAvailableTimes = (
  rawTimes: ReadonlyArray<string>,
  duration: number,
  timezone: string
): AvailableTimes => {
  return getRepo<AvailableTime>(
    rawTimes.reduce((acc, id) => {
      return {
        ...acc,
        [id]: toAvailableTime(id, duration, timezone),
      }
    }, {})
  )
}

// Separate times into days by start of day based on timezone
const getBucketedDays = (times: ReadonlyArray<string>, timezone: string): ReadonlyArray<Day> => {
  const days = times.reduce((acc, time) => {
    const start = DateTime.fromISO(time).setZone(timezone).startOf('day').toISO()!

    // if this is a new day, create a new day node.
    // if there is already a node for the day, add the time to the appropriate day
    return acc[start] === undefined
      ? {
          ...acc,
          [start]: getNewDay(time, start, timezone),
        }
      : {
          ...acc,
          [start]: {
            ...acc[start],
            times: [...acc[start].times, time],
          },
        }
  }, {} as BaseMap<Day>)

  const array: ReadonlyArray<Day> = Object.keys(days).map(day => days[day])
  return array
}

// Build a new day bucket object
const getNewDay = (time: string, start: string, timezone: string): Day => {
  const timeWithZone = DateTime.fromISO(time).setZone(timezone)

  return {
    start,
    end: timeWithZone.endOf('day').toISO()!,
    dayNumber: timeWithZone.toLocaleString({ day: 'numeric' }), // Localized day of the month
    dayNumberSuffixed: timeWithZone.toLocaleString({ day: 'numeric' }),
    dayName: timeWithZone.toLocaleString({ weekday: 'long' }), // Localized full day name
    dayNameShort: timeWithZone.toLocaleString({ weekday: 'short' }), // Localized short day name
    month: timeWithZone.toLocaleString({ month: 'long' }), // Localized full month name
    year: timeWithZone.toLocaleString({ year: 'numeric' }), // Localized year
    times: [time],
    isToday: computeIsToday(time, timezone),
  }
}

// Build a new TimeBucket object
const getNewBucket = (
  day: Day,
  start: string,
  bucketType: BucketUnit,
  timezone: string
): TimeBucket => {
  const startWithZone = DateTime.fromISO(start).setZone(timezone)
  const endWithZone = startWithZone.endOf('week')

  return {
    displayLong: computeDisplayLong(startWithZone, endWithZone, timezone),
    displayShort: `(${startWithZone.toLocaleString({
      month: 'numeric',
      day: 'numeric',
    })} - ${endWithZone.toLocaleString({ month: 'numeric', day: 'numeric' })})`,
    unit: bucketType,
    days: [day],
  }
}

// Transform days into bucketedTimes state object
const getBuckets = (
  days: ReadonlyArray<Day>,
  bucketType: BucketUnit,
  timezone: string
): BucketedTimes => {
  const buckets = days.reduce((acc, day) => {
    const start = DateTime.fromISO(day.start).setZone(timezone).startOf(bucketType).toISO()!
    return acc[start] === undefined
      ? {
          ...acc,
          [start]: getNewBucket(day, start, bucketType, timezone),
        }
      : {
          ...acc,
          [start]: {
            ...acc[start],
            days: [...acc[start].days, day],
          },
        }
  }, {} as BaseMap<TimeBucket>)

  const array: ReadonlyArray<TimeBucket> = Object.keys(buckets)
    .filter(w => buckets[w].displayLong !== 'THIS WEEK' && buckets[w].displayLong !== 'NEXT WEEK')
    .map(week => buckets[week])

  const thisWeek = Object.keys(buckets)
    .filter(w => buckets[w].displayLong === 'THIS WEEK')
    .map(w => buckets[w])

  const nextWeek = Object.keys(buckets)
    .filter(w => buckets[w].displayLong === 'NEXT WEEK')
    .map(w => buckets[w])

  return [...thisWeek, ...nextWeek, ...array]
}

export const parseBucketedTimes = (
  rawTimes: ReadonlyArray<string>,
  timezone: string
): BucketedTimes => {
  const bucketedDays = getBucketedDays(rawTimes, timezone)
  const bucketUnit = 'week'
  return getBuckets(bucketedDays, bucketUnit, timezone)
}
