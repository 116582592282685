import { appColors } from './app_colors'
import { css } from 'styled-components'
import { lighten, darken } from 'polished'

const { defaultThemeColor } = appColors

interface ActiveColorProps {
  readonly color: string
}

export const neutralStateColor = css`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0)),
    linear-gradient(
      ${({ color }: ActiveColorProps) => (!!color ? color : defaultThemeColor)},
      ${({ color }: ActiveColorProps) => (color ? color : defaultThemeColor)}
    );
`

export const hoverStateColor = css`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0)),
    linear-gradient(
      ${({ color }: ActiveColorProps) =>
        !!color ? lighten(0.05, color) : lighten(0.05, defaultThemeColor)},
      ${({ color }: ActiveColorProps) =>
        !!color ? lighten(0.05, color) : lighten(0.05, defaultThemeColor)}
    ) !important;
`

export const activeStateColor = css`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0)),
    linear-gradient(
      ${({ color }: ActiveColorProps) =>
        !!color ? darken(0.07, color) : darken(0.07, defaultThemeColor)},
      ${({ color }: ActiveColorProps) =>
        !!color ? darken(0.07, color) : darken(0.07, defaultThemeColor)}
    );
`

export const errorPageStyles = css`
  height: 100vh;
  width: 100%;

  a {
    color: ${appColors.primary};
  }
`

export const defaultFontFamilies = css`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
`

export const desktopStylesWrapper = (isModal: boolean | undefined) => {
  return {
    wrapper: (literals: TemplateStringsArray, ...args: any[]) => css`
      @media all and (min-width: ${isModal ? 932 : 699}px) {
        ${css(literals, ...args)};
      }
    `,
  }
}

export interface ContainerPropsModalMode {
  readonly isModal: boolean
}
