/**
 * We want to be able to toggle out environment variables
 * on Netlify based on the BRANCH that we are deploying from.
 * If the BRANCH === 'production' then throughout the app we
 * should load the production environment variables
 *
 * ex: REACT_APP_XXX
 *
 * If the BRANCH is not production then we load the staging
 * environment variables
 *
 * ex: REACT_APP_XXX_STAGING
 *
 * process.env.NETLIFY exists on Netlify and is always 'true'.
 * process.env.BRANCH exists on Netlify and is set to the branch
 * name from BitBucket/GitHub that the deploy was triggered from.
 */

const variables = process.env
const keys = Object.keys(variables)

export const env = (variable: string): string => {
  // Verify the requested variable exists at runtime.
  // Variables whether in .env locally or on Netlify are prefixed with 'REACT_APP'
  // so look up must include the prefix.
  if (keys.indexOf(`REACT_APP_${variable}`) === -1) {
    throw new Error(
      `${variable} does not exist!\n
      Verify on Netlify: \n
      https://app.netlify.com/sites/appointlet-dashboard/settings/deploys#environment\n
      Or if running locally double check your .env file.
      `
    )
  }
  // If we are executing on Netlify and not on the production branch...
  if (variables.REACT_APP_NETLIFY && variables.REACT_APP_BRANCH !== 'production') {
    return variables[`REACT_APP_${variable}_STAGING`]!
  }
  // If we are running locally or in production on Netlify...
  return variables[`REACT_APP_${variable}`]!
}
