export interface Utm {
  readonly utm_campaign: null | string
  readonly utm_content: null | string
  readonly utm_medium: null | string
  readonly utm_source: null | string
  readonly utm_term: null | string
}

export const defaultUtm = {
  utm_campaign: null,
  utm_content: null,
  utm_medium: null,
  utm_source: null,
  utm_term: null,
}
