import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { Intent } from '@blueprintjs/core'

import { appColors } from '../../../assets/app_colors'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { appStrings } from '../../../assets/app_strings'
import { CustomField } from './custom_field'
import { EgressModule } from 'common/redux/egress/egress_module'
import { ErrorModule } from 'common/redux/error/error_module'
import { Fields } from 'common/redux/fields/fields_core'
import { FieldValues } from 'common/redux/field_values/field_values_core'
import { FieldValuesModule } from 'common/redux/field_values/field_values_module'
import { getFormError, getFocused } from '../../../util/components/custom_form'
import { getIsMobile } from '../../../util/device_utils'
import {
  neutralStateColor,
  hoverStateColor,
  activeStateColor,
  defaultFontFamilies,
  desktopStylesWrapper,
  ContainerPropsModalMode,
} from '../../../assets/shared_css_styles'
import { Organization } from 'common/redux/organization/organization_core'
import { SaveInfoCheckbox } from '../save_info_checkbox'
import { TextButton } from '../../../components/atoms/button/text_button'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { Meetings } from 'common/redux/meetings/meetings_core'
import { InfoField } from 'components/atoms/info_field'
import { Session } from 'common/redux/session/session_core'

const {
  saveInfoCheckbox,
  completeBookingBtn,
  completeBookingBtnPrice,
  paymentInformation,
} = appStrings

interface CustomFormProps {
  readonly fields: Fields
  readonly fieldValues: FieldValues
  readonly organization: Organization
  readonly timezone: Timezone
  readonly session: Session
  readonly meetings: Meetings

  readonly fieldValuesModule: FieldValuesModule
  readonly egressModule: EgressModule
  readonly errorModule: ErrorModule
}

const Component: React.FC<CustomFormProps> = ({
  fields,
  fieldValues,
  meetings,
  fieldValuesModule: { handleSaveLocalStorage },
  egressModule: { handleSubmitBooking },
  errorModule: { handleShowError },
  organization: { brandColor },
  timezone,
  session: { mode },
}) => {
  const [saveInfo, setSaveInfo] = useState<boolean>()

  const selectedMeeting = meetings.items[meetings.selected]
  const validate = (values: any) => {
    if (saveInfo) {
      handleSaveLocalStorage(values, selectedMeeting.id)
    }

    const error = getFormError(values, fields)
    if (!error) {
      handleSubmitBooking(values)
    } else {
      handleShowError(error, Intent.WARNING)
    }
  }

  const { price, priceFormatted } = selectedMeeting
  const paymentMode = price > 0

  const completeBookingBtnLabel = paymentMode ? (
    <>
      <span>{completeBookingBtnPrice}</span>
      &nbsp;
      <span>{priceFormatted}</span>
    </>
  ) : (
    completeBookingBtn
  )

  const isMobile = getIsMobile()
  const fieldWithFocus = getFocused(fields, isMobile)
  const extraFields = fields.sort.slice(3).filter(field => fields.items[field].visible === true)
  const defaultFields = ['firstName', 'lastName', 'email']
  const showSaveInfoButton = mode !== 'inline' && mode !== 'modal'
  const isModal = mode === 'modal'

  return (
    <ColorThemedForm color={brandColor}>
      <Form
        onSubmit={validate}
        initialValues={fieldValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="fields-wrapper">
              {defaultFields.map((field, i) => (
                <CustomField
                  value={fieldValues[field]}
                  key={i}
                  field={fields.items[field]}
                  autoFocus={fieldWithFocus === field}
                  timezoneCode={timezone.momentCode}
                  defaultFields={defaultFields}
                />
              ))}
              {extraFields.length > 0 && <LineBreak isModal={isModal} />}
              {extraFields.map((field, i) => (
                <CustomField
                  value={fieldValues[field]}
                  key={i}
                  field={fields.items[field]}
                  autoFocus={fieldWithFocus === field}
                  timezoneCode={timezone.momentCode}
                  defaultFields={defaultFields}
                  localizeExclude={fields.items[field].localizeExclude !== false}
                />
              ))}
              {paymentMode && (
                <>
                  <LineBreak isModal={isModal} />
                  <InfoField
                    heading={paymentInformation.heading}
                    body={paymentInformation.body}
                    isModal={isModal}
                  />
                </>
              )}
            </div>
            <div className="checkbox-button-container">
              <div className="checkbox-container">
                {showSaveInfoButton && (
                  <SaveInfoCheckbox
                    id="checkbox"
                    descriptionText={saveInfoCheckbox}
                    setSaveInfo={setSaveInfo}
                  />
                )}
              </div>
              <div className="button-container">
                <TextButton
                  label={completeBookingBtnLabel}
                  type="submit"
                  disabled={false}
                  themeColor={brandColor}
                />
              </div>
            </div>
          </form>
        )}
      />
    </ColorThemedForm>
  )
}

export const CustomForm = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    fields: state.fields,
    fieldValues: state.fieldValues,
    organization: state.organization,
    timezone: state.timezone,
    session: state.session,
    meetings: state.meetings,

    fieldValuesModule: modules.fieldValues,
    egressModule: modules.egress,
    errorModule: modules.error,
  }
})(Component)

const LineBreak = styled.div<ContainerPropsModalMode>`
  && {
    height: 1px;
    background-color: ${appColors.shadow5};
    width: calc(100% + 24px);
    margin: 0px 0px 16px -16px;
    ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
      margin: 0px 0px 24px -24px;

    `}
  }
`

const ColorThemedForm = styled.div<{ readonly color: string }>`
  max-width: 100%;
  .bp3-control input:checked ~ .bp3-control-indicator,
  .bp3-control.bp3-checkbox ~ .bp3-control-indicator,
  button {
    ${neutralStateColor};
    box-shadow: none;
  }
  .bp3-control:hover input[type:checkbox]:checked ~ .bp3-control-indicator:hover,
  .bp3-control:hover input:indeterminate ~ .bp3-control-indicator:hover,
  button:hover {
    ${hoverStateColor};
  }
  .bp3-control:active input[type='checkbox']:checked ~ .bp3-control-indicator:active,
  .bp3-control.bp3-checkbox:active input:indeterminate ~ .bp3-control-indicator:active,
  save-info-label:active,
  button:active {
    ${activeStateColor};
  }
  .bp3-checkbox,
  label {
    word-break: break-word;
  }
  input[type='time'] {
    ${defaultFontFamilies}
  }
`
