import React from 'react'
import styled from 'styled-components'
import { MeetingInfo } from '../meeting_info/meeting_info'
import { TimezonePicker } from '../timezone_picker'

import { Card } from '../../atoms/card'
import { Divider } from '../../atoms/divider'

import { WeekSelector, WeekSelectorProps } from './week_selector'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { Maybe } from 'graphql'
import { spacing } from 'util/style_utils'
import { desktopStylesWrapper, ContainerPropsModalMode } from 'assets/shared_css_styles'
import { HostMember, LocationType } from 'common/redux/meetings/meetings_core'

export interface SidebarProps extends WeekSelectorProps {
  readonly timezone: Timezone
  readonly currentTime?: string
  readonly id?: string
  readonly name?: string
  readonly image?: Maybe<string>
  readonly description?: Maybe<string>
  readonly locationType?: Maybe<LocationType>
  readonly location?: Maybe<string>
  readonly duration?: number
  readonly date?: string
  readonly startTime?: string
  readonly endTime?: string
  readonly moreTimes?: boolean
  readonly setInfoModal: (isOpen: boolean, id: string, modalType?: InfoModalType) => void
  readonly setTimezoneModal?: (isOpen: boolean) => void
  readonly page?: string
  readonly priceFormatted?: string
  readonly priceCurrency?: Maybe<string>
  readonly hostMember?: HostMember
}

export const Sidebar = ({
  id,
  name,
  description,
  duration,
  date,
  startTime,
  endTime,
  location,
  locationType,
  timezone,
  currentTime,
  image,
  weeks = [],
  currentWeek,
  setCurrentWeek,
  moreTimes,
  setInfoModal,
  setTimezoneModal,
  page,
  priceFormatted,
  priceCurrency,
  hostMember,
}: SidebarProps) => {
  const { momentCode, isLocked } = timezone

  const handleChangeTimezone = () => setTimezoneModal && setTimezoneModal(true)

  return (
    <>
      <Card className="sidebar-card">
        <MeetingInfo
          {...{
            setInfoModal,
            id,
            name,
            description,
            duration,
            location,
            locationType,
            image,
            date,
            startTime,
            endTime,
            page,
            priceFormatted,
            priceCurrency,
            maxHeight: true,
            hostMember,
          }}
        />
        <Divider />
        <WeekSelector
          {...{
            weeks,
            currentWeek,
            setCurrentWeek,
            moreTimes,
          }}
        />
      </Card>
      <TimezonePickerContainer>
        <TimezonePicker
          timezoneName={momentCode}
          currentTime={currentTime}
          changeTimezone={handleChangeTimezone}
          isLocked={isLocked}
        />
      </TimezonePickerContainer>
    </>
  )
}

export const SidebarContainer = styled.div<ContainerPropsModalMode>`
  @media all and (max-width: ${({ isModal }: ContainerPropsModalMode) =>
      isModal ? '931' : '698'}px) {
    ${({ isModal }: ContainerPropsModalMode) =>
      isModal &&
      `> div {
      border-radius: 0;
      border-left: none;
      border-right: none;
      &.confirm {
        border: none;
      }
    }`}
  }
  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
    margin: ${spacing(0, 1.25, 2, 1.25)};
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0;
    background: 0;
    max-height: calc(100vh - ${({ isModal }: ContainerPropsModalMode) => (isModal ? 160 : 95)}px);
    overflow-y: scroll;
    position: fixed;
    box-sizing: border-box;
f
    .card {
      &.confirm {
        min-height: 240px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`

const TimezonePickerContainer = styled.div`
  margin: ${spacing(2, 0)};
`
