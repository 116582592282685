import { DateTime } from 'luxon'
import { TimezoneRemote, TimezoneRemoteItems } from '../remote/remote'
import { Timezone } from '../timezone/timezone_core'
import { TimezoneOption } from '../timezone_modal/timezone_modal_core'
import { deprecatedZones } from './../timezone/deprecated_zones'

const isValidTimezone = (tz: string): boolean => {
  try {
    const date = DateTime.now().setZone(tz)
    return date.isValid && date.zoneName === tz
  } catch {
    return false
  }
}

export const computeTimezoneValues = (
  remote: ReadonlyArray<TimezoneRemote>
): ReadonlyArray<TimezoneOption> => {
  return remote.reduce((acc: ReadonlyArray<TimezoneOption>, timezone: TimezoneRemote) => {
    const timezoneKey = timezone.description
    const correctedTimezone = deprecatedZones[timezoneKey]
      ? deprecatedZones[timezoneKey]
      : timezoneKey

    if (!isValidTimezone(correctedTimezone)) {
      return acc
    }
    const formattedTimezone = DateTime.now()
      .setZone(correctedTimezone)
      .toLocaleString(DateTime.TIME_SIMPLE)

    const searchKey = removeUnderscore(correctedTimezone).toLocaleLowerCase()
    const displayLabel = removeUnderscore(correctedTimezone)

    const timezoneOption: TimezoneOption = {
      label: correctedTimezone,
      value: formattedTimezone,
      displayLabel,
      searchKey,
    }
    return [...acc, timezoneOption]
  }, [])
}

export const parseTimezonesRemote = (
  remote: TimezoneRemoteItems
): ReadonlyArray<TimezoneRemote> => {
  return remote.data.timeZones.enumValues
}

const getUserTimezone = () => {
  const guess = DateTime.local().zoneName
  return deprecatedZones[guess] ? deprecatedZones[guess] : guess
}

export const getDefaultTimezone = (
  timezoneLock: string | undefined,
  timezones: ReadonlyArray<TimezoneRemote>
): Timezone => {
  if (timezoneLock) {
    const timezone = timezones.find(
      tz => tz.name === timezoneLock || tz.description === timezoneLock
    )
    if (timezone) {
      return buildTimezone(timezone.description, timezone.name, true)
    }
  }

  return buildTimezone(getUserTimezone())
}

const removeUnderscore = (momentCode: string) => {
  return momentCode.replace(/_/g, ' ')
}

const transformCodeToName = (momentCode: string) => {
  // in cases where timezoneLock is null, we need to send back an enum key: string that the server
  // can handle.  ie "America/Los-Angeles" is returned as "AMERICA_LOS_ANGELES"
  return momentCode.replace(/[/-]/g, '_').toUpperCase()
}

export const buildTimezone = (
  momentCode: string,
  name: string = 'default',
  isLocked: boolean = false
) => {
  const timezoneDisplay = removeUnderscore(momentCode) + ' Time'

  return {
    // Timezone displays either long form or relative offsets for the region
    // name is what get returned to the server
    display: timezoneDisplay,
    momentCode,
    isLocked,
    name: name !== 'default' ? name : transformCodeToName(momentCode),
  }
}

export const parseTimezoneOptionsToTimezonesRemote = (
  timezones: ReadonlyArray<TimezoneOption>
): ReadonlyArray<TimezoneRemote> => {
  return timezones.reduce((acc: ReadonlyArray<TimezoneRemote>, timezone: TimezoneOption) => {
    const remoteTimezone: TimezoneRemote = {
      description: timezone.displayLabel,
      name: transformCodeToName(timezone.displayLabel),
    }
    return [...acc, remoteTimezone]
  }, [])
}
