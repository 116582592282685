import { SitkaModule } from 'olio-sitka'
import { Utm, defaultUtm } from './utm_core'
import { AppModules, AppState } from 'common/redux/sitka'

import { select, put } from 'redux-saga/effects'
import { getNewUtm, UtmValues } from './utm_util'

export class UtmModule extends SitkaModule<Utm, AppModules> {
  public moduleName: string = 'utm'
  public defaultState: Utm = defaultUtm
  public static selectUtm(state: AppState): Utm {
    return state.utm
  }

  public *processUtm(utm: UtmValues): {} {
    // discontinue if there is no utm data
    if (Object.keys(utm).length === 0) {
      return
    }

    const originalUtm = yield select(UtmModule.selectUtm)
    const newUtm = getNewUtm(utm, originalUtm)
    yield put(this.setState(newUtm, true))
  }
}
