import { BaseRepository, getEmptyRepo } from 'common/redux/base_map'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { HostAssignmentStrategy, Maybe } from 'graphql'

export type LocationType =
  // Host chooses a place to meet
  | 'PLACE'
  // Attendee chooses a place to meet
  | 'PLACE_USER'
  // Host provides a number to call
  | 'PHONE'
  // Attendee provides a number to call
  | 'PHONE_USER'
  // Web Conference
  | 'WEB_CONFERENCE'
  // Google Meet
  | 'GOOGLE_MEET'

export interface HostMember {
  readonly id: string
  readonly name: string
  readonly image?: string
}

export interface Meeting {
  readonly description: Maybe<string>
  readonly duration: number
  readonly id: string
  readonly image: Maybe<string>
  readonly location: Maybe<string>
  readonly locationType: Maybe<LocationType>
  readonly name: string
  readonly price: number
  readonly priceCurrency: Maybe<string>
  readonly priceFormatted: string
  readonly teamSlug: string
  readonly slug: string
  readonly timezone: Timezone
  readonly usesLocalTimezone: boolean
  readonly hostAssignmentStrategy: HostAssignmentStrategy
  readonly hostMembers: ReadonlyArray<HostMember>
  readonly selectedHostMember: Maybe<HostMember>
}

export interface Meetings extends BaseRepository<Meeting> {}

export const defaultMeetings: Meetings = getEmptyRepo()
