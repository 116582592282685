import TagManager from 'react-gtm-module'
import { select } from 'redux-saga/effects'
import { OrganizationModule } from '../organization/organization_module'
import { SessionModule } from '../session/session_module'
import { UtmModule } from '../utm/utm_module'

import { env } from '../../util/env'

let CUSTOM_ANALYTICS_URL: string | undefined

try {
  CUSTOM_ANALYTICS_URL = env('CUSTOM_ANALYTICS_URL')
} catch (error) {
  // Error will be thrown on staging, because we do not
  // want these analytics running in the environment.
  // Catch the error & set to undefined for staging.
  CUSTOM_ANALYTICS_URL = undefined
}

type RemoteMessageType = 'scheduler:loaded' | 'scheduler:close' | 'booking:created' | undefined

type ActivityType =
  | 'addedToCalendar'
  | 'bookingCreated'
  | 'loaded'
  | 'loadedCompletedPage'
  | 'loadedMeetings'
  | 'loadedTimes'
  | 'loadedUserInfo'
  | 'requestedModalClose'
  | 'requestedTimeZoneChange'
  | 'selectedTime'
  | 'selectedTimeZone'
  | 'submittedBooking'

interface ActivityRemoteMessage {
  readonly type: RemoteMessageType
  readonly data: string | object
}

// This saga listens to activity emitted via an action triggered
// by other application logic, and sends any messages that are outbound
// to another saga that knows how to ship these messages to listeners
// outside of this application, and to locally log the activity in the browser.
export function* handleActivity(type: ActivityType, redirectUrl?: string): {} {
  const remoteMessages: ActivityRemoteMessage = getActivityMessage(type, redirectUrl)
  if (remoteMessages.type) {
    console.log('ACTIVITY', JSON.stringify(remoteMessages, null, 4)) // tslint:disable-line
    yield handleRemoteMessage(remoteMessages)
  }
}

// Handles broadcast of a message out to an enclosing window using postmessage.
function* handleRemoteMessage(message: ActivityRemoteMessage): {} {
  const session = yield select(SessionModule.selectSession)
  const opener = session.hasParent ? window.opener || window.parent : null

  const stringMsg = 'appointlet:' + JSON.stringify(message)

  // Post message if there is a parent / opener
  if (opener) {
    opener.postMessage(stringMsg, '*')
  }

  // Additionally check for WKWebView environment and send a message if applicable
  window?.webkit?.messageHandlers?.appointletListener?.postMessage?.(stringMsg)
}

function getActivityMessage(type: ActivityType, redirectUrl?: string): ActivityRemoteMessage {
  switch (type) {
    case 'loaded':
      return { type: 'scheduler:loaded' as RemoteMessageType, data: '' }
    case 'requestedModalClose':
      return { type: 'scheduler:close' as RemoteMessageType, data: '' }
    case 'bookingCreated':
      return {
        type: 'booking:created' as RemoteMessageType,
        data: { redirectUrl: redirectUrl },
      }
    default:
      return { type: undefined, data: '' }
  }
}

export async function trackEvent(name: string, profile_id: string): Promise<boolean> {
  if (!CUSTOM_ANALYTICS_URL) {
    console.warn('trackEvent - No CUSTOM_ANALYTICS_URL defined.', { name, profile_id })
    return Promise.resolve(true)
  }

  const url = new URL(CUSTOM_ANALYTICS_URL)

  // Add the event name
  url.searchParams.append('name', name)

  // Add information about the profile that peformed the event.
  url.searchParams.append('profile_id', profile_id)

  // We use this to ensure that the GET request is never cached.
  url.searchParams.append('cache_id', Math.random().toString())

  const res = await fetch(url.href, {
    method: 'GET',
    mode: 'no-cors',
  })

  if (!res.ok) {
    return Promise.resolve(false)
  }

  return Promise.resolve(true)
}

// Google Tag Manager

let gtmInitialized = false

export function* initGoogleTagManager() {
  if (gtmInitialized) {
    return
  }

  const organization = yield select(OrganizationModule.selectOrganization)
  if (!organization.gtmContainerId) {
    return
  }

  const utm = yield select(UtmModule.selectUtm)

  TagManager.initialize({
    gtmId: organization.gtmContainerId,
    dataLayer: {
      ...utm,
    },
  })

  gtmInitialized = true
}

export function* trackGtmEvent(name: string) {
  if (!gtmInitialized) {
    return
  }

  const utm = yield select(UtmModule.selectUtm)

  TagManager.dataLayer({
    dataLayer: {
      ...utm,
      event: name,
    },
  })
}
