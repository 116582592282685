export interface TimezoneModal {
  readonly isOpen: boolean
  readonly timezones: ReadonlyArray<TimezoneOption>
}

export interface TimezoneOption {
  readonly label: string
  readonly value: string
  readonly searchKey: string
  readonly displayLabel: string
}

export const defaultTimezoneModal = {
  isOpen: false,
  timezones: [],
}
