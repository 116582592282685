// deprecated zones will be overridden by new zone
// Zones are in pascal case and use underscores to separate words eg Africa/Addis_Ababa
// Deprecated Zone : New Zone
export const deprecatedZones: Record<string, string> = {
  'Africa/Porto-Novo': 'Africa/Lagos',
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Cordoba': 'America/Argentina/Cordoba',
  'America/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Montreal': 'America/Toronto',
  'America/Thunder_Bay': 'America/Toronto',
  'Asia/Calcutta': 'Asia/Kolkata',
  'Asia/Rangoon': 'Asia/Yangon',
  'Asia/Saigon': 'Asia/Ho_Chi_Minh',
  'Europe/Kiev': 'Europe/Kyiv',
}
