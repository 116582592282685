import React from 'react'
import { HostMember } from 'common/redux/meetings/meetings_core'
import emptyUser from 'assets/Icons/empty-user.svg'
import styled from 'styled-components'

interface HostMemberIconProps {
  hostMember?: HostMember
  className?: string
  size?: string | number
}

export const HostMemberIcon: React.FC<HostMemberIconProps> = ({ hostMember, className, size }) => {
  return (
    <Container size={size}>
      {hostMember &&
        (!!hostMember.image ? (
          <img
            className={`${className} host-member-image`}
            alt={hostMember.name}
            src={hostMember.image}
          />
        ) : (
          <img alt={hostMember.name} className={`${className} host-member-image`} src={emptyUser} />
        ))}
    </Container>
  )
}

interface ContainerProps {
  size?: string | number
}

const Container = styled.span<ContainerProps>`
  .host-member-image {
    width: ${({ size }) => size || 20}px;
    border-radius: 50%;
  }
`
