import { desktopStylesWrapper } from 'assets/shared_css_styles'
import React from 'react'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'

export interface PoweredByProps {
  brandColor?: string
  teamSlug: string
  isModal?: boolean
}

export const PoweredBy: React.FC<PoweredByProps> = props => {
  const { teamSlug, isModal = false } = props
  const url = `https://www.appointlet.com/?utm_source=appointlet&utm_campaign=branding&utm_medium=scheduler&utm_content=header&utm_term=${teamSlug}`
  /* eslint-disable react/jsx-no-target-blank */
  return (
    <Container {...props} isModal={isModal}>
      <a href={url} target="_blank">
        <span className="powered-by">{appStrings.poweredBy}</span>
        <span className="appointlet excl-lz">{appStrings.appointlet}</span>
      </a>
    </Container>
  )
  /* eslint-enable react/jsx-no-target-blank */
}

const Container = styled.div<PoweredByProps>`
  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    // adaptable background color
    background: ${({ brandColor }) => (brandColor ? brandColor : appColors.primary)};

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    color: ${appColors.lightText};

    > .powered-by {
      color: ${appColors.gray300};
      font-size: 10px;
      line-height: 12px;
    }

    > .appointlet {
      font-family: 'Space Grotesk';
      font-size: 14px;
      line-height: 18px;
    }

    height: 42px;

    // mobile dimensions
    width: 84px;
    border-radius: 0px 0px 0px 7px;
    ${({ isModal }) => isModal && 'border-top-right-radius: 4px;'}

    // desktop dimensions
    ${({ isModal }: PoweredByProps) => desktopStylesWrapper(isModal).wrapper`
      width: 100px;
      border-radius: 0px 0px 0px 16px;
      ${({ isModal }: PoweredByProps) => isModal && 'border-top-right-radius: 4px;'}
    `}
  }
`
