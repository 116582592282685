import { SitkaModule } from 'olio-sitka'
import { InfoModal, defaultInfoModal, InfoModalType } from './info_modal_core'
import { AppModules, AppState } from 'common/redux/sitka'
import { put, select } from 'redux-saga/effects'

export class InfoModalModule extends SitkaModule<InfoModal, AppModules> {
  public moduleName: string = 'infoModal'
  public defaultState: InfoModal = defaultInfoModal
  public static selectInfoModal(state: AppState): InfoModal {
    return state.infoModal
  }

  public *handleSetModalIsOpen(isOpen: boolean) {
    const state: InfoModal = yield select(InfoModalModule.selectInfoModal)
    if (isOpen === false) {
      yield put(this.setState({ ...state, isOpen, meetingId: '' }, true))
      return
    }
    yield put(this.setState({ ...state, isOpen }, true))
  }

  public *handleSetInfoModal(isOpen: boolean, meetingId: string, modalType?: InfoModalType) {
    yield put(this.setState({ isOpen, meetingId, modalType: modalType || 'Info' }, true))
  }

  public *set(state: InfoModal) {
    yield put(this.setState(state, true))
  }
}
