import { LocationType } from 'common/redux/meetings/meetings_core'
import { Maybe } from 'graphql'
import { appStrings } from '../../assets/app_strings'
import { CompleteCardState } from '../../components/molecules/complete_card/complete_card'

export const stateToColor = (state: CompleteCardState): string => {
  switch (state) {
    case 'confirmed':
    case 'rescheduleConfirmed':
    case 'rescheduleRequested':
    case 'meetingRequested':
      return '#2ecc71'
    case 'cancellationRequested':
    case 'cancelled':
      return '#cd2a2a'
  }
}

export const getLocationText = (
  location: Maybe<string>,
  locationType: LocationType
): [string, boolean] => {
  const { completeCard } = appStrings
  switch (locationType) {
    case 'GOOGLE_MEET':
      return [completeCard.COMPLETE_LOCATION_GOOGLE_MEET, true]
    case 'WEB_CONFERENCE':
      return [completeCard.COMPLETE_LOCATION_WEB_CONFERENCE, true]
    case 'PHONE':
    case 'PHONE_USER':
      return [completeCard.COMPLETE_LOCATION_PHONE, true]
    default:
      return [location || '', false]
  }
}

export const getHeaders = (state: CompleteCardState) => {
  const { completeCard } = appStrings
  switch (state) {
    case 'rescheduleConfirmed':
      return {
        header: completeCard.COMPLETE_HEADER_RESCHEDULED_CONFIRMED,
        subtitle: '',
      }
    case 'rescheduleRequested':
      return {
        header: completeCard.COMPLETE_HEADER_RESCHEDULED_REQUESTED,
        subtitle: '',
      }
    case 'confirmed':
      return {
        header: completeCard.COMPLETE_HEADER_CONFIRMED,
        subtitle: completeCard.COMPLETE_SUBTITLE_CONFIRMED,
      }
    case 'meetingRequested':
      return {
        header: completeCard.COMPLETE_HEADER_REQUESTED,
        subtitle: completeCard.COMPLETE_SUBTITLE_REQUESTED,
      }
    case 'cancellationRequested':
    case 'cancelled':
      return {
        header: completeCard.COMPLETE_HEADER_CANCELLED,
        subtitle: completeCard.COMPLETE_SUBTITLE_CANCELLED,
      }
  }
}
