import React from 'react'
import styled from 'styled-components'
import { Classes } from '@blueprintjs/core'

import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'

import { ModalHeader } from '../modal_header'
import { HostMember, LocationType } from 'common/redux/meetings/meetings_core'
import InfoItem from '../complete_card/complete_card_info_item'
import { Typography } from '../../atoms/typography/typography'
import { ReactComponent as MeetingTimeIcon } from 'assets/Icons/meeting-time.svg'
import { ReactComponent as MeetingTypeIcon } from 'assets/Icons/meeting-type.svg'
import { locationTypeToIcon } from '../meeting_info/meeting_info_items'
import { Check, MapPin } from 'react-feather'
import { spacing } from '../../../util/style_utils'
import UpdateBookingFeedback from '../../../pages/update_booking/feedback_box'
import { Button } from 'components/atoms/button/button'
import { HostMemberIcon } from 'components/atoms/host_member_icon'
import { Maybe, SchedulerHostMemberNode } from 'graphql'

export interface RescheduleModalProps {
  readonly setModal: (isOpen: boolean) => void
  readonly reschedule: (id: string) => void
  /**
   * Updates cancel reason
   */
  readonly setUpdateCancelReason: (reason: string) => void
  /**
   * The id of the selected time
   */
  readonly id: string
  /**
   * The name of the meeting type
   */
  readonly name: string
  /**
   * The description of the meeting location
   */
  readonly locationType: LocationType
  /**
   * The location to meet, overrides locationType
   */
  readonly location: string
  /**
   * The start time of a meeting (ex: "9:00 am")
   */
  readonly startTime: string
  /**
   * The end time of a meeting (ex: "9:30 am")
   */
  readonly endTime: string
  /**
   * The date of the meeting
   */
  readonly date: string
  /**
   * The timezone of the meeting
   */
  readonly timeZone: string
  /**
   * The chosen hostMember of the meeting
   */
  readonly hostMember?: Maybe<SchedulerHostMemberNode>
}

const modalClasses = [Classes.OVERLAY_CONTENT, Classes.ELEVATION_4, 'modal'].join(' ')

export const RescheduleModal = ({
  setModal,
  reschedule,
  setUpdateCancelReason,
  id,
  name,
  locationType,
  location,
  startTime,
  endTime,
  date,
  timeZone,
  hostMember,
}: RescheduleModalProps) => {
  const onCloseClick = () => {
    setModal(false)
  }

  const onChooseTimeClick = () => {
    reschedule(id)
  }

  const {
    completeCard: { iconBackgrounds, icon },
  } = appColors

  const LocationIcon = locationTypeToIcon[locationType]?.icon || MapPin

  return (
    <Modal className={modalClasses} showButton={true}>
      <ModalHeader
        {...{
          image: <Check size={36} strokeWidth={1.3} color={appColors.successGreen} />,
          name: appStrings.headerTitles.REVIEW_AND_SUBMIT,
          onCloseClick,
        }}
      />

      <ModalBody>
        <InfoItem marginTop={2} icon={<MeetingTypeIcon />} iconBg={iconBackgrounds.type}>
          <Typography variant="body1" className="wrap">
            {name}
          </Typography>
        </InfoItem>

        <InfoItem marginTop={2} icon={<MeetingTimeIcon />} iconBg={iconBackgrounds.time}>
          <Typography variant="body1">
            {`${startTime}${endTime ? ` - ${endTime}` : ''}`}{' '}
            <Typography variant="span" color={appColors.gray600}>
              {timeZone}
            </Typography>
          </Typography>
          <Typography variant="body1">{date}</Typography>
        </InfoItem>

        {location && (
          <InfoItem
            marginTop={2}
            icon={<LocationIcon color={icon.location} />}
            iconBg={iconBackgrounds.location}
          >
            <Typography variant="body1">{location}</Typography>
          </InfoItem>
        )}
        {hostMember && (
          <InfoItem
            icon={<HostMemberIcon hostMember={hostMember as HostMember} size={42} />}
            iconBg={appColors.lightBackground}
            marginTop={2}
          >
            <Typography variant="body1"> {hostMember?.name}</Typography>
          </InfoItem>
        )}
        <UpdateBookingFeedback marginTop={2} setComment={setUpdateCancelReason} hasButton={false} />
        <LineBreak />
      </ModalBody>
      <ModalFooter>
        <Button
          fullWidth
          type="submit"
          bg={appColors.primary}
          color={appColors.lightText}
          onClick={onChooseTimeClick}
        >
          {appStrings.headerTitles.RESCHEDULE}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

interface ModalProps {
  readonly showButton: boolean
}

const Modal = styled.div<ModalProps>`
  width: 92%;
  max-height: calc(92% - 64px);
  background: ${appColors.lightBackground};
  overflow: hidden;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: grid;
  grid-template-rows: ${({ showButton }) => (showButton ? '68px 1fr 72px' : '68px 1fr')};

  &:focus {
    outline: none;
  }

  // fade & scale transtion
  &.modal.bp3-overlay-enter {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
  }
  &.modal.bp3-overlay-enter-active {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    transition: opacity 300ms, transform 300ms;
  }
  &.modal.bp3-overlay-exit {
    opacity: 1;
  }
  &.modal.bp3-overlay-exit-active {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
    transition: opacity 300ms, transform 300ms;
  }
  .wrap {
    word-break: break-word;
  }
  @media all and (min-width: 699px) {
    width: 400px;
    left: calc(50% - 200px);
    top: 64px;
    transform: unset;
    &.modal.bp3-overlay-enter {
      transform: scale(0.9);
    }
    &.modal.bp3-overlay-enter-active {
      transform: scale(1);
    }
    &.modal.bp3-overlay-exit-active {
      transform: scale(0.9);
    }
  }
`

const LineBreak = styled.div`
  height: 1px;
  background-color: ${appColors.shadow5};
  width: calc(100% + ${spacing(6)});
  margin: ${spacing(2)} 0px 0px -${spacing(3)};
`

const ModalBody = styled.div`
  padding: 0 ${spacing(2)};
  grid-row: 2 / span 1;
  overflow-y: auto;
  overflow-x: hidden;
`

const ModalFooter = styled.div`
  padding: ${spacing(2)};
`
