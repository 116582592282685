export interface DynamicLoading {
  hasNextPage: boolean
  fetching: boolean
  nextPageStart?: string
}

export const defaultDynamicLoading: DynamicLoading = {
  hasNextPage: true,
  fetching: false,
  nextPageStart: undefined,
}
