import React from 'react'
import styled from 'styled-components'
import { Classes } from '@blueprintjs/core'

import { ReactComponent as EllipsisIcon } from 'assets/Icons/ellipsis-circle.svg'
import { appColors } from 'assets/app_colors'
import { spacing } from 'util/style_utils'
import { Typography } from 'components/atoms/typography/typography'
import { appStrings } from 'assets/app_strings'

const { paymentWaitingModal } = appStrings

export interface PaymentWaitingModalProps {
  readonly setModal: (isOpen: boolean) => void
  /**
   * Try again function
   */
  readonly retry: () => void
  /**
   * Cancel function
   */
  readonly cancel: () => void
}

const modalClasses = [Classes.OVERLAY_CONTENT, Classes.ELEVATION_4, 'modal'].join(' ')

export const PaymentWaitingModal: React.FC<PaymentWaitingModalProps> = ({
  retry,
  cancel,
  setModal,
}) => {
  const onCloseClick = () => {
    cancel()
    setModal(false)
  }

  return (
    <Modal className={modalClasses}>
      <StyledIcon fill={appColors.gray400} />
      <ModalTitle variant="h1">{paymentWaitingModal.waiting}</ModalTitle>
      <ModalBody variant="body1">
        {paymentWaitingModal.havingTrouble}&nbsp;&nbsp;
        <StyledLink onClick={retry}>{paymentWaitingModal.tryAgain}</StyledLink>
        &nbsp;
        {paymentWaitingModal.or}&nbsp;
        <StyledLink onClick={onCloseClick}>{paymentWaitingModal.cancel}</StyledLink>
      </ModalBody>
    </Modal>
  )
}

const StyledLink = styled.span`
  color: ${appColors.primary};

  &:hover {
    cursor: pointer;
  }
`

const StyledIcon = styled(EllipsisIcon)`
  height: 72px;
  width: 72px;
  margin-bottom: ${spacing(1.5)};
  overflow: initial;

  path:first-child {
    fill: ${appColors.gray400};
  }
`

const ModalTitle = styled(Typography)`
  font-size: 24px;
  margin-bottom: ${spacing(1.5)};
`

const ModalBody = styled(Typography)`
  color: ${appColors.gray600};
`

const Modal = styled.div`
  width: 92%;
  // max-height: calc(92% - 64px);
  background: ${appColors.lightBackground};
  overflow: hidden;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${spacing(6)};

  &:focus {
    outline: none;
  }

  // fade & scale transtion
  &.modal.bp3-overlay-enter {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
  }
  &.modal.bp3-overlay-enter-active {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    transition: opacity 300ms, transform 300ms;
  }
  &.modal.bp3-overlay-exit {
    opacity: 1;
  }
  &.modal.bp3-overlay-exit-active {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
    transition: opacity 300ms, transform 300ms;
  }

  @media all and (min-width: 699px) {
    width: 400px;
    left: calc(50% - 200px);
    top: calc(50% - 121px);
    transform: unset;
    &.modal.bp3-overlay-enter {
      transform: scale(0.9);
    }
    &.modal.bp3-overlay-enter-active {
      transform: scale(1);
    }
    &.modal.bp3-overlay-exit-active {
      transform: scale(0.9);
    }
  }
`
