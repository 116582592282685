import { appColors } from 'assets/app_colors'
import { desktopStylesWrapper, ContainerPropsModalMode } from 'assets/shared_css_styles'
import React from 'react'
import styled from 'styled-components'
import { spacing } from 'util/style_utils'
import { Typography } from './typography/typography'

interface InfoFieldProps {
  readonly heading: JSX.Element | string
  readonly body: JSX.Element | string
  readonly isModal: boolean
}

export const InfoField: React.FC<InfoFieldProps> = ({ heading, body, isModal }) => (
  <InfoFieldWrapper isModal={isModal}>
    <Typography variant="h4">{heading}</Typography>
    <p>{body}</p>
  </InfoFieldWrapper>
)

const InfoFieldWrapper = styled.div<ContainerPropsModalMode>`
  padding: ${spacing(0, 2, 2)};
  h4 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: ${spacing(1)};
  }

  p {
    margin: 0;
    color: ${appColors.helperText};
  }
  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
    padding: unset;
    margin-right: 24px;
    margin-bottom: 24px;
  `}
`
