import { ContainerPropsModalMode, desktopStylesWrapper } from 'assets/shared_css_styles'
import styled from 'styled-components'

export const SelectableListContainer = styled.div<ContainerPropsModalMode>`
  width: 100%;
  margin: 16px;
  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
  max-width: 594px;
  margin: 23px 23px 0 23px;
`}
`
