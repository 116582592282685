import { Utm } from './utm_core'
import { BaseMap } from 'common/redux/base_map'
import { queryStringToObject } from '../../util/query_string_utils'

export type UtmValues = BaseMap<string>

interface SearchContext {
  readonly utm: UtmValues
}

const handleUtm = (acc: SearchContext, key: string, value: string): SearchContext => ({
  ...acc,
  utm: { ...acc.utm, [key]: value },
})

export const getUtmContext = (search: string): SearchContext => {
  const params = queryStringToObject(search)

  return Object.keys(params).reduce(
    (acc: SearchContext, key) => {
      const isUtmField = key.includes('utm_')

      return isUtmField ? handleUtm(acc, key, params[key]) : acc
    },
    { utm: {} }
  )
}

export const getNewUtm = (prospectiveUtm: UtmValues, originalUtm: Utm): Utm => {
  const utmParams = Object.keys(originalUtm) as Array<keyof Utm>

  // if key exist on prospective UTM, use prospective UTM value, else use original value
  return utmParams.reduce(
    (acc, key): Utm =>
      !!prospectiveUtm[key]
        ? { ...acc, [key]: prospectiveUtm[key] }
        : { ...acc, [key]: originalUtm[key] },
    originalUtm
  )
}
