/**
 * This is a horizontal list item that supports an image and text label.
 * It by default expands to fit its container.
 */

import React from 'react'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'
import { Clock, Info, CreditCard } from 'react-feather'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import { spacing } from 'util/style_utils'
import { Maybe } from 'graphql'

export interface ListItemProps {
  readonly id: string
  readonly className?: string
  readonly slug: string
  readonly label: string
  readonly subLabel?: JSX.Element
  readonly image: Maybe<string>
  readonly price: string
  readonly showPrice: boolean
  readonly setInfoModal: (isOpen: boolean, id: string, modalType: InfoModalType) => void
}

export const ListItem = ({
  id,
  className,
  slug,
  label,
  subLabel,
  image,
  price,
  showPrice,
  setInfoModal,
}: ListItemProps) => {
  const hasSubLabel = subLabel ? true : false

  const showMeetingInfo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setInfoModal(true, id, 'InfoWithButton')
  }

  const match = useRouteMatch()
  const location = useLocation()
  const url = match?.url
    ? match?.url?.slice(-1) === '/'
      ? slug + '/'
      : `${match?.url}/${slug}/`
    : undefined

  return (
    <LinkWrapper {...{ to: { pathname: url, search: location.search }, disabled: !url }}>
      <ListItemContainer {...{ className, hasSubLabel }}>
        {image && (
          <div className="icon-container">
            <img src={image} alt="option avatar" />
          </div>
        )}
        <div className="label excl-lz">{label}</div>
        {subLabel && (
          <div className="sub-label truncate">
            <TimeIcon />
            {subLabel}
            {showPrice && <PriceIcon />}
            {showPrice && price}
          </div>
        )}

        <InfoIconContainer onClick={showMeetingInfo}>
          <Info />
        </InfoIconContainer>
      </ListItemContainer>
    </LinkWrapper>
  )
}

interface ListItemContainerProps {
  readonly hasSubLabel: boolean
}

interface LinkWrapperProps {
  readonly disabled: boolean
}

const TimeIcon = styled(Clock)`
  color: ${appColors.gray400};
  padding-right: 6px;
  padding-top: 4px;
  vertical-align: bottom;
`

const PriceIcon = styled(CreditCard)`
  color: ${appColors.gray400};
  padding-right: 6px;
  padding-top: 4px;
  vertical-align: bottom;
  margin-left: ${spacing(1)};
`

const InfoIconContainer = styled.button`
  color: ${appColors.gray400};
  vertical-align: middle;
  grid-row: 1 / span 4;
  grid-column: 3 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: inherit;
  border-radius: 5px;

  &:hover {
    color: #1e9bff;
  }
`

const LinkWrapper = styled(NavLink)`
  ${({ disabled }: LinkWrapperProps) => (disabled ? 'pointer-events: none;' : '')};

  margin-bottom: 16px;
  width: 100%;
  text-decoration: unset;
  color: unset;
  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: unset;
  }
`

const ListItemContainer = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 78px 1fr 64px;
  grid-template-rows: 18px auto auto 18px;
  font-size: 15px;
  background-color: ${appColors.lightBackground};
  width: 100%;
  min-height: 82px;
  box-shadow: 0px 1px 2px ${appColors.shadow1};
  border: 1px solid ${appColors.gray300};
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: unset;
  }

  .label {
    grid-column: 1 / span 2;
    grid-row-start: 2;
    grid-row-end: ${(props: ListItemContainerProps) => (props.hasSubLabel ? '3' : '4')};
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    font-size: 16px;
    font-weight: 500;
    color: ${appColors.gray900};
    padding-left: 14px;
  }

  // label with icon present
  .icon-container + .label {
    grid-column: 2 / span 1;
    padding-left: 0;
  }

  .sub-label {
    grid-row: 3 / span 1;
    grid-column: 1 / span 2;
    align-self: flex-start;
    color: ${appColors.gray600};
    font-size: 14px;
    padding-left: 14px;
  }

  // sub-label with icon present
  .icon-container + .label + .sub-label {
    grid-column: 2 / span 1;
    padding-left: 0;
  }

  .icon-container {
    grid-row: 1 / span 4;
    grid-column: 1 / span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 3px;
      width: 50px;
    }
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
