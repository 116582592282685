import React from 'react'
import styled from 'styled-components'
import { X } from 'react-feather'

import { appColors } from '../../assets/app_colors'
import { spacing } from 'util/style_utils'

export interface ModalHeaderProps {
  /**
   * The url for the meeting type image
   */
  readonly image?: string | JSX.Element | null
  /**
   * The name of the meeting type
   */
  readonly name: string
  /**
   * The function to call when clicking close
   */
  readonly onCloseClick: () => void
  /**
   * whether or not localizejs should translate the title
   */
  readonly disableTranslate?: boolean
}

interface ContainerProps {
  readonly image?: string | JSX.Element | null
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  image,
  name,
  onCloseClick,
  disableTranslate = false,
}) => {
  const headerImage = image ? (
    typeof image == 'string' ? (
      <img className="excl-lz" src={image} alt={name} />
    ) : (
      image
    )
  ) : (
    ''
  )

  return (
    <StyledHeader image={image}>
      {image && <div className="image">{headerImage}</div>}
      <div className="title">
        <span className={`${disableTranslate ? 'excl-lz' : ''}`}>{name}</span>
      </div>
      <CloseButton onClick={onCloseClick}>
        <X />
      </CloseButton>
    </StyledHeader>
  )
}

const StyledHeader = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: 48px 1fr 34px;
  color: ${appColors.headerText};
  background: ${appColors.lightBackground};
  border-bottom: 1px solid;
  border-color: ${appColors.timezoneSelectionBorder};
  padding: ${spacing(2)};

  .title {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    grid-column: ${({ image }: ContainerProps) => `${!!image ? '2 / span 1' : '1 / span 2'}`};
    grid-row: 1 / span 1;
    justify-content: start;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .image {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column: 1;
    grid-row: 1 / span 1;

    img {
      border-radius: 4px;
      max-width: 36px;
    }
  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  grid-column: 3;
  border: 0;
  background: inherit;
  @media (max-width: 767.98px) {
    padding: 0;
  }
  svg {
    color: ${appColors.lightGrayIcon};
  }
  &:hover {
    svg {
      color: #1e9bff;
    }
  }
`
