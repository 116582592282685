import { Intent } from '@blueprintjs/core'

export interface Error {
  readonly text: string
  readonly translate: boolean
  readonly intent: Intent
  readonly shown: boolean
}

export const defaultError: Error = {
  text: '',
  shown: true,
  translate: false,
  intent: Intent.NONE,
}
