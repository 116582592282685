import { DateTime } from 'luxon'

export const computeDisplayLong = (
  startWithZone: DateTime,
  endWithZone: DateTime,
  timezone: string
): string => {
  const currentWeekStart = DateTime.now().setZone(timezone).startOf('week')
  const nextWeekStart = currentWeekStart.plus({ weeks: 1 })

  // If week is current week, return string of "This week"
  if (startWithZone.hasSame(currentWeekStart, 'week')) {
    return 'THIS WEEK'
  }

  // If week is next week, return string of "Next week"
  if (startWithZone.hasSame(nextWeekStart, 'week')) {
    return 'NEXT WEEK'
  }

  // If week crosses month threshold i.e. December 27th - January 2nd, return correct string
  if (startWithZone.month !== endWithZone.month) {
    return `${startWithZone.toLocaleString({
      month: 'long',
      day: 'numeric',
    })} - ${endWithZone.toLocaleString({ month: 'long', day: 'numeric' })}`
  }

  return `${startWithZone.toLocaleString({
    month: 'long',
    day: 'numeric',
  })} - ${endWithZone.toLocaleString({ day: 'numeric' })}`
}

export const computeIsToday = (time: string, timezone: string): boolean => {
  const today = DateTime.now().setZone(timezone).startOf('day')
  return DateTime.fromISO(time).setZone(timezone).startOf('day').equals(today)
}
