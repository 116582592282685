import React from 'react'
import styled from 'styled-components'

import { appColors } from '../../../assets/app_colors'

export interface TypographyProps {
  color?: string
  variant: Variant
  // component?: React.ElementType
  /**
   * If `true`, the text will have a bottom margin.
   * @default false
   */
  paragraph?: boolean
  gutterBottom?: boolean
  display?: string
  lineThrough?: boolean
  disableTranslate?: boolean
  capitalize?: boolean
  className?: string
}

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'span'

const H1 = styled.h1<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray800)};
  font-family: 'Space Grotesk';
  font-weight: normal;
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const H2 = styled.h2<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  font-family: 'Space Grotesk';
  font-weight: normal;
  font-size: 18px;
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const H3 = styled.h3<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const H4 = styled.h4<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const H5 = styled.h5<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const H6 = styled.h6<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const Subtitle1 = styled.p<TypographyProps>`
  font-size: 14px;
  font-weight: normal;
  color: ${({ color }) => (color ? color : appColors.gray600)};
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const P1 = styled.p<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ lineThrough }) => (lineThrough ? 'text-decoration: line-through;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const P2 = styled.p<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ lineThrough }) => (lineThrough ? 'text-decoration: line-through;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const BaseSpan = styled.span<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const ButtonSpan = styled.span<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  font-weight: 600;
  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const CaptionSpan = styled.span<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  font-size: 10px;
  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const OverlineSpan = styled.span<TypographyProps>`
  color: ${({ color }) => (color ? color : appColors.gray900)};
  font-size: 10px;
  line-height: 2.66;
  letter-spacing: 0.08333em;
  text-transform: uppercase;
  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ gutterBottom }) => (gutterBottom ? 'margin-bottom: 0.35em;' : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
`

const variantMapping = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  subtitle1: Subtitle1,
  subtitle2: H6,
  body1: P1,
  body2: P2,
  inherit: P1,
  caption: CaptionSpan,
  button: ButtonSpan,
  overline: OverlineSpan,
  span: BaseSpan,
}

export const Typography: React.FC<TypographyProps> = ({
  children,
  color,
  // component,
  display,
  paragraph,
  variant,
  gutterBottom = false,
  lineThrough = false,
  disableTranslate = false,
  capitalize = false,
  className = '',
}) => {
  const Component = (paragraph ? 'p' : variantMapping[variant]) || BaseSpan
  return (
    <Component
      variant={variant}
      gutterBottom={gutterBottom}
      display={display}
      color={color}
      lineThrough={lineThrough}
      className={`${disableTranslate ? 'excl-lz' : ''} ${className}`}
      capitalize={capitalize}
    >
      {children}
    </Component>
  )
}
