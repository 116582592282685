import { SitkaModule } from 'olio-sitka'
import { GenericModal, defaultGenericModal, ModalType } from './generic_modal_core'
import { AppModules, AppState } from 'common/redux/sitka'
import { put, select } from 'redux-saga/effects'

export class GenericModalModule extends SitkaModule<GenericModal, AppModules> {
  public moduleName: string = 'genericModal'
  public defaultState: GenericModal = defaultGenericModal
  public static selectGenericModal(state: AppState): GenericModal {
    return state.genericModal
  }

  public *handleSetModalIsOpen(isOpen: boolean, modalType: ModalType) {
    yield this.setModalIsOpen(isOpen, modalType)
  }

  public *setModalIsOpen(isOpen: boolean, modalType: ModalType) {
    const state: GenericModal = yield select(GenericModalModule.selectGenericModal)
    yield put(this.setState({ ...state, isOpen, modalType }, true))
  }
}
