export type ModalType = 'reschedule' | 'paymentWaiting' | 'paymentRequired' | ''

export interface GenericModal {
  readonly isOpen: boolean
  readonly modalType: ModalType
}

export const defaultGenericModal: GenericModal = {
  isOpen: false,
  modalType: '',
}
