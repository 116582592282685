import { BaseRepository } from 'common/redux/base_map'
import { AppState } from 'common/redux/sitka'
import { FieldValues } from '../field_values/field_values_core'
import { Field } from '../fields/fields_core'
import { BookingSubmission, FieldSubmission } from './remote'

export const parseStateIntoBookingSubmission = (state: AppState): BookingSubmission => {
  const {
    fieldValues,
    fields,
    availableTimes: { selected: selectedTime },
    utm,
    payment,
  } = state

  const {
    slug: meetingTypeSlug,
    teamSlug,
    timezone: { name: meetingTimezone },
    usesLocalTimezone,
    selectedHostMember,
  } = state.meetings.items[state.meetings.selected]
  const { name: userTimezone } = state.timezone
  const { firstName, lastName, email, location } = fieldValues
  const fieldSubmissions = parseFieldValuesForSubmission(fieldValues, fields)
  const { utm_campaign, utm_medium, utm_source, utm_content, utm_term } = utm
  return {
    teamSlug,
    meetingTypeSlug,
    hostMember: selectedHostMember?.id,
    start: selectedTime,
    location,
    attendee: {
      firstName,
      lastName,
      email,
      timezone: usesLocalTimezone ? meetingTimezone : userTimezone,
      // utm values not ready to be served, but part of request params
      utmCampaign: utm_campaign || '',
      utmMedium: utm_medium || '',
      utmSource: utm_source || '',
      utmContent: utm_content || '',
      utmTerm: utm_term || '',
      payment: payment.id,
      fieldSubmissions,
    },
  }
}

// These fields are handled in the normal form submission and do not require a custom field
const standardFields = ['email', 'firstName', 'lastName', 'location']

const parseFieldValuesForSubmission = (
  fieldValues: FieldValues,
  fields: BaseRepository<Field>
): ReadonlyArray<FieldSubmission> => {
  const customFields = fields.sort.reduce<Array<string>>((acc, field) => {
    if (standardFields.includes(field)) {
      return acc
    }
    acc.push(field)
    return acc
  }, [])

  const submittedValues = customFields.reduce((acc, field) => {
    const { id, fieldId } = fields.items[field]

    if (fieldValues[fieldId]) {
      const value = fieldValues[fieldId]
      const encodedValue = JSON.stringify(value)
      return [...acc, { field: id, value: encodedValue }]
    }
    return [...acc]
  }, [] as ReadonlyArray<FieldSubmission>)
  return submittedValues
}
