import React from 'react'
import styled from 'styled-components'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import { appColors } from 'assets/app_colors'
import emptyUser from 'assets/Icons/empty-user.svg'
import chevronRight from 'assets/Icons/chevron_right.svg'
import { HostMember } from 'common/redux/meetings/meetings_core'

export interface SelectHostMemberListOptionProps {
  readonly hostMember: HostMember
  readonly select: (hostMemberId: string) => void
}

export const SelectHostMemberListOption = ({
  hostMember,
  select,
}: SelectHostMemberListOptionProps) => {
  const match = useRouteMatch()
  const location = useLocation()
  const url = match?.url
    ? match?.url?.slice(-1) === '/'
      ? hostMember.id + '/'
      : `${match?.url}/${hostMember.id}/`
    : undefined
  return (
    <LinkWrapper
      onClick={() => select(hostMember.id)}
      {...{ to: { pathname: url, search: location.search }, disabled: !url }}
    >
      <ListOptionContainer>
        <div className="left">
          <div className="member-image-container">
            {!!hostMember.image ? (
              <img alt="member" src={hostMember.image} />
            ) : (
              <img alt="empty user" src={emptyUser} />
            )}
          </div>
          <div className="label">{hostMember.name}</div>
        </div>
        <div className="icon-container">
          <img src={chevronRight} alt="chevron right" />
        </div>
      </ListOptionContainer>
    </LinkWrapper>
  )
}

interface LinkWrapperProps {
  readonly disabled: boolean
}

const LinkWrapper = styled(NavLink)`
  ${({ disabled }: LinkWrapperProps) => (disabled ? 'pointer-events: none;' : '')};

  margin-bottom: 16px;
  text-decoration: unset;
  color: unset;
  width: 100%;
  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: unset;
  }
`
const ListOptionContainer = styled.div`
  cursor: pointer;
  background-color: ${appColors.lightBackground};
  min-height: 82px;
  box-shadow: 0px 1px 2px ${appColors.shadow1};
  border: 1px solid ${appColors.gray300};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: unset;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
  }

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    color: ${appColors.gray900};
    padding-left: 14px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 24px;
    height: 24px;

    img {
      width: 6px;
      height: 12px;
    }
  }

  .member-image-container {
    display: flex;
    align-items: center;
    position: relative;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
`
