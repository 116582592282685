import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState } from 'common/redux/sitka'
import { Session, defaultSession, SessionType, LocationType } from './session_core'

import { put, select } from 'redux-saga/effects'
import { handleActivity } from '../activity/activity_module'
import { countBy } from 'lodash'

export class SessionModule extends SitkaModule<Session, AppModules> {
  public moduleName: string = 'session'
  public defaultState: Session = defaultSession
  public static selectSession(state: AppState): Session {
    return state.session
  }

  public *setPath(path: string) {
    const session: Session = yield select(SessionModule.selectSession)
    const depth: number = countBy(path)['/']
    if (!session.startingDepth || depth < session.startingDepth) {
      yield put(this.setState({ ...session, startingDepth: countBy(path)['/'], path }, true))
      return
    }
    yield put(this.setState({ ...session, path }, true))
  }

  public *handleCloseModal() {
    yield handleActivity('requestedModalClose')
  }

  public *setSpinner(visible: boolean) {
    const session: Session = yield select(SessionModule.selectSession)
    yield put(this.setState({ ...session, spinner: visible }, true))
  }

  public *set(state: Session) {
    yield put(this.setState(state, true))
  }

  public *setRedirect(url: string, replace: boolean = false) {
    const session: Session = yield select(SessionModule.selectSession)
    yield put(this.setState({ ...session, redirect: url, redirectReplace: replace }, true))
  }

  public *handleClearRedirect() {
    yield this.setRedirect('')
    yield this.setSpinner(false)
  }

  public *handleSetSessionMeta(sessionType: SessionType, location: LocationType) {
    yield this.setSessionMeta(sessionType, location)
  }

  public *setSessionMeta(sessionType: SessionType, location: LocationType) {
    const session: Session = yield select(SessionModule.selectSession)
    yield put(this.setState({ ...session, sessionType, location }, true))
  }

  public *handleClearPolling() {
    const session: Session = yield select(SessionModule.selectSession)
    yield put(this.setState({ ...session, polling: undefined }, true))
  }
}
