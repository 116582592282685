import React from 'react'
import styled from 'styled-components'
import { spacing } from '../../../util/style_utils'

export interface ButtonProps {
  /*
   * Background color
   */
  bg?: string
  /*
   * Border color
   */
  border?: string
  /*
   * Text color
   */
  color?: string
  /*
   * If button should be full width
   */
  fullWidth?: boolean
  /*
   * href if link
   */
  href?: string
  /*
   * target if link
   */
  target?: string
  /*
   * children / content
   */
  children?: React.ReactNode
  /*
   * type of form button (ex: "submit")
   */
  type?: 'button' | 'submit' | 'reset'
  /*
   * callback event when button is clicked
   */
  onClick?: any
  /*
   * spacing utility for setting margin/padding
   *
   * usage:
   *   padding={[12]}          // "12px"
   *   padding={[12, 16]}       // "12px 16px"
   *   padding={[12, 16, 12]}    // "12px 16px 12px"
   *   padding={[8, 0, 8, 2]} // "8px 0px 8px 2px"
   */
  padding?: number[]
  /*
   * font weight value
   */
  fontWeight?: string
}

export const Button: React.FC<ButtonProps> = props => {
  return props.href ? <LinkButton {...props} /> : <FormButton {...props} />
}

const FormButton = styled.button<ButtonProps>`
  ${({ bg }) => (bg ? `background: ${bg};` : '')}
  ${({ border }) => (border ? `border: 1px solid ${border};` : 'border: none;')}
  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ fullWidth }) =>
    fullWidth ? `width: 100%;` : `padding-left: ${spacing(1.5)}; padding-right: ${spacing(1.5)};`}

  padding-top: ${spacing(1.5)};
  padding-bottom: ${spacing(1.5)};
  ${({ padding }) => (padding ? `padding: ${padding.map(num => `${num}px`).join(' ')};` : '')}
  border-radius: ${spacing(0.5)};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const LinkButton = styled.a<ButtonProps>`
  ${({ bg }) => (bg ? `background: ${bg};` : '')}
  ${({ border }) => (border ? `border: 1px solid ${border};` : '')}
  ${({ border }) => (border ? `border: 1px solid ${border};` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ fullWidth }) =>
    fullWidth ? `width: 100%;` : `padding-left: ${spacing(1.5)}; padding-right: ${spacing(1.5)};`}

  text-align: center;
  padding-top: ${spacing(1.5)};
  padding-bottom: ${spacing(1.5)};

  border-radius: ${spacing(0.5)};
  font-weight: 600;
  text-decoration: none;
  display: inline-block;

  &:hover {
    text-decoration: none;
    ${({ color }) => (color ? `color: ${color};` : '')}
  }
`
