import React from 'react'
import styled from 'styled-components'
import { spacing } from '../../util/style_utils'

export interface BoxProps {
  /*
   * Margin
   */
  m?: number
  /*
   * Margin Top
   */
  mt?: number
  /*
   * Margin Right
   */
  mr?: number
  /*
   * Margin Bottom
   */
  mb?: number
  /*
   * Margin Left
   */
  ml?: number
  /*
   * Margin Left/Right
   */
  mx?: number
  /*
   * Margin Top/Bottom
   */
  my?: number
  /*
   * Padding
   */
  p?: number
  /*
   * Padding Top
   */
  pt?: number
  /*
   * Padding Right
   */
  pr?: number
  /*
   * Padding Bottom
   */
  pb?: number
  /*
   * Padding Left
   */
  pl?: number
  /*
   * Padding Left/Right
   */
  px?: number
  /*
   * Padding Top/Bottom
   */
  py?: number
  /*
   * Background color
   */
  bg?: string
  /*
   * Border radius
   */
  br?: number
  /*
   * Border color
   */
  border?: string
  /*
   * justifyContent (flex-start/center/flex-end/etc)
   */
  justifyContent?: string
  /*
   * alignItems (flex-start/center/flex-end/etc)
   */
  alignItems?: string
  /*
   * display (block/flex/none/etc)
   */
  display?: string
  /*
   * textAlign (left/center/right)
   */
  textAlign?: string
  /*
   * React child nodes
   */
  children?: React.ReactNode
}

export const Box = styled.div<BoxProps>`
  ${({ m }) => (m ? `margin: ${spacing(m)};` : '')}
  ${({ mt }) => (mt ? `margin-top: ${spacing(mt)};` : '')}
  ${({ mr }) => (mr ? `margin-right: ${spacing(mr)};` : '')}
  ${({ mb }) => (mb ? `margin-bottom: ${spacing(mb)};` : '')}
  ${({ ml }) => (ml ? `margin-left: ${spacing(ml)};` : '')}
  ${({ mx }) => (mx ? `margin-left: ${spacing(mx)}; margin-right: ${spacing(mx)};` : '')}
  ${({ my }) => (my ? `margin-top:  ${spacing(my)}; margin-bottom: ${spacing(my)};` : '')}

  ${({ p }) => (p ? `padding: ${spacing(p)};` : '')}
  ${({ pt }) => (pt ? `padding-top: ${spacing(pt)};` : '')}
  ${({ pr }) => (pr ? `padding-right: ${spacing(pr)};` : '')}
  ${({ pb }) => (pb ? `padding-bottom: ${spacing(pb)};` : '')}
  ${({ pl }) => (pl ? `padding-left: ${spacing(pl)};` : '')}
  ${({ px }) => (px ? `padding-left: ${spacing(px)}; padding-right: ${spacing(px)};` : '')}
  ${({ py }) => (py ? `padding-top:  ${spacing(py)}; padding-bottom: ${spacing(py)};` : '')}

  ${({ bg }) => (bg ? `background: ${bg};` : '')}
  ${({ br }) => (br ? `border-radius: ${spacing(br)};` : '')}
  ${({ border }) => (border ? `border: 1px solid ${border};` : '')}
  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
`
