import { useState, useEffect } from 'react'

export const useContentMargin = () => {
  const [contentMargin, setContentMargin] = useState(0)

  useEffect(() => {
    const headerEl = document.querySelector('.content') as HTMLElement
    const margin = headerEl ? headerEl.offsetTop : 0
    setContentMargin(margin)
  }, [])

  return contentMargin
}
