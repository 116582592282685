import { FieldValue } from 'common/redux/field_values/field_values_core'
import { BaseMap } from 'common/redux/base_map'

export const loadState = (meetingTypeId: string): void | BaseMap<FieldValue> => {
  try {
    const serializedState = localStorage.getItem(`formFieldState-${meetingTypeId}`)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (error) {
    return undefined
  }
}

export const saveState = (state: object, meetingTypeId: string): void => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(`formFieldState-${meetingTypeId}`, serializedState)
  } catch (err) {
    return undefined
  }
}
