import React from 'react'
import { Field } from 'react-final-form'
import { FieldProps } from './custom_field'
import { Options } from './field_options'

interface ChoiceFieldProps extends FieldProps {}
export const ChoiceField = ({ fieldId, choices, value }: ChoiceFieldProps): JSX.Element => {
  // if options is greater than 5 show select menu, otherwise show radio buttons
  const useSelect = choices.length > 5

  return useSelect ? (
    <div className="bp3-select">
      <Field name={fieldId} component="select">
        <option value="" disabled selected hidden></option>
        {choices.map(choice => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </Field>
    </div>
  ) : (
    <Options id={fieldId} value={value} choices={choices} type="radio" />
  )
}
