/**
 * This button is an textx-only button that resizes according to its container.
 */
import React from 'react'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'

export type ButtonType = 'button' | 'submit'

interface SimpleActionButtonProps {
  readonly label: string | JSX.Element
  readonly type: ButtonType
  readonly disabled: boolean
  readonly className?: string
  readonly onClick?: () => void
  readonly themeColor?: string
}

export class TextButton extends React.Component<SimpleActionButtonProps, {}> {
  render(): JSX.Element {
    const { label, type, disabled, className, onClick, themeColor } = this.props

    return (
      <TextButtonStyled
        className={className}
        type={type}
        disabled={disabled}
        onClick={onClick}
        themeColor={themeColor}
      >
        {label}
      </TextButtonStyled>
    )
  }
}

interface StyledButtonProps {
  readonly themeColor: string | undefined
}

const TextButtonStyled = styled.button`
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3;
  text-align: center;
  color: ${appColors.lightText};
  border-radius: 4px;
  background-blend-mode: overlay, normal;
  background: ${(props: StyledButtonProps) => props.themeColor} !important;
`
