import { SitkaModule } from 'olio-sitka'
import { put, select } from 'redux-saga/effects'
import { AvailableTimes, defaultAvailableTimes } from './available_times_core'
import { AppModules, AppState } from 'common/redux/sitka'

import { handleActivity } from '../activity/activity_module'
import { defaultBucketedTimes } from '../bucketed_times/bucketed_times_core'
import { defaultDynamicLoading } from '../dynamic_loading/dynamic_loading_core'

export class AvailableTimesModule extends SitkaModule<AvailableTimes, AppModules> {
  public moduleName: string = 'availableTimes'
  public defaultState: AvailableTimes = defaultAvailableTimes
  public static selectAvailableTimes(state: AppState): AvailableTimes {
    return state.availableTimes
  }

  public *handleRenderedTimes() {
    yield handleActivity('loadedTimes')
  }

  public *clearTimes() {
    yield put(this.modules.availableTimes.setState(defaultAvailableTimes, true))
    yield this.modules.bucketedTimes.set(defaultBucketedTimes)
    yield this.modules.dynamicLoading.set(defaultDynamicLoading)
  }

  public *getSelectedTime(time: string = '') {
    const times: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)
    return time ? times.items[time] : times.items[times.selected]
  }

  // this is only used for rescheduling flow
  // opens the modal and selects the time clicked
  public *handleSelectTime(id: string) {
    yield this.updateSelectedTime(id)
    yield this.modules.genericModal.handleSetModalIsOpen(true, 'reschedule')
  }

  public *updateSelectedTime(id: string) {
    // set selected time
    const oldAvailableTimes: AvailableTimes = yield select(
      AvailableTimesModule.selectAvailableTimes
    )

    yield put(this.setState({ ...oldAvailableTimes, selected: id }, true))

    // activity layer
    yield handleActivity('selectedTime')
  }

  public *set(state: AvailableTimes) {
    yield put(this.setState(state, true))
  }
}
