import styled from 'styled-components'

interface CircleProps {
  bg?: string
  size?: number
}

export const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ bg }) => (bg ? bg : '#ccc')};
  width: ${({ size }) => (size ? size + 'px' : '24px')};
  height: ${({ size }) => (size ? size + 'px' : '24px')};
  min-width: ${({ size }) => (size ? size + 'px' : '24px')};
  min-height: ${({ size }) => (size ? size + 'px' : '24px')};
`
