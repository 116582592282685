import { BaseRepository, getEmptyRepo } from 'common/redux/base_map'

export interface Field {
  readonly id: string
  readonly fieldId: string
  readonly kind: string
  readonly label: string
  readonly helpText: string
  readonly choices: ReadonlyArray<string>
  readonly validators: Validator
  readonly slug?: string
  readonly visible?: boolean
  readonly localizeExclude?: boolean
}

interface Validator {
  readonly required: boolean
  readonly regex?: RegExp
}

export interface Fields extends BaseRepository<Field> {}

export const defaultFields: Fields = getEmptyRepo()
