import styled from 'styled-components'
import React from 'react'
import { desktopStylesWrapper } from 'assets/shared_css_styles'

interface AnimationProps {
  readonly appearDuration: number
  readonly startPosition: number
  readonly isModal: boolean
}

export const AnimationWrapper: React.FC<AnimationProps> = ({
  appearDuration,
  startPosition,
  children,
  isModal,
}) => <Wrapper {...{ appearDuration, startPosition, isModal }}>{children}</Wrapper>

const opacityCubicBezier = '.62,.28,.23,.99'
const transitionBezier = '.51,.92,.24,1.15'

const Wrapper = styled.div`
  right: 0px;
  width: 100%;
  margin-top: 68px;
  height: calc(100% - 68px);
  align-items: center;
  display: flex;
  flex-direction: column;

  &.forward-enter {
    transform: ${({ startPosition }: AnimationProps) => `translate(${startPosition}%, 0px)`};
    opacity: 0;
  }

  &.forward-enter-active {
    transform: translate(0px, 0px);
    opacity: 1;

    transition: ${({ appearDuration }: AnimationProps) =>
      `transform ${appearDuration}ms cubic-bezier(${transitionBezier}),
            opacity ${appearDuration / 2}ms cubic-bezier(${opacityCubicBezier})`};
  }

  &.forward-exit {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  &.forward-exit-active {
    opacity: 0;
    transform: ${({ startPosition }: AnimationProps) => `translate(-${startPosition}%, 0px)`};

    transition: ${({ appearDuration }: AnimationProps) =>
      `transform ${appearDuration}ms ease-in,
            opacity ${appearDuration / 2}ms ease-in`};
  }

  &.backward-enter {
    opacity: 0;
    transform: ${({ startPosition }: AnimationProps) => `translate(-${startPosition}%, 0px)`};
  }

  &.backward-enter-active {
    transform: translate(0px, 0px);
    opacity: 1;
    transition: ${({ appearDuration }: AnimationProps) =>
      `transform ${appearDuration}ms cubic-bezier(${transitionBezier}),
            opacity ${appearDuration}ms cubic-bezier(${opacityCubicBezier})`};
  }

  &.backward-exit {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  &.backward-exit-active {
    opacity: 0;
    transform: ${({ startPosition }: AnimationProps) => `translate(${startPosition}%, 0)`};
    transition: ${({ appearDuration }: AnimationProps) =>
      `transform ${appearDuration}ms cubic-bezier(${transitionBezier}),
            opacity ${appearDuration}ms cubic-bezier(${opacityCubicBezier})`};
  }

  ${({ isModal }: AnimationProps) => desktopStylesWrapper(isModal).wrapper`
    margin-top: 71px;
    height: calc(100% - 71px);
  `}
`
