import { HandledResp } from '../../api/client'
import { getDefaultTimezone } from '../remote/remote_timezones_transform'
import { TimezoneRemote } from '../remote/remote'
import { BookedAppointment } from '../booked_appointment/booked_appointment_core'

export const transformRemoteAttendee = (
  response: HandledResp,
  timezones: ReadonlyArray<TimezoneRemote>
): BookedAppointment | null => {
  const { getAttendeeById: attendee } = response.items.data

  // TODO look at attendee.errors
  if (!attendee) {
    return null
  }

  let timezoneKey: string | undefined
  if (attendee.meeting) {
    timezoneKey = attendee.timezone
  }

  const timezoneToUse = getDefaultTimezone(timezoneKey, timezones)
  const timezoneToUseLocked = { ...timezoneToUse, isLocked: true }
  const { externalId, status, meeting: meetingNode } = attendee

  const meeting = meetingNode
    ? {
        conferenceUrl: meetingNode.conferenceUrl,
        confirmed: meetingNode.confirmed,
        end: meetingNode.end,
        isCancellable: meetingNode.isCancellable,
        isReschedulable: meetingNode.isReschedulable,
        location: meetingNode.location,
        locationType: meetingNode.locationType,
        start: meetingNode.start,
        hostMember: meetingNode.hostMember,
      }
    : undefined

  return {
    externalId,
    status,
    meeting,
    timezone: timezoneToUseLocked,
  }
}
