import { Field } from 'react-final-form'
import React from 'react'
import { FieldValue } from 'common/redux/field_values/field_values_core'

interface OptionsProps {
  readonly id: string
  readonly choices: ReadonlyArray<string>
  readonly type: 'checkbox' | 'radio'
  readonly value: FieldValue
}

export const Options = ({ id, choices, type }: OptionsProps) => {
  return (
    <div className="options-wrapper">
      {choices.map(choice => {
        return (
          <label key={choice} className={`bp3-control bp3-${type} .modifier`}>
            <Field name={id} component="input" type={type} value={choice} />
            <span className="bp3-control-indicator" />
            {choice}
          </label>
        )
      })}
    </div>
  )
}
