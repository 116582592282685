import React from 'react'
import { MapPin, Monitor, Phone, Calendar, Clock, CreditCard } from 'react-feather'
import { Maybe } from 'graphql'
import { HostMember, LocationType } from 'common/redux/meetings/meetings_core'
import { appStrings } from 'assets/app_strings'
import { ReactFeatherIcon } from 'util/icon'
import { timeConversion } from 'util/style_utils'
import { HostMemberIcon } from 'components/atoms/host_member_icon'
import { MeetingInfoItem } from './meeting_info_item'

const {
  PLACE,
  PLACE_USER,
  PHONE,
  PHONE_USER,
  WEB_CONFERENCE,
  GOOGLE_MEET,
} = appStrings.locationTypeDescription

export interface MeetingInfoItemsProps {
  readonly duration?: number
  readonly date?: string
  readonly dateMobile?: string
  readonly startTime?: string
  readonly endTime?: string
  readonly locationType?: Maybe<LocationType>
  readonly location?: Maybe<string>
  readonly page?: string
  readonly priceFormatted?: string
  readonly priceCurrency?: Maybe<string>
  readonly isModal?: boolean
  readonly hostMember?: HostMember
}

interface LocationTypeToMap {
  readonly icon: ReactFeatherIcon
  readonly label: string
}

export const locationTypeToIcon: { [K in LocationType]: LocationTypeToMap } = {
  // Host chooses a place to meet
  PLACE: {
    icon: MapPin,
    label: PLACE,
  },
  // Attendee chooses a place to meet
  PLACE_USER: {
    icon: MapPin,
    label: PLACE_USER,
  },
  // Host provides a number to call
  PHONE: {
    icon: Phone,
    label: PHONE,
  },
  // Attendee provides a number to call
  PHONE_USER: {
    icon: Phone,
    label: PHONE_USER,
  },
  // Web Conference
  WEB_CONFERENCE: {
    icon: Monitor,
    label: WEB_CONFERENCE,
  },
  // Google Meet
  GOOGLE_MEET: {
    icon: Monitor,
    label: GOOGLE_MEET,
  },
}

const getIconLabel = (location?: Maybe<string>, locationType?: Maybe<LocationType>) => {
  if (!locationType) {
    return { icon: undefined, label: undefined }
  }
  const { icon, label } = locationTypeToIcon[locationType]

  return {
    icon,
    label: location ? location : label,
  }
}
export const MeetingInfoItems: React.FC<MeetingInfoItemsProps> = ({
  duration,
  date,
  dateMobile,
  startTime,
  endTime,
  locationType,
  location,
  page,
  priceFormatted,
  priceCurrency,
  hostMember,
}) => {
  const durationFormatted = timeConversion(duration)
  const { icon, label } = getIconLabel(location, locationType)
  const confirmPage = page === 'confirm'

  // individual render functions
  const renderDuration = () => <MeetingInfoItem label={durationFormatted} icon={Clock} />
  const renderTimeRange = () => (
    <MeetingInfoItem
      label={`${startTime}${endTime ? ` - ${endTime}` : ''}`}
      icon={Clock}
      className="desktop-only excl-lz"
    />
  )
  const renderDate = () => {
    return (
      <MeetingInfoItem label={date} icon={Calendar} className="desktop-only excl-lz" capitalize />
    )
  }
  const renderTimeRangeAndDate = () => (
    <MeetingInfoItem
      label={`${startTime}${endTime ? ` - ${endTime}` : ''}, ${dateMobile}`}
      icon={Calendar}
      className="mobile-only excl-lz"
    />
  )

  const renderPrice = () => (
    <MeetingInfoItem
      label={`${priceFormatted} ${priceCurrency}`}
      icon={CreditCard}
      className="excl-lz"
    />
  )

  const renderHostMember = () => {
    const HostMemberIconWrapper = ({
      className,
      size,
    }: {
      className?: string
      size?: string | number
    }) => <HostMemberIcon hostMember={hostMember} className={className} size={size} />

    return (
      <MeetingInfoItem label={hostMember?.name} icon={HostMemberIconWrapper} className="excl-lz" />
    )
  }

  return (
    <>
      {!startTime && duration && renderDuration()}
      {startTime && renderTimeRange()}
      {renderDate()}
      {startTime && confirmPage && renderTimeRangeAndDate()}
      {label && <MeetingInfoItem {...{ icon, label }} />}
      {hostMember && renderHostMember()}
      {priceFormatted && renderPrice()}
    </>
  )
}
