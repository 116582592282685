import React from 'react'
import { connect } from 'react-redux'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { HostMember } from 'common/redux/meetings/meetings_core'
import { SelectHostMemberList } from './molecules/select_host_member_list/select_host_member_list'
import styled from 'styled-components'

export interface SelectHostMemberPageProps {
  readonly hostMembers: ReadonlyArray<HostMember>
  readonly select: (hostMemberId: string) => void
  readonly isModal: boolean
}

export const PureSelectHostMemberPage: React.FC<SelectHostMemberPageProps> = ({
  hostMembers,
  select,
  isModal,
}) => {
  return (
    <ListContainer>
      <SelectHostMemberList isModal={isModal} hostMembers={hostMembers} select={select} />
    </ListContainer>
  )
}

export const SelectHostMemberPage = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    hostMembers: state.meetings.items[state.meetings.selected].hostMembers,
    select: modules.meetings.handleSetSelectedHostMember,
    isModal: state.session.mode === 'modal',
  }
})(PureSelectHostMemberPage)

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
