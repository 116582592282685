import React, { useEffect } from 'react'
import { Overlay } from '@blueprintjs/core'
import { TimezoneModal } from '../time_zone_modal/time_zone_modal'
import { connect } from 'react-redux'
import { MeetingModal } from '../meeting_modal/meeting_modal'
import { AppModules, AppState, sitka } from 'common/redux/sitka'
import { TimezoneModal as TimezoneModalState } from 'common/redux/timezone_modal/timezone_modal_core'
import { TimezoneModule } from 'common/redux/timezone/timezone_module'
import { TimezoneModalModule } from 'common/redux/timezone_modal/timezone_modal_module'
import { InfoModal } from 'common/redux/info_modal/info_modal_core'
import { InfoModalModule } from 'common/redux/info_modal/info_modal_module'
import { Meeting, Meetings } from 'common/redux/meetings/meetings_core'
import { MeetingsModule } from 'common/redux/meetings/meetings_module'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { GenericModal } from 'common/redux/generic_modal/generic_modal_core'
import { RescheduleModal } from '../reschedule_modal/reschedule_modal'
import { GenericModalModule } from 'common/redux/generic_modal/generic_modal_module'
import { ConfirmContentIface, getConfirmContent } from 'util/components/complete'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { SubmitUpdateBookingModule } from 'common/redux/submit_update_booking/submit_update_booking_module'
import { Organization } from 'common/redux/organization/organization_core'
import { FeatureFlags } from 'common/redux/organization/organization_core'
import { PaymentWaitingModal } from '../payment_waiting_modal/payment_waiting_modal'
import { EgressModule } from 'common/redux/egress/egress_module'
import { SessionModule } from 'common/redux/session/session_module'

interface TimezoneModalProps {
  readonly infoModalModule: InfoModalModule
  readonly genericModalModule: GenericModalModule
  readonly meetingsModule: MeetingsModule
  readonly submitUpdateBookingModule: SubmitUpdateBookingModule
  readonly timezoneModalModule: TimezoneModalModule
  readonly timezoneModule: TimezoneModule
  readonly egressModule: EgressModule
  readonly sessionModule: SessionModule

  readonly availableTimes: AvailableTimes
  readonly bookedAppointment: BookedAppointment
  readonly organization: Organization
  readonly infoModal: InfoModal
  readonly genericModal: GenericModal
  readonly meetings: Meetings
  readonly timezone: Timezone
  readonly timezoneModalState: TimezoneModalState
  readonly featureFlags: ReadonlyArray<FeatureFlags>
}

const Component: React.FC<TimezoneModalProps> = ({
  infoModalModule: { handleSetModalIsOpen: setInfoIsOpen },
  genericModalModule: { handleSetModalIsOpen },
  submitUpdateBookingModule: { handleUpdateBooking, handleUpdateBookingReason },
  timezoneModalModule: { handleSetTimezoneModal },
  timezoneModule: { handleRenderTimezoneModal, handleChangeTimezone },
  egressModule: { handleShowPaymentWindow },
  sessionModule: { handleClearPolling },

  availableTimes,
  bookedAppointment,
  featureFlags,
  infoModal,
  organization,
  genericModal,
  meetings,
  timezone,
  timezoneModalState,
}) => {
  useEffect(() => {
    if (timezoneModalState.isOpen) {
      handleRenderTimezoneModal()
    }
  }, [timezoneModalState, handleRenderTimezoneModal])

  const closeModals = () => {
    handleSetTimezoneModal(false)
    setInfoIsOpen(false)
    handleSetModalIsOpen(false, '')
  }

  const canOutsideClickClose = !(
    genericModal.isOpen &&
    (genericModal.modalType === 'paymentWaiting' || genericModal.modalType === 'paymentRequired')
  )

  const overlayConfig = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose,
    hasBackdrop: true,
    isOpen: timezoneModalState.isOpen || infoModal.isOpen || genericModal.isOpen,
    onClose: closeModals,
  }

  const MeetingModalWithProps = () => {
    if (infoModal.meetingId === 'booked-appointment') {
      const meeting: Meeting = meetings.items[meetings.selected]
      if (!meeting) {
        return <div />
      }
      const {
        slug,
        name,
        description,
        image,
        locationType,
        duration,
        price,
        priceCurrency,
        priceFormatted,
      } = meeting
      const { modalType } = infoModal
      return (
        <MeetingModal
          {...{
            slug,
            name,
            description,
            duration,
            image,
            locationType,
            setInfoModal: setInfoIsOpen,
            modalType,
            priceFormatted: price > 0 ? priceFormatted : undefined,
            priceCurrency,
          }}
        />
      )
    }

    const meeting = meetings.items[infoModal.meetingId]
    if (!meeting) {
      return <div />
    }
    const {
      slug,
      name,
      description,
      image,
      locationType,
      duration,
      price,
      priceCurrency,
      priceFormatted,
    } = meeting
    const { modalType } = infoModal
    return (
      <MeetingModal
        {...{
          slug,
          name,
          description,
          duration,
          image,
          locationType,
          setInfoModal: setInfoIsOpen,
          modalType,
          priceFormatted: price > 0 ? priceFormatted : undefined,
          priceCurrency,
        }}
      />
    )
  }

  const RescheduleModalWithProps = () => {
    const meeting: Meeting = meetings.items[meetings.selected]
    if (!meeting) {
      return <div />
    }
    const { id, name, locationType, location } = meeting

    const meta: ConfirmContentIface | null = getConfirmContent(
      bookedAppointment,
      organization,
      availableTimes
    )

    if (!meta || !timezone) {
      return <div />
    }

    const { availableTimeStart, endTime, dateLong } = meta
    const setModal = (isOpen: boolean) => handleSetModalIsOpen(isOpen, 'reschedule')

    return (
      <RescheduleModal
        {...{
          id,
          name,
          locationType: locationType ? locationType : 'PLACE',
          location: location ? location : '',
          startTime: availableTimeStart,
          endTime: endTime,
          date: dateLong,
          timeZone: timezone.display.replace(/\sTime$/, ''),
          reschedule: handleUpdateBooking,
          setUpdateCancelReason: handleUpdateBookingReason,
          setModal,
          hostMember:
            !!bookedAppointment.meeting?.hostMember &&
            meeting.hostAssignmentStrategy === 'ATTENDEE_CHOOSES'
              ? bookedAppointment.meeting?.hostMember
              : undefined,
        }}
      />
    )
  }

  const PaymentWaitingModalWithProps = () => {
    const setModal = (isOpen: boolean) => handleSetModalIsOpen(isOpen, 'paymentWaiting')
    return (
      <PaymentWaitingModal
        cancel={handleClearPolling}
        retry={handleShowPaymentWindow}
        setModal={setModal}
      />
    )
  }

  const showReschedule = genericModal.isOpen && genericModal.modalType === 'reschedule'
  const showPaymentWaiting = genericModal.isOpen && genericModal.modalType === 'paymentWaiting'

  return (
    <Overlay {...overlayConfig}>
      {timezoneModalState.isOpen && (
        <TimezoneModal
          {...{ handleSetTimezoneModal, timezoneModalState }}
          setTimezone={handleChangeTimezone}
        />
      )}
      {infoModal.isOpen && <MeetingModalWithProps />}
      {showReschedule && <RescheduleModalWithProps />}
      {showPaymentWaiting && <PaymentWaitingModalWithProps />}
    </Overlay>
  )
}

export const ModalContainer = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    infoModalModule: modules.infoModal,
    genericModalModule: modules.genericModal,
    meetingsModule: modules.meetings,
    submitUpdateBookingModule: modules.submitUpdateBooking,
    timezoneModalModule: modules.timezoneModal,
    timezoneModule: modules.timezone,
    egressModule: modules.egress,
    sessionModule: modules.session,

    availableTimes: state.availableTimes,
    bookedAppointment: state.bookedAppointment,
    featureFlags: state.organization.featureFlags,
    infoModal: state.infoModal,
    organization: state.organization,
    genericModal: state.genericModal,
    meetings: state.meetings,
    timezone: state.timezone,
    timezoneModalState: state.timezoneModal,
  }
})(Component)
