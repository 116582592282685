import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState } from 'common/redux/sitka'
import { BucketedTimes, defaultBucketedTimes } from './bucketed_times_core'
import { put } from 'redux-saga/effects'

export class BucketedTimesModule extends SitkaModule<BucketedTimes, AppModules> {
  public moduleName: string = 'bucketedTimes'
  public defaultState = defaultBucketedTimes
  public static selectBucketedTimes(state: AppState): BucketedTimes {
    return state.bucketedTimes
  }

  public *set(state: BucketedTimes) {
    yield put(this.setState(state, true))
  }
}
