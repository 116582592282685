import { SitkaModule } from 'olio-sitka'
import { put } from 'redux-saga/effects'
import { AppModules, AppState } from 'common/redux/sitka'
import { BookedAppointment, defaultBookedAppointment } from './booked_appointment_core'
import { handleActivity } from '../activity/activity_module'

export class BookedAppointmentModule extends SitkaModule<BookedAppointment, AppModules> {
  public moduleName: string = 'bookedAppointment'
  public defaultState: BookedAppointment = defaultBookedAppointment
  public static selectBookedAppointment(state: AppState): BookedAppointment {
    return state.bookedAppointment
  }

  public *set(state: BookedAppointment) {
    yield put(this.setState(state, true))
  }

  public *handleRenderedBookedAppointment(): {} {
    yield handleActivity('loadedCompletedPage')
  }
}
