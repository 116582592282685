import { decodeQueryParams } from 'common/util/query_string_utils'

interface WindowOptions {
  top?: number
  left?: number
  width?: number
  height?: number
}

export enum PaymentErrorType {
  Cancelled = 'cancelled',
  NetworkFailure = 'network_failure',
}

export const openWindow = (url: string, name: string, options: WindowOptions) => {
  const optionsPieces: string[] = []

  // If there is a height/width we're going to try and center the window
  if (options.width && !options.left) {
    options.left = window.outerWidth / 2 + window.screenX - options.width / 2
  }

  if (options.height && !options.top) {
    options.top = window.outerHeight / 2 + window.screenY - options.height / 2
  }

  // Loop through the options object and push the key value
  // pairs to the array.
  Object.entries(options).forEach(([key, val]) => optionsPieces.push(`${key}=${val}`))

  // Open the url with the applied options.
  const win = window.open(url, name, optionsPieces.join(','))
  // Return a promise that resolves when the window is closed.
  return new Promise(resolve => {
    // Because there is no event for a window closing, poll to check the window status
    const intervalId = setInterval(() => {
      // Check if the window is closed...
      if (!win || win.closed || !win.window) {
        // Resolve the promise if closed & clear the interval.
        resolve({})
        clearInterval(intervalId)
      }
    }, 100)
  })
}

export const paymentProcess = (paymentRedirectUrl: string) => {
  return new Promise<{ error?: PaymentErrorType }>((resolve, reject) => {
    const win = openWindow(paymentRedirectUrl, 'Payment Window', {
      width: 1000,
      height: 750,
    })

    const messageHandler = (ev: MessageEvent) => {
      // Ignore message that are not from us.
      if (ev.origin !== 'https://oauth2.appointlet.com') {
        return
      }

      const queryString = decodeQueryParams<{ error: PaymentErrorType; status?: string }>(ev.data)

      if (queryString.error || queryString.status === PaymentErrorType.Cancelled) {
        reject(queryString.error || queryString.status)
        return
      }

      resolve({})

      // cleanup
      removeMessageHandler()
    }

    const removeMessageHandler = () => {
      window.removeEventListener('message', messageHandler)
    }

    // If window closes, cleanup the listener and reject the promise.
    win.then(() => {
      removeMessageHandler()
      reject(PaymentErrorType.Cancelled)
    })

    // Start listening for messages.
    window.addEventListener('message', messageHandler, false)
  })
}
