import React from 'react'
import { appColors } from '../../../assets/app_colors'
import styled from 'styled-components'
import { Search } from 'react-feather'

interface TimezoneInputControllerProps {
  readonly setTimezoneSearchValue: (searchValue: string) => void
}

export const TimezoneInputController: React.FC<TimezoneInputControllerProps> = ({
  setTimezoneSearchValue,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimezoneSearchValue(e.target.value)
  }

  return (
    <TimezoneInputContainer>
      <div className="icon-container">
        <Search size={16} color={appColors.gray500} />
      </div>
      <TimezoneInputStyled onChange={handleChange} autoFocus={false} placeholder="Search" />
    </TimezoneInputContainer>
  )
}

const TimezoneInputContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 38px auto;
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TimezoneInputStyled = styled.input`
  width: 100%;
  border: none;
  font-size: 15px;
  background: none;

  ::placeholder {
    color: ${appColors.gray500};
  }
`
