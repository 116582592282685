import { appStrings } from 'assets/app_strings'
import { Organization } from 'common/redux/organization/organization_core'
import { Meeting } from 'common/redux/meetings/meetings_core'
import { LocationType } from 'common/redux/session/session_core'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface RedirectWrapperProps {
  readonly redirect: string
  readonly replace: boolean
}
// this redirect wrapper captures the change
// in the redirect but only redirects when redirect comes in blank
export const RedirectWrapper: React.FC<RedirectWrapperProps> = ({ redirect, replace }) => {
  const location = useLocation()
  const history = useHistory()
  const [last, setLast] = useState<RedirectWrapperProps>({ redirect: '', replace: false })

  if (redirect === '' && last.redirect !== '') {
    setLast({ redirect: '', replace: false })
    if (last.replace) {
      history.replace({ pathname: last.redirect, search: location.search })
    } else {
      history.push({ pathname: last.redirect, search: location.search })
    }
    return null
  }
  if (redirect !== last.redirect) {
    setLast({ redirect, replace })
  }
  return null
}

interface MetaTags {
  readonly metaTitle: string
  readonly metaImage?: string | null
  readonly metaDescription?: string | null
}

const calculateTitle = (
  location: LocationType,
  organization: Organization,
  meeting?: Meeting
): string => {
  const { appointlet } = appStrings
  const organizationName = organization.name
  const meetingName = meeting?.name

  if (location === 'meetings' || location === 'noMeetings') {
    return organizationName || appointlet
  }
  return organizationName && meetingName ? `${meetingName} | ${organizationName}` : appointlet
}

export const calculateMetaTags = (
  location: LocationType,
  organization: Organization,
  meeting?: Meeting
): MetaTags => {
  const metaImage = location === 'meetings' ? organization.image : meeting?.image
  const metaDescription = location === 'meetings' ? organization.description : meeting?.description
  return {
    metaTitle: calculateTitle(location, organization, meeting),
    metaImage,
    metaDescription,
  }
}
