import { Meetings } from 'common/redux/meetings/meetings_core'
import { Timezone } from 'common/redux/timezone/timezone_core'

export const boldTextHelper = (text: string, search: string) => {
  const matchStart = text.toLowerCase().indexOf(search.toLowerCase())
  const matchEnd = matchStart + search.length - 1
  const beforeMatch = text.slice(0, matchStart)
  const matchText = text.slice(matchStart, matchEnd + 1)
  const afterMatch = text.slice(matchEnd + 1)
  return { before: beforeMatch, match: matchText, after: afterMatch }
}

export const selectedMeetingTimezone = (meetings: Meetings): Timezone => {
  return meetings.items[meetings.selected].timezone
}
