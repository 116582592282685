import React from 'react'
import styled from 'styled-components'
import { DayList } from './day_list'
import { appColors } from '../../../assets/app_colors'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { BucketedTimes } from 'common/redux/bucketed_times/bucketed_times_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { CONSTS } from 'assets/app_consts'
import { desktopStylesWrapper, ContainerPropsModalMode } from 'assets/shared_css_styles'

interface WeekListProps {
  readonly availableTimes: AvailableTimes
  readonly bucketedTimes: BucketedTimes
  readonly timeZone: Timezone
  readonly reschedule: boolean
  readonly select: (id: string) => void
  readonly isModal: boolean
}

export const WeekList: React.FC<WeekListProps> = ({
  availableTimes,
  bucketedTimes,
  timeZone,
  reschedule,
  select,
  isModal,
}) => {
  return (
    <>
      {bucketedTimes.map((week, i) => {
        const headerClasses = `mobile-week-header ${
          CONSTS.WEEK_HEADERS.includes(week.displayLong) ? '' : 'excl-lz'
        }`
        return (
          <div key={week.displayLong + i} id={week.displayLong} className="week-container">
            <header className={headerClasses}>{week.displayLong}</header>
            <DayList
              days={week.days}
              availableTimes={availableTimes}
              timeZone={timeZone}
              reschedule={reschedule}
              select={select}
              isModal={isModal}
            />
          </div>
        )
      })}
    </>
  )
}

export const WeekListContainer = styled.div<ContainerPropsModalMode>`
  display: grid;
  margin-left: 0px;
  grid-template-columns: 100%;

  .info-icon {
    width: 16px;
    height: 16px;
  }

  .week-container {
    max-width: 100%;
    border: solid 1px ${appColors.weekGroupBorder};

    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);

    overflow: hidden;
    border-radius: 0px;
    margin-bottom: 0;
  }

  .mobile-week-header {
    display: flex;
    align-items: center;
    height: 34px;
    background-color: ${appColors.weekHeaderBackgroundMobile};
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: ${appColors.gray600};
    padding: 0 16px;
    border-bottom: solid 1px ${appColors.cardBorder};
    text-transform: capitalize;
  }

  /* Desktop view */
  ${({ isModal }: ContainerPropsModalMode) => desktopStylesWrapper(isModal).wrapper`
    .info-icon {
      width: 20px;
      height: 20px;
    }

    margin-left: 348px;
    margin-right: 24px;

    .week-container {
      border-radius: 5px;
      margin-bottom: 17px;
    }

    .mobile-week-header {
      background-color: ${appColors.weekHeaderBackgroundWeb};
    }
  `}
`
