import styled from 'styled-components'
import React from 'react'
import { SelectHostMemberListOption } from '../select_host_member_list_option/select_host_member_list_option'
import { HostMember } from 'common/redux/meetings/meetings_core'
import { SelectableListContainer } from 'components/atoms/selectable_list_container'

export interface SelectHostMemberListProps {
  readonly hostMembers: ReadonlyArray<HostMember>
  readonly select: (hostMemberID: string) => void
  readonly isModal: boolean
}

export const SelectHostMemberList = ({
  hostMembers,
  select,
  isModal,
}: SelectHostMemberListProps) => {
  return (
    <SelectableListContainer isModal={isModal}>
      {hostMembers.map(hostMember => (
        <MemberContainer key={hostMember.id}>
          <SelectHostMemberListOption hostMember={hostMember} select={select} />
        </MemberContainer>
      ))}
    </SelectableListContainer>
  )
}

const MemberContainer = styled.div`
  display: flex;
  width: 100%;
`
