import { appStrings } from '../../../assets/app_strings'
import { Session } from 'common/redux/session/session_core'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { countBy } from 'lodash'

const {
  headerTitles: {
    CHOOSE_A_MEETING,
    CHOOSE_A_TIME,
    ENTER_YOUR_INFORMATION,
    BOOKING_CONFIRMED,
    BOOKING_REQUESTED,
    RESCHEDULE_CONFIRMED,
    RESCHEDULED_REQUESTED,
    MEETING_CANCELLED,
    CANCEL_BOOKING,
    CHOOSE_A_NEW_TIME,
    SELECT_A_HOST_MEMBER,
  },
} = appStrings

export const getTitle = (session: Session, bookedAppointment: BookedAppointment): string => {
  const { location, sessionType } = session

  switch (sessionType) {
    case 'create':
      switch (location) {
        case 'meetings':
          return CHOOSE_A_MEETING
        case 'time':
          return CHOOSE_A_TIME
        case 'hostMember':
          return SELECT_A_HOST_MEMBER
        case 'confirm':
          return ENTER_YOUR_INFORMATION
        case 'complete':
          return bookedAppointment.status === 'CANCELLED'
            ? MEETING_CANCELLED
            : bookedAppointment.meeting?.confirmed
            ? BOOKING_CONFIRMED
            : BOOKING_REQUESTED
        default:
          return ''
      }
    case 'cancel':
      switch (location) {
        case 'cancel':
          return CANCEL_BOOKING
        case 'complete':
          return MEETING_CANCELLED
        default:
          return ''
      }

    case 'reschedule':
      switch (location) {
        case 'time':
          return CHOOSE_A_NEW_TIME
        case 'complete':
          return bookedAppointment.status === 'REQUESTED'
            ? RESCHEDULED_REQUESTED
            : RESCHEDULE_CONFIRMED
        default:
          return ''
      }
    default:
      return ''
  }
}

export const getShowBack = ({ startingDepth, path }: Session) => {
  if (startingDepth && path && startingDepth < countBy(path)['/'] && !path.includes('/meeting/')) {
    return true
  }
  return false
}
