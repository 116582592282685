import { defaultFontFamilies } from 'assets/shared_css_styles'
import React from 'react'
import styled from 'styled-components'
import { appColors } from '../../assets/app_colors'
import { appStrings } from '../../assets/app_strings'
import { Box } from '../../components/atoms/box'
import { Button } from '../../components/atoms/button/button'

interface UpdateBookingFeedbackProps {
  readonly hasButton?: boolean
  readonly marginTop?: number
  readonly setComment: (comment: string) => void
  readonly handleSubmit?: () => void
  readonly submitButtonLabel?: string
}

const UpdateBookingFeedback: React.FunctionComponent<UpdateBookingFeedbackProps> = ({
  hasButton = true,
  marginTop = 3,
  setComment,
  handleSubmit,
  submitButtonLabel,
}: UpdateBookingFeedbackProps) => {
  const handleSubmitWrapper = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (handleSubmit) {
      handleSubmit()
    }
  }

  return (
    <FeedbackContainer onSubmit={handleSubmitWrapper}>
      <Box mt={marginTop}>
        <TextareaContainer>
          <textarea
            placeholder={appStrings.completeCard.COMPLETE_COMMENT_PLACEHOLDER}
            className="bp3-input bp3-fill"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setComment(event.target.value)
            }}
          ></textarea>
        </TextareaContainer>
      </Box>
      {hasButton && (
        <Box mt={3}>
          <Button fullWidth type="submit" bg={appColors.primary} color={appColors.lightText}>
            {submitButtonLabel}
          </Button>
        </Box>
      )}
    </FeedbackContainer>
  )
}

export default UpdateBookingFeedback

/* ******************

       Styles

  ******************* */

const FeedbackContainer = styled.form``

const TextareaContainer = styled.div`
  && > textarea {
    height: 80px;
    padding: 12px 16px;
    resize: none;
    box-shadow: none;
    border: 1px solid ${appColors.gray400};
    border-radius: 5px;
    // todo: fix this... should not need
    ${defaultFontFamilies}
  }
`
