import React from 'react'
import { List } from '../../components/atoms/list/list'
import { ListItemProps } from '../../components/atoms/list/list_item'
import { timeConversion } from '../../util/style_utils'
import { Meetings } from 'common/redux/meetings/meetings_core'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { Organization } from 'common/redux/organization/organization_core'
import { SelectableListContainer } from 'components/atoms/selectable_list_container'

interface SelectableListProps {
  readonly items: Meetings
  readonly organization: Organization
  readonly setInfoModal: (isOpen: boolean, id: string, modalType: InfoModalType) => void
  readonly isModal: boolean
}

export const SelectableList = ({
  items,
  setInfoModal,
  organization,
  isModal,
}: SelectableListProps) => {
  const listItems: ReadonlyArray<ListItemProps> | null = !items
    ? null
    : items.sort.map(id => {
        const item = items.items[id]
        return {
          id: item.id,
          slug: item.slug,
          subLabel: item.duration ? timeConversion(item.duration) : undefined,
          label: item.name,
          image: item.image,
          price: item.priceFormatted,
          showPrice: item.price > 0,
          setInfoModal,
        }
      })

  if (!listItems) {
    return null
  }

  return (
    <SelectableListContainer className={'Selectable-List'} isModal={isModal}>
      <List
        {...{
          setInfoModal,
          items: listItems,
          welcomeMessage: organization.description,
        }}
      />
    </SelectableListContainer>
  )
}
