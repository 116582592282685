import React, { useState } from 'react'
import { appColors } from '../../../assets/app_colors'
import styled from 'styled-components'
import { utils } from 'common/util/common_utils'
import { TimezoneInputController } from '../timezone_input_controller/timezone_input_controller'
import { boldTextHelper } from '../../../util/components/timezone_modal'
import { TimezoneModal } from 'common/redux/timezone_modal/timezone_modal_core'
import { Timezone } from 'common/redux/timezone/timezone_core'

const { buildTimezone } = utils

interface TimezoneSelectProps {
  readonly timezoneModal: TimezoneModal
  readonly setTimezone: (timezone: Timezone) => void
}

export const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  timezoneModal: { timezones },
  setTimezone,
}) => {
  const [timezoneSearchValue, setTimezoneSearchValue] = useState('')
  const [searchUpdate, setSearchUpdate] = useState<number | undefined>(undefined)
  const timezoneSearchValueLower = timezoneSearchValue.toLocaleLowerCase()

  const triggerSearchUpdate = (searchValue: string) => {
    clearTimeout(searchUpdate)
    setSearchUpdate(
      window.setTimeout(() => {
        setTimezoneSearchValue(searchValue)
      }, 250)
    )
  }

  return (
    <Container>
      <TimezoneInput setTimezoneSearchValue={triggerSearchUpdate} />
      <OptionsContainer>
        {timezones.map(timezone => {
          return (
            <>
              {timezone.searchKey.includes(timezoneSearchValueLower) && (
                <Option
                  key={timezone.label}
                  timezoneSearchValue={timezoneSearchValueLower}
                  setTimezone={setTimezone}
                  label={timezone.label}
                  value={timezone.value}
                  displayLabel={timezone.displayLabel}
                />
              )}
            </>
          )
        })}
      </OptionsContainer>
    </Container>
  )
}

interface TimezoneOptionProps {
  readonly setTimezone: (timezone: Timezone) => void
  readonly displayLabel: string
  readonly label: string
  readonly value: string
  readonly timezoneSearchValue: string
}

const Option: React.FC<TimezoneOptionProps> = ({
  label,
  value,
  setTimezone,
  displayLabel,
  timezoneSearchValue,
}) => {
  const selectTimezone = () => {
    setTimezone(buildTimezone(label))
  }

  const parsedLabel = boldedText(displayLabel, timezoneSearchValue)

  return (
    <OptionContainer onClick={selectTimezone}>
      <span className="timezone-label excl-lz">{parsedLabel}</span>
      <span className="timezone-time">{value}</span>
    </OptionContainer>
  )
}

const boldedText = (text: string, search: string) => {
  const { before, match, after } = boldTextHelper(text, search)
  return (
    <span>
      {before}
      <b>{match}</b>
      {after}
    </span>
  )
}

interface TimezoneInputProps {
  readonly setTimezoneSearchValue: (searchValue: string) => void
}

const TimezoneInput = ({ setTimezoneSearchValue }: TimezoneInputProps) => (
  <InputContainer>
    <TimezoneInputController setTimezoneSearchValue={setTimezoneSearchValue} />
  </InputContainer>
)

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-bottom: 9px;
`

const OptionsContainer = styled.div`
  border-top: 1px solid ${appColors.timezoneSelectionBorder};
  overflow-y: auto;
  width: 100%;
`

const InputContainer = styled.div`
  width: calc(100% - 32px);
  margin: 16px 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${appColors.gray400};
  min-height: 40px;
  height: 40px;
  display: flex;
`

const OptionContainer = styled.button`
  padding: 0 22px 0 22px;
  display: flex;
  min-height: 50px;
  width: 100%;
  border: 0;
  background: inherit;
  border-bottom: 1px solid ${appColors.timezoneSelectionBorder};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .timezone-time {
    color: ${appColors.mediumText};
  }

  &:hover {
    background-color: ${appColors.mediumLightBackground};
  }
`
