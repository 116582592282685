// todo - rename to header.tsx once ready to swap out
import React from 'react'
import styled from 'styled-components'
import { ChevronLeft } from 'react-feather'

import { appColors } from '../../../assets/app_colors'
import { PoweredBy } from './powered_by'
import { Maybe } from 'graphql'
import { desktopStylesWrapper } from 'assets/shared_css_styles'

export interface HeaderProps {
  /*
   * Brand color overrides the top border if present
   */
  brandColor?: string
  /*
   * Name of the team
   */
  teamName: Maybe<string>
  /*
   * Slug of the team - used in appointlet branding link utm_source
   */
  teamSlug: string
  /*
   * URL to team logo - image or svg
   */
  teamLogo?: Maybe<string>
  /*
   * Title of the current screen
   */
  screenTitle: string
  /*
   * Callback function when clicking nav button
   */
  onNavClick: () => void
  /*
   * Callback function when team metadata loads (including image if present)
   * Used to hide page contents until team logo is loaded
   */
  /*
   * Whether to show appointlet branding badge
   */
  showBadge?: boolean
  /*
   * Type of nav button to show: "BACK" OR "CLOSE" OR "" (none)
   */
  showBack: boolean
  /*
   * Is the app running as a modal
   */
  isModal: boolean
}

export const Header: React.FC<HeaderProps> = ({
  brandColor,
  teamName,
  teamLogo,
  teamSlug,
  screenTitle,
  onNavClick,
  showBadge,
  showBack,
  isModal,
}) => (
  <StyledContainer id="header" brandColor={brandColor} isModal={isModal}>
    {showBack && (
      <button className="nav" onClick={onNavClick}>
        <ChevronLeft className="nav-btn" />
      </button>
    )}

    <div className={`titles-and-logo ${showBack ? '' : ' no-back'}${teamLogo ? ' with-logo' : ''}`}>
      {teamLogo && (
        <img src={teamLogo} className="team-logo excl-lz" alt={teamName || 'Team Logo'} />
      )}
      <div className="titles">
        <span className="team-name excl-lz">{teamName}</span>
        <span className="screen-title">{screenTitle}</span>
      </div>
    </div>
    {showBadge && (
      <div className="badge">
        <PoweredBy brandColor={brandColor} teamSlug={teamSlug} isModal={isModal} />
      </div>
    )}
  </StyledContainer>
)

const INNER_HEIGHT_MOBILE = 63
const INNER_HEIGHT_DESKTOP = 65
const BORDER_HEIGHT = 5

interface ContainerProps {
  brandColor?: string
  isModal: boolean
}

const StyledContainer = styled.header<ContainerProps>`
  // mobile first
  display: flex;
  position: fixed;
  width: ${({ isModal }) => (isModal ? 75 : 100)}vw;

  ${({ isModal }) =>
    isModal &&
    `
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  `}

  z-index: 20;

  background: ${appColors.lightBackground};

  // adaptable border color
  border-top: ${BORDER_HEIGHT}px solid
    ${({ brandColor }) => (brandColor ? brandColor : appColors.primary)};
  border-bottom: 1px solid ${appColors.gray300};

  .titles-and-logo {
    display: flex;
    align-items: center;
    width: 100%;
    &.no-back {
      .team-logo {
        margin-left: 17px;
      }
      .titles {
        margin-left: 18px;
      }

      &.with-logo {
        .titles {
          margin-left: 0;
        }
      }
    }
  }

  .team-logo {
    margin-right: 12px;
    max-height: 36px;
  }

  .titles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: ${INNER_HEIGHT_MOBILE}px;
  }

  .nav {
    cursor: pointer;
    height: ${INNER_HEIGHT_MOBILE}px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: inherit;
  }

  .badge {
    position: absolute;
    top: -${BORDER_HEIGHT}px;
    right: 0;
  }

  .team-name {
    color: ${appColors.gray600};
    font-size: 12px;
    line-height: 18px;
  }

  .screen-title {
    color: ${appColors.gray900};
    font-size: 16px;
    font-family: 'Space Grotesk';
    line-height: 24px;
  }

  .nav-btn {
    color: ${appColors.gray600};
  }

  // very small-mobile only
  @media all and (max-width: 359px) {
    // hide logo on small mobiles
    .team-logo {
      display: none;
    }
    // add left margin back to titles since logo hidden
    .titles-and-logo.no-back.with-logo {
      .titles {
        margin-left: 12px;
      }
    }
  }

  // desktop overrides
  ${({ isModal }) => desktopStylesWrapper(isModal).wrapper`
    justify-content: center;

    .nav {
      position: absolute;
      left: 0;
      width: 56px;
      height: ${INNER_HEIGHT_DESKTOP}px;
    }

    .titles-and-logo {
      justify-content: center;
      &.no-back {
        .titles,
        .team-logo {
          margin-left: 0; // cancel out mobile margin
        }
      }
      &.with-logo {
        .titles {
          align-items: flex-start;
          min-width: 12rem; // w jumping when page title length changes
        }
      }
    }

    .titles {
      align-items: center;
      height: ${INNER_HEIGHT_DESKTOP}px;
      margin-left: 0 !important;
    }

    .team-name {
      font-size: 14px;
      line-height: 20px;
    }

    .screen-title {
      font-size: 18px;
      line-height: 24px;
    }
  `}
`
