import React from 'react'
import { Card } from '../../atoms/card'
import { Box } from '../../atoms/box'
import { Button } from '../../atoms/button/button'
import { Circle } from '../../atoms/circle'
import { Typography } from '../../atoms/typography/typography'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import { locationTypeToIcon } from '../meeting_info/meeting_info_items'
import { getHeaders, getLocationText, stateToColor } from '../../../util/components/complete_card'
import InfoItem from './complete_card_info_item'

// icons
import { ReactComponent as MeetingTimeIcon } from 'assets/Icons/meeting-time.svg'
import { ReactComponent as MeetingTypeIcon } from 'assets/Icons/meeting-type.svg'

import { Check, MapPin, X } from 'react-feather'
import { LocationType } from 'common/redux/meetings/meetings_core'
import { Maybe } from 'graphql'

export type CompleteCardState =
  | 'confirmed'
  | 'meetingRequested'
  | 'rescheduleConfirmed'
  | 'rescheduleRequested'
  | 'cancelled'
  | 'cancellationRequested'

const StateIcon = ({ state }: { state: CompleteCardState }) => {
  const iconProps = {
    color: '#ffffff',
    size: 44,
    strokeWidth: 3,
  }
  switch (state) {
    case 'confirmed':
    case 'rescheduleConfirmed':
    case 'rescheduleRequested':
    case 'meetingRequested':
      return <Check {...iconProps} />
    case 'cancellationRequested':
    case 'cancelled':
      return <X {...iconProps} />
  }
}

export interface CompleteCardProps {
  teamName: Maybe<string>
  teamSlug: string
  meetingType: string
  startTime: string
  endTime: string
  timezone: string
  date: string
  location: Maybe<string>
  locationType: LocationType
  state: CompleteCardState
  showAppointletBranding: boolean
}

export const CompleteCard: React.FC<CompleteCardProps> = ({
  teamName,
  teamSlug,
  meetingType,
  startTime,
  endTime,
  timezone,
  date,
  location,
  locationType,
  state,
  showAppointletBranding,
}) => {
  const { header, subtitle } = getHeaders(state)
  const subtitleWithTeam = subtitle.replace(/TEAM_NAME/g, teamName || '')

  const [locationText, translateLocation] = getLocationText(location, locationType)

  const appointletButtonURL =
    `https://www.appointlet.com/` +
    `?utm_source=appointlet` +
    `&utm_campaign=branding` +
    `&utm_medium=scheduler` +
    `&utm_term=${teamSlug}` +
    `&utm_content=completed`

  const LocationIcon = locationTypeToIcon[locationType]?.icon || MapPin

  const {
    completeCard: { iconBackgrounds, icon },
  } = appColors

  return (
    <Card className="complete-card">
      <Box p={4}>
        <Box display="flex" justifyContent="center">
          <Circle size={64} bg={stateToColor(state)}>
            <StateIcon state={state} />
          </Circle>
        </Box>
        <Box textAlign="center" mt={3}>
          <Typography variant="h1" gutterBottom>
            {header}
          </Typography>
          {subtitleWithTeam !== '' && (
            <Typography variant="subtitle1">{subtitleWithTeam}</Typography>
          )}
        </Box>

        <InfoItem marginTop={2} icon={<MeetingTypeIcon />} iconBg={iconBackgrounds.type}>
          <Typography variant="body1" disableTranslate className="wrap">
            {meetingType}
          </Typography>
        </InfoItem>

        <InfoItem marginTop={2} icon={<MeetingTimeIcon />} iconBg={iconBackgrounds.time}>
          <Typography variant="body1">
            {`${startTime}${endTime ? ` - ${endTime}` : ''}`}{' '}
            <Typography variant="span" color={appColors.gray600} disableTranslate>
              {timezone}
            </Typography>
          </Typography>
          <Typography variant="body1" capitalize disableTranslate>
            {date}
          </Typography>
        </InfoItem>

        {locationText && (
          <InfoItem
            marginTop={2}
            icon={<LocationIcon color={icon.location} />}
            iconBg={iconBackgrounds.location}
          >
            <Typography variant="body1" disableTranslate={!translateLocation}>
              {locationText}
            </Typography>
          </InfoItem>
        )}
        {showAppointletBranding && (
          <Box mt={3}>
            <Button
              fullWidth
              href={appointletButtonURL}
              target="_blank"
              border={appColors.gray400}
              color={appColors.primary}
            >
              {appStrings.completeCard.COMPLETE_APPOINTLET_BUTTON}
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  )
}
