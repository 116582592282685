import React from 'react'
import { appStrings } from '../assets/app_strings'

const {
  minute: minuteLabel,
  minutes: minutesLabel,
  hour: hourLabel,
  hours: hoursLabel,
} = appStrings

/*
 * spacing utility for calculating margin/padding spacing based on multiples of 8px
 *
 * usage:
 *   spacing(1)          // "8px"
 *   spacing(1, 2)       // "8px 16px"
 *   spacing(1, 0, 1)    // "8px 0px 8px"
 *   spacing(1, 0, 1, 2) // "8px 0px 8px 16px"
 */
export const spacing = (...nums: number[]) => {
  return nums.map(i => `${i * 8}px`).join(' ')
}

export const timeConversion = (rawMinutes: number | undefined): JSX.Element | undefined => {
  if (!rawMinutes) {
    return undefined
  }

  const hours = Math.floor(rawMinutes / 60)
  const minutes = rawMinutes % 60
  const hoursFormatted: JSX.Element | undefined =
    hours > 0 ? (
      hours === 1 ? (
        <span>
          <span className="excl-lz">{hours}</span>
          &nbsp;
          <span>{hourLabel}</span>
        </span>
      ) : (
        <span>
          <span className="excl-lz">{hours}</span>
          &nbsp;
          <span>{hoursLabel}</span>
        </span>
      )
    ) : undefined

  const minutesFormatted: JSX.Element | undefined =
    minutes > 0 ? (
      minutes === 1 ? (
        <span>
          <span className="excl-lz">{minutes}</span>
          &nbsp;
          <span>{minuteLabel}</span>
        </span>
      ) : (
        <span>
          <span className="excl-lz">{minutes}</span>
          &nbsp;
          <span>{minutesLabel}</span>
        </span>
      )
    ) : undefined
  const separator: JSX.Element | undefined =
    hoursFormatted && minutesFormatted ? <span>,&nbsp;</span> : undefined

  return (
    <>
      {hoursFormatted}
      {separator}
      {minutesFormatted}
    </>
  )
}

export const calculateVisibilityForElement = (element: HTMLElement, offset: number) => {
  const { top, bottom } = element.getBoundingClientRect()
  const windowHeight = window.innerHeight
  return Math.max((Math.min(windowHeight, bottom) - Math.max(offset, top)) / (bottom - top), 0)
}
