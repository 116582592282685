import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState } from 'common/redux/sitka'
import { Fields, defaultFields } from './fields_core'
import { put } from 'redux-saga/effects'

export class FieldsModule extends SitkaModule<Fields, AppModules> {
  public moduleName: string = 'fields'
  public defaultState: Fields = defaultFields
  public static selectFields(state: AppState): Fields {
    return state.fields
  }

  public *set(state: Fields) {
    yield put(this.setState(state, true))
  }
}
