interface RouteMatch {
  path: string
}

export interface Bootstrap {
  readonly currentPage?: RouteMatch
}

export const defaultBootstrap: Bootstrap = {
  currentPage: undefined,
}
