import React from 'react'
import styled from 'styled-components'
import { Classes } from '@blueprintjs/core'
import { Link, useLocation } from 'react-router-dom'

import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'

import { ModalInfo } from '../modal_info'
import { ModalHeader } from '../modal_header'
import { LocationType } from 'common/redux/meetings/meetings_core'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { Maybe } from 'graphql'

export interface MeetingModalProps {
  readonly setInfoModal: (isOpen: boolean, id: string) => void
  /**
   * The slug of the meeting
   */
  readonly slug: string
  /**
   * The name of the meeting type
   */
  readonly name: string
  /**
   * The image associated with the meeting type
   */
  readonly image: Maybe<string>
  /**
   * The description of the meeting type
   */
  readonly description?: Maybe<string>
  /**
   * The duration of the meeting type (ex: "30 minutes")
   */
  readonly duration: number
  /**
   * The description of the meeting location
   */
  readonly locationType?: Maybe<LocationType>
  /**
   * The location to meet, overrides locationType
   */
  readonly location?: Maybe<string>
  /**
   * The start time of a meeting (ex: "9:00 am")
   */
  readonly startTime?: string
  /**
   * The end time of a meeting (ex: "9:30 am")
   */
  readonly endTime?: string
  /**
   * The date of the meeting
   */
  readonly date?: string
  /**
   * The type of the Modal
   */
  readonly modalType: InfoModalType
  /**
   * Price formatted as a string
   */
  readonly priceFormatted?: string
  /**
   * Currency used for price
   */
  readonly priceCurrency?: Maybe<string>
}

const modalClasses = [Classes.OVERLAY_CONTENT, Classes.ELEVATION_4, 'modal'].join(' ')

export const MeetingModal = ({
  setInfoModal,
  slug,
  name,
  description,
  duration,
  image,
  locationType,
  location,
  startTime,
  endTime,
  date,
  modalType,
  priceFormatted,
  priceCurrency,
}: MeetingModalProps) => {
  const onCloseClick = () => {
    setInfoModal(false, '')
  }

  const showButton = modalType === 'InfoWithButton'

  const routeLocation = useLocation()
  const { pathname } = routeLocation
  const url = pathname.slice(-1) === '/' ? `${pathname}${slug}/` : `${pathname}/${slug}/`

  return (
    <Modal className={modalClasses} showButton={showButton}>
      <ModalHeader {...{ image, name, onCloseClick, disableTranslate: true }} />
      <ModalInfo
        {...{
          date,
          description,
          duration,
          endTime,
          location,
          locationType,
          startTime,
          priceFormatted,
          priceCurrency,
        }}
      />
      {showButton && (
        <ModalFooter>
          <FooterButton onClick={onCloseClick} to={{ pathname: url, search: routeLocation.search }}>
            {appStrings.headerTitles.CHOOSE_A_TIME} {appStrings.rightArrow}
          </FooterButton>
        </ModalFooter>
      )}
    </Modal>
  )
}

interface ModalProps {
  readonly showButton: boolean
}

const Modal = styled.div<ModalProps>`
  width: 92%;
  max-height: calc(92% - 64px);
  background: ${appColors.lightBackground};
  overflow: hidden;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: grid;
  grid-template-rows: ${({ showButton }) => (showButton ? '68px 1fr 72px' : '68px 1fr')};

  &:focus {
    outline: none;
  }

  // fade & scale transtion
  &.modal.bp3-overlay-enter {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
  }
  &.modal.bp3-overlay-enter-active {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    transition: opacity 300ms, transform 300ms;
  }
  &.modal.bp3-overlay-exit {
    opacity: 1;
  }
  &.modal.bp3-overlay-exit-active {
    opacity: 0;
    transform: scale(0.9) translate(-55%, -55%);
    transition: opacity 300ms, transform 300ms;
  }

  @media all and (min-width: 699px) {
    width: 400px;
    left: calc(50% - 200px);
    top: 64px;
    transform: unset;
    &.modal.bp3-overlay-enter {
      transform: scale(0.9);
    }
    &.modal.bp3-overlay-enter-active {
      transform: scale(1);
    }
    &.modal.bp3-overlay-exit-active {
      transform: scale(0.9);
    }
  }
`
const ModalFooter = styled.div`
  padding: 16px;
`

const FooterButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: ${appColors.primary};
  border-radius: 5px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${appColors.lightText};
  cursor: pointer;

  text-decoration: unset;

  &:hover {
    cursor: pointer;
    text-decoration: unset;
    color: ${appColors.lightText};
  }
`
