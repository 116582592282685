import React from 'react'
import { TextField } from './text_field'
import { ChoiceField } from './choice_field'
import { MultipleChoiceField } from './multiple_choice_field'
import { BooleanField } from './boolean_field'
import { CustomDateField } from './custom_date_field'
import { PlaceField } from './place_field'
import { Field } from 'common/redux/fields/fields_core'
import { FieldValue } from 'common/redux/field_values/field_values_core'

export interface FieldProps extends Field {
  readonly fieldId: string
  readonly helpText: string
  readonly autoFocus: boolean
  readonly value: FieldValue
}

const getField = (field: FieldProps, timezoneCode: string) => {
  switch (field.kind) {
    case 'TEXT':
      return <TextField {...field} type="text" />
    case 'EMAIL':
      return <TextField {...field} type="text" />
    case 'DATE':
      return <CustomDateField {...field} type="date" timezoneCode={timezoneCode} />
    case 'TIME':
      return <TextField {...field} type="time" />
    case 'MULTITEXT':
      return <TextField {...field} type="text" />
    case 'CHOICE':
      return <ChoiceField {...field} />
    case 'MULTICHOICE':
      return <MultipleChoiceField {...field} />
    case 'TOGGLE':
      return <BooleanField {...field} />
    case 'PLACE':
      return <PlaceField {...field} />
    case 'PLACE_USER':
      return <PlaceField {...field} />
    case 'PHONE_USER':
      return <TextField {...field} type="text" />
    default:
      return <TextField {...field} type="text" />
  }
}

const getHelper = (type: string, helpText: string) => {
  const hasHelperText = !!helpText
  const isToggle = type === 'TOGGLE'
  return hasHelperText && !isToggle && <div className={'helper-top'}>{helpText}</div>
}

interface CustomFieldProps {
  readonly field: Field
  readonly value: FieldValue
  readonly autoFocus: boolean
  readonly timezoneCode: string
  readonly defaultFields?: Array<string>
  readonly localizeExclude?: boolean
}

export const CustomField = ({
  field,
  autoFocus,
  value,
  timezoneCode,
  defaultFields,
  localizeExclude,
}: CustomFieldProps): JSX.Element => {
  const {
    validators: { required: isRequired },
    visible,
    fieldId,
  } = field

  const wrapperClassName = (fieldId: string, visible?: boolean) => {
    const className = `${visible ? '' : 'hidden-field '}${
      fieldId.includes('Name') ? 'inline-field' : 'full-width'
    }`
    if (defaultFields && defaultFields.includes(fieldId)) {
      return `${className} default-field`
    }
    return className
  }

  return (
    <div className={wrapperClassName(fieldId, visible)}>
      <div className="field-group">
        <div className="field-label-wrapper">
          <label className={'field-label' + (localizeExclude ? ' excl-lz' : '')}>
            {field.label}
          </label>
          {!isRequired && <span className="is-required optional-label">Optional</span>}
        </div>
        {getField({ ...field, autoFocus, value }, timezoneCode)}
        {getHelper(field.kind, field.helpText)}
      </div>
    </div>
  )
}
