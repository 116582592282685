import { SitkaModule } from 'olio-sitka'
import { AppModules, AppState, store } from 'common/redux/sitka'
import { call, put, select } from 'redux-saga/effects'
import { Client, HandledResp } from 'common/api/client'
import { SessionModule } from '../session/session_module'
import { Session } from '../session/session_core'
import { parseErrors, getUpdateBookingPayload } from './submit_update_booking_util'
import { SubmitUpdateBooking, defaultUpdateBookingReason } from './submit_update_booking_core'
import { Intent } from '@blueprintjs/core/lib/esm/common/intent'
import { BookedAppointmentModule } from '../booked_appointment/booked_appointment_module'
import { BookedAppointment } from '../booked_appointment/booked_appointment_core'

export class SubmitUpdateBookingModule extends SitkaModule<SubmitUpdateBooking, AppModules> {
  public moduleName: string = 'submitUpdateBooking'
  public defaultState: SubmitUpdateBooking = defaultUpdateBookingReason

  public *handleUpdateBooking(): {} {
    yield this.modules.session.setSpinner(true)
    const session: Session = yield select(SessionModule.selectSession)

    const type = session.sessionType
    const state: AppState = yield select(store.getState)

    if (type !== 'cancel') {
      yield this.modules.genericModal.setModalIsOpen(false, '')
    }
    const payload = getUpdateBookingPayload(state, type)

    const client = new Client()
    const resp: HandledResp =
      type === 'cancel'
        ? yield call(client.submitCancelBooking, payload)
        : yield call(client.submitRescheduleBooking, payload)

    // parse update booking response
    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      yield this.modules.session.setSpinner(false)
      return true
    }

    yield this.modules.session.setSessionMeta(type, 'complete')
    const bookedAppointment: BookedAppointment = yield select(
      BookedAppointmentModule.selectBookedAppointment
    )
    yield this.modules.session.setRedirect(`/meeting/${bookedAppointment.externalId}`)
  }

  public *handleUpdateBookingReason(updateBookingReason: string) {
    yield put(this.setState({ updateBookingReason }, true))
  }
}
