import React from 'react'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'
import { ReactFeatherIcon } from '../../../util/icon'
import { spacing } from '../../../util/style_utils'

interface MeetingInfoItemProps {
  readonly label?: JSX.Element | string
  readonly icon?: ReactFeatherIcon
  readonly margin?: number
  readonly className?: string
  readonly disableLocalize?: boolean
  readonly capitalize?: boolean
}

export const MeetingInfoItem: React.FC<MeetingInfoItemProps> = ({
  label,
  icon: Icon,
  className = '',
  margin,
  disableLocalize = false,
  capitalize = false,
}) => {
  if (!label) {
    return null
  }
  return (
    <Container className={`meeting-info-item ${className}`} margin={margin} capitalize={capitalize}>
      {Icon && <Icon color={appColors.lightGrayIcon} size={20} className="info-icon" />}
      <div className={`text-label${disableLocalize ? ' excl-lz' : ''}`}>{label}</div>
    </Container>
  )
}

const Container = styled.div<MeetingInfoItemProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 14px;
  line-height: 16.71px;
  text-overflow: ellipsis;

  color: ${appColors.subHeaderText};
  margin-top: ${({ margin }) => spacing(margin !== undefined ? margin : 1.2)};

  .text-label {
    display: flex;
    align-items: center;
    ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
  }

  .info-icon {
    margin-right: 6px;
  }
`
