import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { Meeting, Meetings } from 'common/redux/meetings/meetings_core'
import { FeatureFlags } from 'common/redux/organization/organization_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { utils } from 'common/util/common_utils'
import { Maybe } from 'graphql'
import { DateTime } from 'luxon'

export interface TimeProps {
  readonly handleChangeTimezone: (timezone: Timezone) => void
  readonly currentTime: string
  readonly description?: Maybe<string>
  readonly duration: number
  readonly image?: Maybe<string>
  readonly modalOpenOnRender: boolean
  readonly name: string
  readonly timezone: Timezone
  readonly priceFormatted?: string
  readonly priceCurrency?: Maybe<string>
  readonly type: string
}

export const getTimeProps = (
  bookedAppointment: BookedAppointment,
  meetings: Meetings,
  sessionType: string,
  handleChangeTimezone: (timezone: Timezone) => void,
  featureFlags: ReadonlyArray<FeatureFlags>,
  timezone: Timezone
): TimeProps => {
  // if session type is "reschduleBooking" or "accessConference", return values from BookedAppointment
  const selectedMeeting: Meeting = meetings.items[meetings.selected]

  if (sessionType === 'reschedule' && selectedMeeting && bookedAppointment.meeting) {
    const {
      description,
      duration,
      image,
      name,
      price,
      priceFormatted,
      priceCurrency,
    } = selectedMeeting

    return {
      description,
      duration,
      image,
      name,
      timezone,
      priceFormatted: price > 0 ? priceFormatted : undefined,
      priceCurrency,
      currentTime: DateTime.now()
        .setZone(timezone.momentCode)
        .toLocaleString(DateTime.TIME_SIMPLE)
        .toLocaleLowerCase(),

      type: 'time',
      handleChangeTimezone,
      modalOpenOnRender: false,
    }
  }

  // if session type is not "rescheduleBooking", return values from meetings.
  const {
    description,
    duration,
    image,
    name,
    price,
    priceFormatted,
    priceCurrency,
  } = selectedMeeting
  return {
    description,
    duration,
    image,
    name,
    timezone,
    priceFormatted: price > 0 ? priceFormatted : undefined,
    priceCurrency,
    currentTime: DateTime.now()
      .setZone(timezone.momentCode)
      .toLocaleString(DateTime.TIME_SIMPLE)
      .toLocaleLowerCase(),

    type: 'time',
    handleChangeTimezone,
    modalOpenOnRender: false,
  }
}

export const getGoogleMapScript = (): HTMLScriptElement => {
  const script = document.createElement('script')
  const apiKey = utils.env('GOOGLE_MAPS_API_KEY')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
  script.async = true
  script.id = 'places-snippet'

  return script
}

export const areArraysEqual = (a: readonly string[], b: readonly string[]): boolean => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  const aSort = [...a].sort()
  const bSort = [...b].sort()

  for (let i = 0; i < aSort.length; ++i) {
    if (aSort[i] !== bSort[i]) return false
  }
  return true
}
