import React, { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { appColors } from '../../assets/app_colors'

const CardContainer = styled.div`
  background-color: ${appColors.lightBackground};
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${appColors.shadow1};
  border: 1px solid ${appColors.cardBorder};
`

export interface CardProps {
  children?: ReactNode
  className?: string
}

export const Card: FC<CardProps> = ({ children, ...props }) => (
  <CardContainer {...props}>{children}</CardContainer>
)

Card.propTypes = { children: PropTypes.node.isRequired }
