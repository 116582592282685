import { ContainerPropsModalMode, desktopStylesWrapper } from 'assets/shared_css_styles'
import { AvailableTimes } from 'common/redux/available_times/available_times_core'
import { Day as DayState } from 'common/redux/bucketed_times/bucketed_times_core'
import { Timezone } from 'common/redux/timezone/timezone_core'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { ArrowDown } from 'react-feather'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import sunIcon from '../../../assets/Icons/sun.png'
import { Time } from './time'

interface DayProps {
  readonly day: DayState
  readonly times: ReadonlyArray<string>
  readonly availableTimes: AvailableTimes
  readonly index: number
  readonly isMobile: boolean
  readonly timeZone: Timezone
  readonly reschedule: boolean
  readonly select: (id: string) => void
  readonly isModal: boolean
}

interface TimeAnimatorProps extends ContainerPropsModalMode {
  readonly times: number
}
export const Day: React.FC<DayProps> = ({
  day,
  times,
  availableTimes,
  index,
  isMobile,
  timeZone,
  reschedule,
  select,
  isModal,
}) => {
  const [isExpanded, toggleExpand] = useState(false)

  const handleToggleExpand = () => {
    toggleExpand(!isExpanded)
  }

  // injects sun icon into mobile display times between last AM time and first PM time
  const timesBuilder = (times: ReadonlyArray<string>, timeZone: string): Array<string> => {
    return times.reduce((acc, time, i) => {
      const previousTime = i > 0 && times[i - 1]
      const isFirstAfterNoon =
        previousTime &&
        DateTime.fromISO(previousTime).setZone(timeZone).hour < 12 &&
        DateTime.fromISO(time).setZone(timeZone).hour >= 12

      if (isFirstAfterNoon) {
        return [...acc, 'sunIcon', time]
      }
      return [...acc, time]
    }, [] as string[])
  }

  const displayTimes = timesBuilder(times, timeZone.momentCode)
  const buttonText = appStrings.moreTimes
  const showExpandButton = times.length > 11
  const isShortList = !showExpandButton
  //  This is the animation timeout based on day index
  const timeout = (index + 1) * 200
  const sunIconIndex = times.indexOf('sunIcon')

  return (
    <DayContainer isExpanded={isExpanded} isShortList={isShortList} isModal={isModal}>
      <DayLabel day={day} />
      <CSSTransition
        appear={isMobile}
        in={isMobile}
        classNames="time-animation"
        timeout={{ enter: isMobile ? timeout : 0, exit: 0 }}
      >
        <TimeAnimator
          className="time-animator"
          key="time"
          times={displayTimes.length}
          isModal={isModal}
        >
          {displayTimes.map((time, i) => {
            const calcTabIndex = () => {
              if (isExpanded) {
                return 0
              } else {
                if (sunIconIndex === -1 || sunIconIndex >= 9) {
                  return i > 9 ? -1 : 0
                }
                if (sunIconIndex < 9) {
                  return i > 10 ? -1 : 0
                }
              }
              return 0
            }

            return time === 'sunIcon' ? (
              <img key={time + i} src={sunIcon} className={'sun-icon'} alt={'Sun icon'} />
            ) : (
              <Time
                key={time + i}
                time={availableTimes.items[time]}
                reschedule={reschedule}
                select={select}
                isModal={isModal}
                tabIndex={calcTabIndex()}
                mapId={`id-${i}`}
              />
            )
          })}
        </TimeAnimator>
      </CSSTransition>
      {showExpandButton && !isExpanded && (
        <MoreTimesButton handleClick={handleToggleExpand} buttonText={buttonText} />
      )}
    </DayContainer>
  )
}
interface MoreTimesButtonProps {
  readonly handleClick: () => void
  readonly buttonText: string
}

const MoreTimesButton = ({ handleClick, buttonText }: MoreTimesButtonProps): JSX.Element => (
  <>
    <button
      onClick={handleClick.bind(handleClick)}
      tabIndex={0}
      className="more-times-btn"
      id="more-times-btn"
    >
      {buttonText} <ArrowDown className="more-times-btn-icon" />
    </button>
    <div className="time-button-shadow" />
  </>
)

interface DayLabelProps {
  readonly day: DayState
}

const DayLabel = ({ day }: DayLabelProps): JSX.Element => (
  <DayLabelStyled day={day}>
    <div className="day-label">
      <span className="day-name-mobile excl-lz">{day.dayNameShort}</span>
      <span className="day-name excl-lz">{day.dayName}</span>
      <span className="month excl-lz">{day.month}</span>
      <span className="day-number">{day.dayNumber}</span>
      <span className="day-number-suffixed excl-lz">{day.dayNumberSuffixed}</span>
      <span className="year">{day.year}</span>
    </div>
  </DayLabelStyled>
)
interface DayContainerProps extends ContainerPropsModalMode {
  readonly isExpanded: boolean
  readonly isShortList: boolean
}

const DayLabelStyled = styled.div<DayLabelProps>`
  z-index: 15;

  .day-label {
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 66px;
    width: 62px;
    padding: 12px 16px;
    background-color: ${appColors.lightBackground};
    border-right: 1px solid ${appColors.shadow2};
  }

  .day-name {
    display: none;
    text-transform: capitalize;
  }

  .day-name-mobile {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    color: ${({ day: { isToday } }) => (isToday ? appColors.primary : 'inherit')};
  }

  .day-name-box {
    background: ${appColors.lightBackground};
    border: 1px solid ${appColors.shadow2};
    border-radius: 2px;
    height: 26px;
    width: 68px;
    margin-bottom: 8px;
    position: relative;
    top: -10px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05),
      0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .day-number {
    font-size: 24px;
    font-weight: 700;
    color: ${({ day: { isToday } }) => (isToday ? appColors.primary : appColors.darkText)};
  }

  .month {
    display: none;
    text-transform: capitalize;
  }
`
const DayContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${appColors.shadow2};
  overflow: hidden;

  &:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .time-animator {
    -webkit-overflow-scrolling: touch;
  }

  .time-animator::webkit-scrollbar {
    display: none;
  }

  .time-animator:after {
    content: ' ';
    display: block;
    background: rgba(0, 0, 0, 0);
    height: 10px;
    width: 1px;
    position: relative;
    right: -1px;
  }

  .time-button-shadow,
  .more-times-btn {
    display: none;
  }

  .year,
  .day-number-suffixed {
    display: none;
  }
  ${({ isModal }: DayContainerProps) => desktopStylesWrapper(isModal).wrapper`
    position: relative;
    display: grid;
    padding: 22px;
    padding-top: 0px;
    height: ${(props: DayContainerProps) =>
      props.isShortList ? 'auto' : props.isExpanded ? 'auto' : '171px'};
    z-index: 10;
    border-bottom: 0;

    .day-label {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      width: unset;
      border-right: none;
      padding: 0;

      span {
        line-height: 14px;
        font-size: 14px;
        margin-right: 4px;
      }
    }

    .day-name-mobile,
    .day-number {
      display: none;
    }

    .month,
    .year,
    .day-number-suffixed {
      display: inline;
      color: ${appColors.gray900};
      opacity: 0.6;
    }

    .day-name {
      display: inline;
      font-weight: 500;
      color: ${appColors.gray900};
      opacity: 1;
    }

    .day-number-suffixed {
      &:after {
        content: ',';
      }
    }

    .time-button-container {
      min-width: 82px;
      min-height: 34px;
      display: grid;
      grid-template-columns: repeat(auto-fit, 106px);
      grid-row-gap: 8px;
      row-gap: 8px;
      grid-column-gap: 6px;
      background-color: inherit;
      padding: 1px;
      overflow: hidden;
      .sun-icon {
        display: none;
      }
    }

    .time-button-shadow {
      display: ${(props: DayContainerProps) => (props.isExpanded ? 'none' : 'block')};
      position: absolute;
      top: 124px;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: inset 35px ${appColors.lightBackground};
    }

    .more-times-btn {
      &-icon {
        height: 13px;
        width: 13px;
        margin: 2px 0 0 4px;
        stroke-width: 2.2px;
      }
      position: ${(props: DayContainerProps) => (props.isExpanded ? 'initial' : 'absolute')};
      bottom: 7px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: normal;
      color: ${appColors.primary};
      width: 100%;
      min-height: 26px;
      z-index: 1;
      cursor: pointer;
      padding-left: 4px;
      border: 0;
      background: inherit;
    }
  `}
`

const transitionBezier = '.51,.92,.24,1.15'

const TimeAnimator = styled.div<TimeAnimatorProps>`
  position: relative;
  left: 0;
  display: grid;
  grid-template-columns: repeat(${({ times }: TimeAnimatorProps) => times}, 1fr min-content);
  grid-column-gap: 8px;
  align-items: center;
  padding: 0 12px;
  overflow: auto;
  height: 68px;

  &.time-animation-appear {
    transform: translate(100%, 0px);
    opacity: 0.01;
  }

  &.time-animation-appear-done {
    transform: translate(0px, 0px);
    opacity: 1;
    transition: all 500ms cubic-bezier(${transitionBezier});
  }

  .sun-icon {
    height: 23px;
    width: 23px;
    opacity: 0.3;
  }
  ${({ isModal }: TimeAnimatorProps) => desktopStylesWrapper(isModal).wrapper`
    width: 100%;
    min-width: 82px;
    min-height: 34px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 104px);
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    background-color: inherit;
    padding: 1px;
    overflow: hidden;
    position: unset;
    height: auto;
    .sun-icon {
      display: none;
    }
  `}
`
