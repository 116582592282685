import React from 'react'
import { Card } from '../../atoms/card'
import { Box } from '../../atoms/box'
import { Typography } from '../../atoms/typography/typography'
import { appColors } from '../../../assets/app_colors'
import { appStrings } from '../../../assets/app_strings'
import UpdateBookingFeedback from '../../../pages/update_booking/feedback_box'

// icons
import { ReactComponent as MeetingTimeIcon } from 'assets/Icons/meeting-time.svg'
import { ReactComponent as MeetingTypeIcon } from 'assets/Icons/meeting-type.svg'
import { HostMemberIcon } from 'components/atoms/host_member_icon'
import { MapPin } from 'react-feather'
import { HostMember, LocationType } from 'common/redux/meetings/meetings_core'
import { SessionType } from 'common/redux/session/session_core'
import { MeetingTypeNode } from 'common/redux/remote/remote'
import { locationTypeToIcon } from '../meeting_info/meeting_info_items'
import { getLocationText } from '../../../util/components/complete_card'
import InfoItem from './complete_card_info_item'
import { Maybe, SchedulerHostMemberNode } from 'graphql'

interface CancelFormProps {
  meetingType: string | MeetingTypeNode
  startTime: string
  endTime?: string
  timezone?: string
  date: string
  location: Maybe<string>
  locationType: LocationType
  sessionType: SessionType
  submitUpdateBooking: () => void
  setUpdateBookingReason: (reason: string) => void
  hostMember?: Maybe<SchedulerHostMemberNode>
}

export const CancelForm: React.FC<CancelFormProps> = ({
  meetingType,
  startTime,
  endTime,
  timezone,
  date,
  location,
  locationType,
  sessionType,
  submitUpdateBooking,
  setUpdateBookingReason,
  hostMember,
}) => {
  const [locationText, translateLocation] = getLocationText(location, locationType)
  const LocationIcon = locationTypeToIcon[locationType]?.icon || MapPin

  const {
    completeCard: { iconBackgrounds, icon },
  } = appColors

  const submitButtonLabel =
    sessionType === 'reschedule'
      ? appStrings.completeCard.COMPLETE_RESCHEDULE
      : appStrings.completeCard.COMPLETE_CANCEL

  return (
    <Card className="cancel-form-card">
      <Box>
        <InfoItem icon={<MeetingTypeIcon />} iconBg={iconBackgrounds.type} marginTop={0}>
          <Typography variant="body1" className="wrap">
            {meetingType}
          </Typography>
        </InfoItem>
        <InfoItem icon={<MeetingTimeIcon />} iconBg={iconBackgrounds.time} marginTop={2}>
          <Typography variant="body1">
            {`${startTime}${endTime ? ` - ${endTime}` : ''}`}{' '}
            <Typography variant="span" color={appColors.gray600}>
              {timezone}
            </Typography>
          </Typography>
          <Typography variant="body1">{date}</Typography>
        </InfoItem>
        {locationText && (
          <InfoItem
            icon={<LocationIcon color={icon.location} />}
            iconBg={iconBackgrounds.location}
            marginTop={2}
          >
            <Typography variant="body1" disableTranslate={!translateLocation}>
              {locationText}
            </Typography>
          </InfoItem>
        )}
        {hostMember && (
          <InfoItem
            icon={<HostMemberIcon hostMember={hostMember as HostMember} size={42} />}
            iconBg={appColors.lightBackground}
            marginTop={2}
          >
            <Typography variant="body1" disableTranslate={!translateLocation}>
              {hostMember?.name}
            </Typography>
          </InfoItem>
        )}
        <UpdateBookingFeedback
          handleSubmit={submitUpdateBooking}
          setComment={setUpdateBookingReason}
          submitButtonLabel={submitButtonLabel}
        />
      </Box>
    </Card>
  )
}
