import { BaseMap } from 'common/redux/base_map'
import { Fields } from 'common/redux/fields/fields_core'
import { appStrings } from '../../assets/app_strings'

const defaultFieldLabels = ['First Name', 'Last Name', 'Email']

// looks through all the values for form errors returned by react-final-form
export const getFormError = (values: BaseMap<string>, fields: Fields) => {
  const { sort, items } = fields

  // translate 'required' and 'invalid' to current language
  const requiredTranslated = window.Localize.translate(appStrings.requiredError)
  const invalidTranslated = window.Localize.translate(appStrings.invalidError)
  for (const field of sort) {
    // deconstruct needed values
    const currentField = items[field]
    const fieldId = currentField.fieldId
    const { required, regex } = currentField.validators
    const valueOfField = values[fieldId]

    // check conditions
    const requiredNotMet = required && !valueOfField
    const invalid = !!valueOfField && !!regex && !validateRegex(valueOfField, regex)

    // if the label is a default label translate the field name to the current language
    // if it is not display the label name untranslated
    const displayLabel = defaultFieldLabels.includes(currentField.label)
      ? window.Localize.translate(currentField.label)
      : currentField.label

    // if there is an error return the first value
    if (requiredNotMet || invalid) {
      return requiredNotMet
        ? `${displayLabel} ${requiredTranslated}`
        : `${displayLabel} ${invalidTranslated}`
    }
  }
}

const validateRegex = (input: string, re: RegExp): boolean => {
  return re.test(input)
}

export const getFocused = (fields: Fields, isMobile: boolean) => {
  const shouldAutofocus = !isMobile
  return shouldAutofocus ? fields.sort[0] : undefined
}
