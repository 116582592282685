import React from 'react'
interface SaveInfoCheckboxProps {
  readonly id: string
  readonly descriptionText: string
  readonly setSaveInfo: (clicked: boolean) => void
}

export const SaveInfoCheckbox: React.FC<SaveInfoCheckboxProps> = ({
  id,
  descriptionText,
  setSaveInfo,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setSaveInfo(e.target.checked)

  return (
    <label className={`bp3-control bp3-checkbox .modifier`}>
      <input name={id} type={'checkbox'} onChange={onChange} />
      <span className="bp3-control-indicator" />
      {descriptionText}
    </label>
  )
}
