import { SitkaModule } from 'olio-sitka'
import { TimezoneModal, defaultTimezoneModal, TimezoneOption } from './timezone_modal_core'
import { AppModules, AppState } from 'common/redux/sitka'
import { put, select } from 'redux-saga/effects'

export class TimezoneModalModule extends SitkaModule<TimezoneModal, AppModules> {
  public moduleName: string = 'timezoneModal'
  public defaultState: TimezoneModal = defaultTimezoneModal
  public static selectTimezoneModal(state: AppState): TimezoneModal {
    return state.timezoneModal
  }

  public *setTimezones(timezones: ReadonlyArray<TimezoneOption>) {
    const state: TimezoneModal = yield select(TimezoneModalModule.selectTimezoneModal)
    yield put(this.setState({ ...state, timezones }, true))
  }

  public *handleSetTimezoneModal(isOpen: boolean) {
    yield this.setTimezoneModal(isOpen)
  }

  public *setTimezoneModal(isOpen: boolean) {
    const state: TimezoneModal = yield select(TimezoneModalModule.selectTimezoneModal)
    yield put(this.setState({ ...state, isOpen }, true))
  }

  public *set(state: TimezoneModal) {
    yield put(this.setState(state, true))
  }
}
