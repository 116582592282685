import { Fields } from '../fields/fields_core'

// checks 20YY-MM-DD includes Leap year checks
const leapYearRegex = /^(^(((\d\d)(([02468][048])|([13579][26]))-02-29)|(((20[2-9][0-9])))-((((0\d)|(1[0-2]))-((0\d)|(1\d)|(2[0-8])))|((((0[13578])|(1[02]))-31)|(((0[1,3-9])|(1[0-2]))-(29|30))))))$/
// checks HH:MM:SS format 24hr format
const timeRegex = /^([01]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)$/

export const getSubdomain = (hostname: string): string => {
  const parsedHost = hostname.split('.')

  // example staging hostname: "development--appointlet-scheduler.netlify.com"
  const isStaging = parsedHost[0].includes('--appointlet-scheduler') || hostname === 'localhost'
  const stagingSubdomain = 'appointlet'

  // use default slug or staging subdomain for development purposes
  const subdomain = isStaging ? stagingSubdomain : parsedHost[0]

  return subdomain
}

export const validateCustomFormValues = (
  params: Record<string, string>,
  validFields: Fields | null
) => {
  if (validFields === null) {
    return {}
  }

  return Object.keys(params).reduce((acc, key) => {
    const field = validFields.items[key]
    if (!field) {
      return acc
    }

    const value = params[key]
    // fails regex ignore the query parameter
    if (key === 'date' || key === 'time') {
      const doesDateMatch = leapYearRegex.test(value)
      const doesTimeMatch = timeRegex.test(value)
      return doesDateMatch || doesTimeMatch ? { ...acc, [key]: value } : acc
    }
    // checks validFields for "CHOICE" options
    if (field.kind === 'CHOICE') {
      return field.choices.includes(value) ? { ...acc, [key]: value } : acc
    }
    if (field.kind === 'MULTICHOICE') {
      const splitValues = value.split('|').filter(option => field.choices.includes(option))
      return { ...acc, [key]: splitValues || [''] }
    }
    if (field.kind === 'TOGGLE') {
      const validToggle = value.includes('true')
      return { ...acc, [key]: validToggle }
    }
    return { ...acc, [key]: value }
  }, {})
}
